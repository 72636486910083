/* 마이 메뉴: header top right */
#header .rsWrapper .userWrapper .menuBox {
  margin: 16px 0 0;
  padding: 8px 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
#header .rsWrapper .userWrapper {
  min-width: 200px;
}
#header .mobileMyMenuHeader {
  display: none;
}
#header .rsWrapper .userWrapper .followingBox h1 {
  margin: 0 0 4px;
}
#header .rsWrapper .userWrapper .asidedataBar {
  height: 66px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  gap: 4px;
  padding: 16px 8px;
}
#header .rsWrapper .userWrapper .asidedataBar .text {
  height: 22px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
  padding: 4px 0;
}
#header .rsWrapper .userWrapper .asidedataBar .text span:first-child {
  height: 17px;
  flex-grow: 1;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  text-align: left;
  color: #00a8e4;
}
#header .rsWrapper .userWrapper .asidedataBar .text span:last-child {
  height: 14px;
  flex-grow: 1;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  text-align: right;
  color: #656262;
}
#header .rsWrapper .userWrapper .asidedataBar .data {
  height: 8px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
  border-radius: 50px;
  background-color: #e9e9e9;
}
#header .rsWrapper .userWrapper .asidedataBar .data .uses {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  padding: 0;
  border-radius: 50px;
  background-image: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
#header .rsWrapper .userWrapper #logoutBtn {
  margin: 0;
}

/* 무한 스크롤 */
.infinite-scroll-component__outerdiv {
  width: 100%;
}

/* tooltip */
.prompt-detail-prev:where(.css-14i19y2).ant-tooltip .ant-tooltip-inner {
  padding: 0;
  background-color: unset;
  box-shadow: unset;
}
.prompt-detail-prev:where(.css-14i19y2).ant-tooltip .ant-tooltip-inner #engineWrapper {
  padding: 0;
}
.prompt-detail-prev:where(.css-14i19y2).ant-tooltip .ant-tooltip-inner .flexColCenter {
  display: block;
}
.prompt-detail-prev:where(.css-14i19y2).ant-tooltip .ant-tooltip-inner #engineWrapper .rsWrapper .listBox .gerneBox {
  margin: 0;
}
.prompt-detail-prev:where(.css-14i19y2).ant-tooltip .ant-tooltip-inner .flexColBetween .play {
  display: flex;
}
.ant-tooltip.badge-nm {
  border: 1px solid rgb(199, 24, 226);
  border-radius: 8px;
}
.ant-tooltip.badge-nm .ant-tooltip-arrow:before {
  background: #fff;
  margin: 0px 0 -1px;
}
.ant-tooltip.badge-nm .ant-tooltip-arrow:after {
  border-bottom: 1px solid rgb(199, 24, 226);
  border-right: 1px solid rgb(199, 24, 226);
}
.ant-tooltip.badge-nm .ant-tooltip-content .ant-tooltip-inner {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

/* mobile filter */
#mobileFilter .content {
  margin-bottom: 100px;
}

/* 뮤직 플레이어 */
.ant-dropdown.spread-button {
  border: 1px solid rgb(199, 24, 226);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  width: 100px;
}
.ant-dropdown.spread-button li.ant-dropdown-menu-item {
  font-size: 13px;
  font-family: Pretendard;
  font-weight: 500;
}
#musicBox .listBox .list-nothing {
  margin: 10px;
}
#musicBox .listBox.playlist .list .titleBox .user h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
#musicBox .listBox .list {
  gap: 6px;
}
#musicBox .affixBox .ant-affix {
  background: linear-gradient(to bottom, #bbecee, #f1c3f5);
}
.lyricsInfo .ant-modal-content {
  padding: 0;
}
.lyricsInfo .ant-modal-content .ant-modal-body {
  max-height: 70vh;
  overflow: auto;
  padding: 30px 40px;
}
.lyricsInfo .ant-modal-content .ant-modal-body .wrapInfo h1 {
  color: #0e0e1f;
  font-size: 20px;
  font-family: Pretendard;
}
.lyricsInfo .ant-modal-content .ant-modal-body .wrapInfo p,
.lyricsInfo .ant-modal-content .ant-modal-body .wrapInfo textarea {
  color: #87858b;
  font-family: Pretendard;
}
.lyricsInfo .ant-modal-content .ant-modal-body .wrapInfo textarea {
  font-weight: 500;
  padding: 0;
  border: 0;
}
.lyricsInfo .ant-modal-content .ant-modal-body .wrapInfo textarea:disabled {
  background-color: unset;
  cursor: default;
}
.lyricsInfo .ant-modal-content .ant-modal-body .wrapInfo {
  margin: 0 0 20px;
}
.lyricsInfo .ant-modal-content .ant-modal-body .bottomContent button {
  height: 40px;
  padding: 0 12px;
  border-radius: 4px;
}

/* rate star */
:where(.css-apn68).ant-rate .ant-rate-star:not(:last-child) {
  margin: 0 2px;
}

:where(.css-14i19y2).ant-rate .ant-rate-star:not(:last-child) {
  margin: 0 2px;
}
:where(.css-14i19y2).ant-rate.not-empty.index-0 li.ant-rate-star:nth-child(n + 2),
:where(.css-14i19y2).ant-rate.not-empty.index-0.width-0 li.ant-rate-star:nth-child(1) {
  display: none;
}
:where(.css-14i19y2).ant-rate.not-empty.index-1 li.ant-rate-star:nth-child(n + 3),
:where(.css-14i19y2).ant-rate.not-empty.index-1.width-0 li.ant-rate-star:nth-child(2) {
  display: none;
}
:where(.css-14i19y2).ant-rate.not-empty.index-2 li.ant-rate-star:nth-child(n + 4),
:where(.css-14i19y2).ant-rate.not-empty.index-2.width-0 li.ant-rate-star:nth-child(3) {
  display: none;
}
:where(.css-14i19y2).ant-rate.not-empty.index-3 li.ant-rate-star:nth-child(n + 5),
:where(.css-14i19y2).ant-rate.not-empty.index-3.width-0 li.ant-rate-star:nth-child(4) {
  display: none;
}
:where(.css-14i19y2).ant-rate.not-empty.index-4.width-0 li.ant-rate-star:nth-child(5) {
  display: none;
}
:where(.css-14i19y2).ant-rate.index-0.width-1 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-1 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-1 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-1 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-1 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 10% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-2 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-2 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-2 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-2 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-2 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 20% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-3 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-3 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-3 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-3 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-3 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 30% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-4 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-4 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-4 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-4 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-4 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 40% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-5 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-5 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-5 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-5 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-5 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 50% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-6 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-6 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-6 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-6 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-6 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 60% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-7 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-7 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-7 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-7 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-7 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 70% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-8 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-8 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-8 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-8 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-8 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 80% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}
:where(.css-14i19y2).ant-rate.index-0.width-9 li.ant-rate-star:nth-child(1) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-1.width-9 li.ant-rate-star:nth-child(2) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-2.width-9 li.ant-rate-star:nth-child(3) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-3.width-9 li.ant-rate-star:nth-child(4) .ant-rate-star-first,
:where(.css-14i19y2).ant-rate.index-4.width-9 li.ant-rate-star:nth-child(5) .ant-rate-star-first {
  width: 90% !important;
  color: inherit;
  z-index: 1;
  opacity: 1;
}

/* 더보기 버튼 */
#whiteBtn.noMore {
  border: none;
  background: unset;
}
#whiteBtn.noMore:hover {
  cursor: default;
  box-shadow: unset;
}

/* 검색 버튼 */
#mobileFilter .btnBox #pinkBtn {
  width: 100%;
}

/* input */
.titleBox .search :where(.css-14i19y2).ant-input-affix-wrapper:not(.ant-input-disabled):hover,
.titleBox .search :where(.css-dev-only-do-not-override-14i19y2).ant-input-affix-wrapper:not(.ant-input-disabled):hover {
  z-index: auto;
}
.titleBox .search :where(.css-14i19y2).ant-input-outlined:hover,
.titleBox .search :where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined:hover {
  border-color: unset;
  background-color: unset;
}
.titleBox .search :where(.css-14i19y2).ant-input-outlined,
.titleBox .search :where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined {
  background: unset;
  border-width: unset;
  border-style: unset;
  border-color: unset;
}
.titleBox .search :where(.css-14i19y2).ant-input-affix-wrapper:not(.ant-input-disabled):hover,
.titleBox .search :where(.css-14i19y2).ant-input-outlined:focus,
.titleBox .search :where(.css-14i19y2).ant-input-outlined:focus-within,
.titleBox .search :where(.css-dev-only-do-not-override-14i19y2).ant-input-affix-wrapper:not(.ant-input-disabled):hover,
.titleBox .search :where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined:focus,
.titleBox .search :where(.css-dev-only-do-not-override-14i19y2).ant-input-outlined:focus-within {
  box-shadow: unset;
  outline: 0;
}

/* slider */
#top10 #newSlider .slick-list {
  padding: 70px 0 0 !important;
}

/* dropdown */
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox div > p {
  font-size: 16px;
  font-weight: 500;
  margin: 0 5px 0 0;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .appraisal img {
  margin: 0 5px 0 0;
  cursor: pointer;
}
.classRank .ant-dropdown {
  border: 1px solid rgb(199, 24, 226);
  border-radius: 8px;
}
.classRank :where(.css-14i19y2).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.classRank :where(.css-14i19y2).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.classRank :where(.css-14i19y2).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.classRank :where(.css-14i19y2).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.classRank :where(.css-dev-only-do-not-override-14i19y2).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.classRank :where(.css-dev-only-do-not-override-14i19y2).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.classRank :where(.css-dev-only-do-not-override-14i19y2).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.classRank :where(.css-dev-only-do-not-override-14i19y2).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 5px 8px;
}
.classRank :where(.css-14i19y2).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content p,
.classRank :where(.css-dev-only-do-not-override-14i19y2).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content p {
  color: #000 !important;
}
.classRank span::after {
  height: 0;
}

/* 메인 페이지에서 뮤직 영역 */
#musicInfo .majorModel {
  padding: 12px 0;
}
#musicInfo .rsWrapper .info div .userBox .userInfo .nameBox .userArea .major img {
  width: 32px;
  height: 32px;
}
#musicInfo .mobileContentsBox .musicBox .user p,
#musicInfo .aiModelNm {
  width: auto;
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%;
  width: fit-content;
  color: #ffffff;
}
#newWrapper #newSlider #active {
  cursor: pointer;
}
#top10 .musicBox .aiModelNm,
#newWrapper .musicBox .aiModelNm {
  width: auto;
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%;
  width: fit-content;
  color: #ffffff;
}
.thumbBox .absolBox .flexRowBetween.force {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#newSlider .musicBox img {
  aspect-ratio: 1;
  object-fit: cover;
}
.majorModel span.me {
  cursor: pointer;
}
#musicInfo .rsWrapper .thumbBox {
  aspect-ratio: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music .user {
  z-index: 1;
}
#musicInfo .rsWrapper .info div .contentsBox .musicBox .music .user p {
  margin: 4.5px 8px;
}

/* 메인 페이지에서 이미지/비디오/텍스트 영역 */
#popularWrapper .rsWrapper .userBox .appraisal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
}
#popularWrapper .rsWrapper .userBox .appraisal .classRank,
#popularWrapper .rsWrapper .userBox .appraisal .star {
  width: auto !important;
}
#popularWrapper .rsWrapper .userBox .appraisal .classRank img,
#popularWrapper .rsWrapper .userBox .appraisal .classRank p {
  margin: 0 5px 0 0 !important;
  cursor: pointer;
}
#popularWrapper .rankBox .rsWrapper .infoBox .userBox div:first-child div {
  width: inherit;
}
#popularWrapper .rsWrapper .userBox .appraisal .classBadge,
#popularWrapper .rsWrapper .userBox .appraisal .majorModel {
  width: auto !important;
}
#popularWrapper .rsWrapper .userBox .appraisal .classBadge .major img {
  width: 32px !important;
  height: 32px !important;
  margin: 0 !important;
  cursor: default !important;
}
#popularSlider .list > div .absoluteBox .user div.promptRankIcon {
  padding: 0;
  width: auto;
}
#popularWrapper .rankBox .rsWrapper .thumbnail {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
#popularSlider .list > div .absoluteBox p {
  position: absolute;
  bottom: 0;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0 0 4px;
  padding: 0 28px;
  line-height: 140%;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}
#popularSlider .list > div .absoluteBox .thumbnail {
  position: relative;
  width: 100%;
  height: 204px;
  border-radius: 16px 16px 0px 0px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
#popularSlider .list > div .absoluteBox {
  position: unset;
  width: 100%;
  height: auto;
  border-radius: 16px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}
#popularSlider .list > div .absoluteBox .title .greyBox div > p {
  position: unset;
  text-shadow: unset;
  width: auto;
  padding: 0;
}
#popularSlider .list > div .absoluteBox .user .aiBox p {
  position: unset;
}
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox .thumbnail {
  display: block;
  position: relative;
  width: 100%;
  height: 131px;
  border-radius: 16px 16px 0px 0px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 8px;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox {
  position: unset;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox .thumbnail > h2,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox .thumbnail > h2 {
  position: absolute;
  bottom: 0;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 4px;
  line-height: 1.4;
  text-shadow: 0 0 8px #00000080;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox .thumbnail .user {
  padding: 0;
  background: unset;
  width: 100%;
  min-height: unset;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox .play,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox .play {
  position: absolute;
  top: 27px;
}
#listWrapper .rsWrapper .staff .staffList .staffBox:hover .absoluteBox .hover-bg,
#listWrapper .rsWrapper .newContents .staffList .staffBox:hover .absoluteBox .hover-bg {
  width: 100%;
  height: 131px;
  background: #0006;
  position: absolute;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child {
  padding: 0;
}

/* 서브메인 페이지 */
#musicWrapper .content .searchBox .tabBox,
#otherWrapper .content .searchBox .tabBox {
  width: auto;
  padding: 4px;
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-radius: 50px;
  margin: 0 12px 0 0 !important;
}
#musicWrapper .content .searchBox .tabBox .tab,
#otherWrapper .content .searchBox .tabBox .tab {
  padding: 6px 12px;
  width: auto;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  border-radius: 50px;
  margin: 0 2px;
  border: 0;
}
#musicWrapper .content .searchBox .tabBox .tab#active,
#musicWrapper .content .searchBox .tabBox .tab:hover,
#otherWrapper .content .searchBox .tabBox .tab#active,
#otherWrapper .content .searchBox .tabBox .tab:hover {
  background: #ffffff;
  color: #464548;
}
#musicWrapper .rsWrapper > div .content .searchBox .buttonGroup,
#otherWrapper .rsWrapper > div .content .searchBox .buttonGroup {
  gap: 8px;
}
#musicWrapper .rsWrapper > div .content .searchBox div {
  gap: 0;
}
#otherWrapper .rsWrapper > div .content .searchBox div button {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: start;
  padding: 0 12px;
}
#otherWrapper .rsWrapper > div .content .searchBox div #pinkBtn {
  width: auto;
}
#otherWrapper .rsWrapper > div .content .listBox .list .ant-checkbox-wrapper {
  margin: 0 8px 0 0;
}

/* 장바구니 */
#header .rsWrapper .cartWrapper .cartBox {
  max-height: 45vh;
  overflow: auto;
}

/* 라디오 버튼 */
.ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner {
  border-radius: 50px;
  border-style: solid;
  border-width: 6px;
  border-image-source: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

/* 체크 박스 */
.ant-checkbox-wrapper-checked .ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
  background-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-color: #ffffff !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #ec15ff !important;
}

/* 서브메인 상세페이지 */
#detail .majorModel {
  width: unset;
  padding: 12px 0 12px 12px;
}
#detail .majorModel .title {
  color: #8790a3;
  font-size: 12px;
  font-weight: 600;
}

/* 프롬프트 랭크 아이콘 */
div.promptRankIcon::before {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: '';
  margin: -3px 0 0;
}
div.promptRankIcon {
  position: relative;
  display: flex;
}
.promptRank1::before {
  background-image: url(/src/assets/images/common/i-rank01.svg);
  width: 38px;
  height: 54px;
}
.promptRank2::before {
  background-image: url(/src/assets/images/common/i-rank02.svg);
  width: 38px;
  height: 54px;
}
.promptRank3::before {
  background-image: url(/src/assets/images/common/i-rank03.svg);
  width: 38px;
  height: 54px;
}
.promptRank4::before {
  background-image: url(/src/assets/images/common/i-rank04.svg);
  width: 38px;
  height: 54px;
}
.promptRank1 .rank-value,
.promptRank2 .rank-value,
.promptRank3 .rank-value,
.promptRank4 .rank-value {
  width: 100%;
  flex-grow: 0;
  font-family: SaenggeoJincheon;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #000;
  position: absolute;
  top: 18px;
}

/* 탈퇴 사유 모달창 */
.leaveReason .ant-modal-content {
  padding: 0;
}
.leaveReason .ant-modal-content .ant-modal-body {
  max-height: 70vh;
  overflow: auto;
  padding: 0 40px;
}
.leaveReason .ant-modal-content .ant-modal-body .ant-select-selector {
  background-color: unset;
  cursor: default;
  color: #87858b;
}
.leaveReason .ant-modal-content .ant-modal-body .ant-select-disabled .ant-select-selector:hover {
  border: 1px solid #d9d9d9 !important;
}
.leaveReason .ant-modal-content .ant-modal-body textarea {
  color: #87858b;
}
.leaveReason .ant-modal-content .ant-modal-body textarea:disabled {
  background-color: unset;
  cursor: default;
}
.leaveReason .ant-modal-content .ant-modal-footer button {
  width: 100px;
  height: 40px;
  border-radius: 8px;
}

/* 관리자 회원 상세페이지 */
.admin #myPage #myProfile .ant-form {
  width: 90%;
}

@media (max-width: 900px) {
  /* 마이 메뉴: header top right */
  #header .rsWrapper .userWrapper {
    width: 280px;
    position: fixed;
    top: 0;
    right: 0;
    background: #ffffff;
    padding: 40px 18px;
    z-index: 1000;
    min-height: 100vh;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: auto;
    transition: all 0.2s ease-in-out;
    opacity: 1 !important;
  }
  #header .userWrapper .mobileIconBox {
    width: auto;
  }
  #header .userWrapper .mobileIconBox div {
    position: relative;
  }
  #header .userWrapper .mobileIconBox div .round {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 16px;
    background: #fe5139;
  }
  #header .userWrapper .mobileIconBox div:last-child .round {
    right: -4px;
  }
  #header .userWrapper .mobileIconBox div img {
    width: 20px;
    margin: 0 20px 0 0;
  }
  #header .userWrapper .mobileIconBox div:last-child img {
    width: 15px;
    margin: 0;
  }
  #header .userWrapper div > img {
    width: 14px;
  }
  #header .userWrapper > div:first-child {
    padding: 0 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 8px;
  }
  #header .userWrapper .pointBox {
    margin: 0 0 16px;
  }
  #header .userWrapper .pointBox div {
    width: auto;
  }
  #header .userWrapper .pointBox div:first-child img {
    width: 20px;
    margin: 0 8px 0 0;
  }
  #header .userWrapper .pointBox div:first-child p {
    font-size: 18px;
  }
  #header .userWrapper .followingBox {
    margin: 0 0 22px;
  }
  #header .userWrapper .followingBox div {
    width: 50%;
  }
  #header .userWrapper .followingBox div h1 {
    font-size: 20px;
    color: #0e0e1f;
    margin: 0 0 5px;
  }
  #header .userWrapper .followingBox div p {
    font-size: 14px;
    color: #0e0e1f;
  }
  #header .mobileMyMenuHeader {
    display: flex;
  }

  /* 전체 메뉴: header top left */
  #header .mobileMenuWrapper .flexRowBetween button {
    width: auto;
  }
  #header .mobileMenuWrapper .flexRowBetween button .logo {
    width: 170px;
  }

  /* 메인 페이지 */
  #musicInfo .rsWrapper .info div .userBox {
    width: 100%;
  }
  #newWrapper #newSlider .musicBox #active img {
    width: 35px;
    height: 35px;
    margin: 10px 0;
  }
  #newWrapper #newSlider .musicBox #active div {
    width: auto;
    padding: 0;
  }
  #newWrapper #newSlider .musicBox #active div h1,
  #newWrapper #newSlider .musicBox #active div p {
    font-size: 8px;
    margin: 0 0 4px;
  }
  #newSlider .musicBox #active .mobileImg {
    width: auto;
    height: auto;
    border-radius: 8px;
    display: flex;
  }
  #top10 #newSlider .musicBox #active .mobileImg {
    display: none;
  }
  #top10 .musicBox .aiModelNm,
  #newWrapper .musicBox .aiModelNm {
    font-size: 8px !important;
  }
  #newSlider .musicBox #active {
    padding: 10px;
    justify-content: center;
  }
  #aiWrapper > h1 span {
    font-size: 16px;
  }
  #newSlider .slick-list {
    height: auto;
    padding: 40px 0 !important;
  }
  #top10 #newSlider .slick-list {
    padding: 40px 0 0 !important;
    height: 200px;
  }
  .mainIcon {
    width: 83px;
    margin: -60px 0 0;
  }
  #popularSlider .list > div .absoluteBox .user div.promptRankIcon {
    display: none;
  }
  #popularSlider .list > div .absoluteBox .thumbnail {
    height: 124px;
  }
  #popularSlider .list > div .absoluteBox .thumbnail .user {
    padding: 0;
  }
  #popularSlider .list > div .absoluteBox p {
    font-size: 12px;
    padding: 0 8px;
  }
  #listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox .thumbnail > h2,
  #listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox .thumbnail > h2 {
    font-size: 12px;
  }

  /* 서브메인 페이지 */
  #otherWrapper .rsWrapper > div .content .listBox {
    justify-content: space-between;
  }
  #musicWrapper .rsWrapper > div .content .searchBox #subFilter,
  #otherWrapper .rsWrapper > div .content .searchBox #subFilter {
    display: block;
  }
  #musicWrapper .content .searchBox .tabBox,
  #otherWrapper .content .searchBox .tabBox {
    max-width: 138px;
  }
  #musicWrapper .content .searchBox #subFilter div.flexRowCenter:nth-child(2),
  #otherWrapper .content .searchBox #subFilter div.flexRowCenter:nth-child(2) {
    margin: 10px 0 0;
    justify-content: flex-start;
  }

  /* 서브메인 상세 페이지 */
  #detail #musicBox {
    margin: 0 0 34px;
  }
  #musicBox .rhap_controls-section {
    margin-top: 30px;
  }
  #detail .top .rsWrapper .right .priceBox > div {
    width: 100%;
  }
  article .top .rsWrapper .right .mobilePrice #purpleBtn {
    width: auto;
  }
  #detail .top .rsWrapper .right .priceBox > div.downloadBox {
    display: none;
  }

  /* 우측 하단 고정 버튼 */
  #section {
    bottom: 90px;
    right: 20px;
  }
}
