@charset "UTF-8";
/* ----------------------------------------------
Reset
----------------------------------------------*/
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');
@import url('//fonts.googleapis.com/earlyaccess/nanumgothic.css');
html {
  /* -webkit-text-size-adjust: 100%; */
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  cursor: pointer;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
}

button,
optgroup,
select {
  cursor: pointer;
}

button {
  border: 0;
  background: none;
  overflow: visible;
  gap: 8px;
}

input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  text-align: center;
}
fieldset figure {
  margin: 0;
}
fieldset figcaption {
  display: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[hidden],
template {
  display: none;
}

ul,
dl,
p {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

li,
dt,
dd {
  list-style-type: none;
  margin: 0;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --transition: all 0.2s ease;
}
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --transition: all 0.2s ease;
}

[aria-label] {
  position: relative;
}
[aria-label]:after {
  content: attr(aria-label);
  display: none;
  position: absolute;
  top: -40%;
  left: 100%;
  z-index: 5000;
  pointer-events: none;
  padding: 0.5rem;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
  text-indent: 0;
  overflow: visible;
  font-family: 'noto sans';
  font-size: 1.1rem;
  font-weight: 400;
  color: #fff;
  background: #2c3033;
  border-radius: 0.4rem;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 999;
  letter-spacing: 0;
}
[aria-label]:hover:after,
[aria-label]:focus:after {
  /* display: block; */
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  outline: none;
  border-radius: 10px;
  border: 4px solid transparent;
  box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  border: 4px solid transparent;
  box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 0.5);
  cursor: pointer;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}

::selection {
  background: #3bbccd;
  color: #fff;
}

::placeholder,
::-webkit-input-placeholder {
  color: #aaa !important;
  font-weight: 300 !important;
}

:-ms-input-placeholder {
  color: #aaa !important;
  font-weight: 400 !important;
}

/* ----------------------------------------------
Common
----------------------------------------------*/
:root {
  --animate-duration: 0.1s;
  --animate-delay: 0.1s;
  --animate-repeat: 0.1;
}

html {
  font-size: 62.5%;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
  font-size: 1.3rem;
  padding: 0;
  margin: 0;
  font-family:
    'Pretendard Variable',
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    sans-serif;
  /* background: #f1f5f8; */
  color: #2c3033;
  box-sizing: border-box;
  letter-spacing: -0.05px;
  /* overflow: hidden; */
  font-weight: 500;
  color: #2c3033;
}

a {
  color: #1e9fe8;
  text-decoration: none;
}
a:hover {
  color: #0d47a1;
  text-decoration: underline;
}

.white {
  /* background-color: #fff !important;
  border: 0.1rem solid #b7c1c6 !important;
  color: #2c3033;
  font-weight: 600; */
}

.down {
  background-image: url('../images/common/i_down.svg') !important;
  background-repeat: no-repeat;
  background-position: 1.6rem center;
}

.fileDown {
  background-image: url('../images/common/btn_fileDown.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.2rem;
  height: 2.3rem;
}

/* ----------------------------------------------
Layout
----------------------------------------------*/
.l-warp {
  display: flex;
  width: 100%;
  min-height: 100vh;
  min-width: 144rem;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  background: #f1f5f8;
  flex: auto;
  overflow: auto;
}

.l-sidebar,
.l-sidebar2 {
  display: flex;
  height: 100vh;
  width: 24rem;
  padding: 0px 1rem 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background: #2d3041;
  border-right: 0.1rem solid #cbd5e1;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.l-sidebar .__copy,
.l-sidebar2 .__copy {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 0 0 5px;
  color: #9b9c9b;
  font-size: 1.1rem;
}

.l-sidebar .__copy__logo,
.l-sidebar2 .__copy__logo {
  background: url('../images/logo/mslkGray.svg') no-repeat left center;
  width: 12rem;
  height: 2.5rem;
  padding: 0.5rem;
}

.l-header {
  display: flex;
  height: 6rem;
  align-self: stretch;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 0.1rem solid #ddd;
  width: 100%;
}
.l-header .btn-area {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding: 15px 20px;
}
.l-header .btn-area__btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.l-header .btn-area__btn i {
  font-size: 2.8rem;
  color: transparent;
}

.login-area {
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem;
  z-index: 999;
}

.login-area__group {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.login-area__group li {
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  gap: 1rem;
}
.login-area__group .search-area {
  width: 20rem;
  height: 2.8rem;
  border-radius: 1.5rem;
  border: 1px solid #d3d3d3;
  background: #f8f8f8;
  margin: 2rem;
  padding: 0 1rem;
  box-shadow: none;
}
.login-area__group .search-area:focus {
  background: #fff;
}
.login-area__group .search-area:focus input {
  background: #fff;
}
.login-area__group .search-area:focus-visible {
  background: #fff;
}
.login-area__group .search-area:focus-within {
  background: #fff;
  border: 2px solid #000;
}
.login-area__group .search-area__input {
  background: transparent;
  border: 0;
  width: 100%;
  padding: 0;
  height: 3rem;
}
.login-area__group .search-area__input:focus {
  border: 0;
  outline: 0;
}
.login-area__group .search-area i {
  font-size: 1.6rem;
  color: #6d7378;
}
.login-area__group .search-area i:hover {
  filter: invert(60%) sepia(10%) saturate(178%) hue-rotate(155deg) brightness(60%) contrast(81%);
}

.login-area .alert-area {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0 1rem;
}
.login-area .alert-area i {
  font-size: 2.2rem;
  color: #878d90;
}
.login-area .alert-area i:hover {
  filter: invert(60%) sepia(10%) saturate(178%) hue-rotate(155deg) brightness(60%) contrast(81%);
  z-index: 1;
}
.login-area .alert-area span {
  border-radius: 5rem;
  background: #fe5139;
  font-size: 1rem;
  padding: 0.3rem 0.4rem;
  color: #fff;
  margin-left: -1rem;
  vertical-align: top;
  min-width: 1.8rem;
  min-height: 1.8rem;
  line-height: 1;
  z-index: 99;
}
.login-area .alert-area__btn {
  display: inline-block;
  padding: 0;
}
.login-area .info-area__imgbox {
  display: flex;
  align-items: center;
  border: #ddd 0.1rem solid;
  border-radius: 10rem;
  width: 3.5rem;
  height: 3.5rem;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}
.login-area .info-area__imgbox:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 0 0);
}
.login-area .info-area__imgbox i {
  font-size: 1.8rem;
  color: #2d2d2d;
}
.login-area .info-area__affiliate {
  letter-spacing: -0.02rem;
}
.login-area .info-area__name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.login-area .info-area__group {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #888;
  margin-top: 0.5rem;
}
.login-area .info-area .btn-drop__check {
  display: none;
}
.login-area .info-area .btn-drop__btn {
  display: flex;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.8rem;
  color: #aaa;
  cursor: pointer;
  justify-content: center;
  flex-shrink: 0;
}
.login-area .info-area .btn-drop__btn:hover {
  color: #1e9fe8;
  cursor: pointer;
}
.login-area .info-area .btn-drop__btn:hover i {
  color: #1ec3e8;
}
.login-area .info-area .btn-drop__layer {
  display: none;
  position: absolute;
  right: 0;
  top: 5.9rem;
  background: #fff;
  width: 16rem;
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: none;
  border: 0.1rem solid #eee;
}
.login-area .info-area .btn-drop__group {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
}
.login-area .info-area .btn-drop__list {
  padding: 1rem;
  gap: 2.4rem;
  width: 100%;
  cursor: pointer;
}
.login-area .info-area .btn-drop__list i {
  color: #8d9db0;
  font-size: 1.6rem;
  padding: 0.3rem;
}
.login-area .info-area .btn-drop__list:hover {
  color: #1e9fe8;
}
.login-area .info-area .btn-drop #logDrop:checked ~ .btn-drop__btn i,
.login-area .info-area .btn-drop #logDropS:checked ~ .btn-drop__btn i {
  transform: rotate(180deg);
  color: #1e9fe8;
}
.login-area .info-area .btn-drop #logDrop:checked ~ .btn-drop__layer,
.login-area .info-area .btn-drop #logDropS:checked ~ .btn-drop__layer {
  display: block;
}

.l-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 0;
  padding-left: 24rem;
  padding-right: 0;
  width: calc(100% - 24rem);
  min-width: 120rem;
  transition:
    width 0.25s ease,
    margin 0.25s ease;
  overflow: hidden;
  background-color: #f1f5f8;
}

.s-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.8rem;
}

.s-head__title {
  font-size: 2.4rem;
  display: flex;
  gap: 1.5rem;
}

.s-head__title--view {
  padding-left: 3rem;
  font-size: 2.4rem;
  display: inline-block;
  background: url('../images/common/angle-left-solid(v6.4_f104).svg') no-repeat left center;
  background-size: 2rem;
}

.s-head__title--btnPre {
  width: 1.4857rem;
  height: 2.6rem;
}

.s-head__pass {
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: center;
  flex-direction: row;
  font-size: 1.2rem;
  font-weight: 300;
}
.s-head__pass.nolink {
  gap: 0.5rem;
}
.s-head__pass.nolink i {
  margin-left: 0.7rem;
}
.s-head__pass.nolink button {
  padding-left: 1.5rem;
  padding-right: 0;
  color: #2d2d2d;
}

.s-head__home {
  width: 1.5rem;
  height: 1.5rem;
  background: url('../images/common/house-solid(v6.4.0_f015).svg') no-repeat left 0.1rem;
  padding: 0;
  font-size: 0;
}

.s-head__depth {
  display: inline-block;
  background: url('../images/common/angle-right-solid(v6.4_f105) 2.svg') no-repeat left center;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}
.s-head__depth.custom-select {
  height: auto;
  /*point the arrow upwards when the select box is open (active):*/
}
.s-head__depth.custom-select .select-selected {
  border: 0;
  background: #f1f5f8;
}
.s-head__depth.custom-select select {
  display: flex;
  border: 0;
  background: inherit;
  padding-right: 1.5rem;
}
.s-head__depth.custom-select .select-selected.select-arrow-active {
  outline: 0;
  outline-offset: 0;
  height: auto;
  width: 100%;
  padding: 0.81rem 1rem;
  border: 0;
}
.s-head__depth.custom-select .select-selected.select-arrow-active:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0.1rem;
  width: 3.2rem;
  height: 2.8rem;
  background: url(../images/common/arr_down.svg) no-repeat top -0.3rem right 0.1rem;
  border-radius: 0.8rem;
  transform: rotate(180deg);
  right: 1rem;
  top: 0rem;
  background-color: #fff;
}
.s-head__depth.custom-select .select-selected:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0.1rem;
  width: 3.2rem;
  height: 2.8rem;
  background: url(../images/common/arr_down.svg) no-repeat top -0.3rem right 0.1rem;
  border-radius: 0.8rem;
  background: url('../images/common/caret-down-f0d7 2.svg') no-repeat top left;
  width: 0.9rem;
  top: 33%;
  margin-right: 0.5rem;
}
.s-head__depth.custom-select .select-selected.select-arrow-active:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0.1rem;
  width: 3.2rem;
  height: 2.8rem;
  background: url(../images/common/arr_down.svg) no-repeat top -0.3rem right 0.1rem;
  border-radius: 0.8rem;
  background: url('../images/common/caret-down-f0d7 2.svg') no-repeat top left;
  transform: rotate(180deg);
  width: 0.9rem;
  top: -1rem;
}
.s-head__depth.custom-select i {
  margin-left: 0.5rem;
  font-size: 1.4rem;
}
.s-head__depth.custom-select .select-items {
  right: -1rem;
  left: auto;
}

.s-container {
  display: flex;
  padding: 2rem 2rem 0;
  gap: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  overflow: auto;
  height: calc(100vh - 9rem);
}

.s-footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 5px 0 5px;
  color: #9b9c9b;
  font-size: 1.1rem;
  width: 100%;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.s-footer__logo {
  background: url('../images/logo/mslkBlue.svg') left center no-repeat;
  width: 5.1rem;
  height: 1.9rem;
  padding: 0.5rem;
}

.s-contents {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.search-area {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 15px 20px;
  flex-direction: row;
  border-radius: 1.6rem;
  background: #fff;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 2px 2px -1px,
    rgba(0, 0, 0, 0.05) 0px 2px 2px -1px;
}

.search-area__wrap {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}
.search-area__wrap.between {
  border-radius: 0.5rem;
}

.search-area__form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-area__btn {
  display: flex;
  margin-left: 0;
  flex-direction: row;
  gap: 1rem;
  overflow: hidden;
}

.board-area {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  padding: 10px 20px;
  flex-direction: column;
  background: #fff;
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 2px 2px -1px,
    rgba(0, 0, 0, 0.05) 0px 2px 2px -1px;
}

.board-area__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.8rem;
  margin: 0;
}

.board-area__result {
  font-size: 1.3rem;
  display: inline-block;
}
.board-area__result span {
  font-size: 1.2rem;
  padding-left: 2.5rem;
  background: url('../images/common/angle-right-solid(v6.4_f105) 2.svg') 0.5rem center no-repeat;
  letter-spacing: -0.01rem;
}

.board-area__btnarea {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  font-size: 1.2rem;
}

.view-area {
  display: flex;
  align-self: stretch;
  padding: 15px 20px;
  flex-direction: column;
  border-radius: 1.6rem;
  background: #fff;
  gap: 1.6rem;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 2px 2px -1px,
    rgba(0, 0, 0, 0.05) 0px 2px 2px -1px;
}

.btn__open {
  padding: 0.5rem 1rem;
  background: #759587;
  border: 0;
  color: #fff;
  cursor: pointer;
}

#mask {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #000;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
  zoom: 1;
  opacity: 0.2;
  z-index: 9998;
}

.popup {
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  z-index: 9999;
  border-radius: 2rem;
  gap: 2rem;
  padding: 1rem 3rem;
  display: none;
}

.popup__box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.popup__title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.2rem 0;
  margin: 0;
}

.popup__cont {
  padding: 2rem;
  justify-content: center;
  width: 100%;
}
.popup__cont ul {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  gap: 1.4rem;
}
.popup__cont ul li {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.8rem;
}
.popup__cont ul li label {
  font-size: 1.4rem;
  font-weight: 600;
  color: #2c3033;
}
.popup__cont ul li input {
  display: flex;
  padding: 1.3rem 1.8rem;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fcfcfc;
}

.popup__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 1rem;
  gap: 2.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  border-top: 0.1rem solid #ddd;
}
.popup__btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3.6rem;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
}
.popup__btn button:hover {
  box-shadow: 0 -300px 0 0 rgba(0, 0, 0, 0.3) inset !important;
  border: 0.1rem solid rgba(0, 0, 0, 0.3) !important;
  transition-duration: 0.5s all;
}

.popup__btn--close {
  background: #555;
  border: 0.1rem solid #555;
}

.popup__btn--confirm {
  border: 0.1rem solid #ff6530;
  background: #ff6530;
}

.popup .btn_close {
  background: url('../images/common/btn_close.svg') no-repeat center;
  background-size: 2.2rem;
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  font-size: 0;
  width: 2.2rem;
  height: 2.2rem;
  display: inline-block;
}
.popup .btn_close:hover {
  box-shadow: 0 -300px 0 0 rgba(75, 125, 201, 0.02) inset;
  transition: ease 0.5s all;
  border-radius: 5rem;
}

/* ----------------------------------------------
Navigation
----------------------------------------------*/
.l-sidebar ~ .l-main,
.l-sidebar2 ~ .l-main {
  position: absolute;
  top: 0;
  left: 24rem;
  min-height: 100vh;
  z-index: 6;
  transition: var(--transition);
  padding-left: 0;
  right: 0;
}

.fold.l-sidebar,
.fold.l-sidebar2 {
  width: 6.9rem;
  transition: var(--transition);
}
.fold.l-sidebar .btn-area__btn i,
.fold.l-sidebar2 .btn-area__btn i {
  color: #1ec3e8;
}
.fold.l-sidebar ~ .l-main,
.fold.l-sidebar2 ~ .l-main {
  top: 0;
  left: 6.9rem;
  width: calc(100% - 6.9rem);
}

.l-main p {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  display: inline-block;
  white-space: nowrap;
}

.l-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 1rem;
  align-self: stretch;
  z-index: 11;
  width: 100%;
  overflow-y: auto;
  transition:
    width 0.25s ease,
    background 0.25s ease;
  -webkit-transition:
    width 0.25s ease,
    background 0.25s ease;
  -moz-transition:
    width 0.25s ease,
    background 0.25s ease;
  -ms-transition:
    width 0.25s ease,
    background 0.25s ease;
}

.l-nav__logo {
  display: flex;
  align-items: center;
  width: 22rem;
  height: 6rem;
  padding: 0.6rem 0;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  background: url('../images/logo/ico_promptBankAdmin.svg') no-repeat center center;
  cursor: pointer;
}

.l-nav .login-area {
  display: flex;
  align-items: center;
  min-width: 46px;
  padding: 20px 0px 10px 0px;
  flex-direction: column;
  align-self: stretch;
}
.l-nav .login-area__group {
  flex-direction: column;
  width: 100%;
}
.l-nav .login-area__group li {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.l-nav .login-area__group .info-area .btn-drop__layer {
  position: absolute;
  left: 1rem;
  top: 14rem;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: none;
  border-radius: 1rem;
  justify-content: flex-start;
  width: 22rem;
  border: 1px solid #ddd;
}
.l-nav .login-area__group .info-area .btn-drop__list {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 24px;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
}
.l-nav .login-area .alert-area {
  gap: 2.4rem;
  background: #f3f3f3;
  padding: 0.7rem 0;
  border-radius: 1rem;
}
.l-nav .login-area .alert-area i {
  font-size: 1.8rem;
}
.l-nav .menu-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
}
.l-nav .menu-area__group {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 0;
  flex-shrink: 0;
  color: #bbb2c3;
  width: 100%;
}

input:checked .l-nav .menu-area__group:focus-within {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 5rem;
  flex-direction: column;
  background: #1d1d29;
}

.l-nav .menu-area__group input:checked + label,
.l-nav .menu-area__group.on input + label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  flex-direction: row;
  gap: 0;
  flex-shrink: 0;
  border-radius: 1rem;
  text-decoration: none;
  background: #1d1d29;
}
.l-nav .menu-area__group input:checked + label .menu-area__text,
.l-nav .menu-area__group.on input + label .menu-area__text {
  color: #fff;
}
.l-nav .menu-area__group input:checked + label .fa-angle-down:before,
.l-nav .menu-area__group.on input + label .fa-angle-down:before {
  color: #fff;
  transform: rotate(0deg) !important;
}
.l-nav .menu-area__group input + label:hover {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  flex-direction: row;
  gap: 0;
  flex-shrink: 0;
  border-radius: 1rem;
  text-decoration: none;
}
.l-nav .menu-area__group input + label:hover .menu-area__text {
  color: #fff;
}
.l-nav .menu-area__group input + label:hover .fa-angle-down:before {
  color: #fff;
  transform: rotate(180deg);
}
.l-nav .menu-area__check {
  display: none;
}
.l-nav .menu-area__menu {
  display: flex;
  align-items: center;
  width: 220px;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 1.2rem 1.5rem;
}
.l-nav .menu-area__menu i {
  display: flex;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  justify-content: center;
  flex-shrink: 0;
  transform: rotate(-180deg);
}
.l-nav .menu-area__menu .fa-angle-down:before {
  color: #8d9db0;
  font-size: 1.6rem;
  transform: rotate(-180deg);
}
.l-nav .menu-area__text {
  display: flex;
  align-items: center;
  width: 125px;
  gap: 10px;
  flex-shrink: 0;
  padding-left: 3rem;
  color: #bbb2c3;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.28px;
}
.l-nav .menu-area__content {
  display: flex;
  width: 220px;
  padding: 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  max-height: 300px;
  opacity: 1;
  z-index: 10;
  overflow-y: auto;
  display: none;
}
.l-nav .menu-area__smenu {
  display: flex;
  width: 220px;
  padding: 0 1rem 0 2.2rem;
  flex-direction: column;
  align-items: flex-start;
}
.l-nav .menu-area__smenu a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.4rem 2rem;
  background: none;
  border-left: 0.1rem dashed #899193;
  font-family: Pretendard;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: -0.28px;
  text-decoration: none;
  color: #9f90b2;
}
.l-nav .menu-area__smenu.active a {
  border-left: 0.2rem solid #1ec3e8;
  color: #fff;
}
.l-nav .menu-area__smenu.active a a {
  color: #fff;
  font-weight: 300;
  width: 100%;
  background: none;
  outline: 0;
  color: #fff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: -0.28px;
}
.l-nav .menu-area__smenu:hover a {
  color: #fff;
  font-weight: 300;
  width: 100%;
  background: none;
  outline: 0;
  color: #fff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: -0.28px;
}

.menu-area__group {
  width: 22rem;
  /* &:nth-child(6) label:before {
    background: url($imgCommon + 'd06.svg') no-repeat 1.5rem center;
  } */
  /* &:nth-child(6):hover > input + label:before {
    background: url($imgCommon + 'd06_hover.svg') no-repeat 1.5rem center;
  } */
  /* &:nth-child(6).on input + label:before,
  &:nth-child(6) input:checked + label:before {
    background: url($imgCommon + 'd06_active.svg') no-repeat 1.5rem center;
  } */
}
.menu-area__group input[type='radio']:checked ~ .menu-area__content {
  z-index: 10;
  display: block;
}
.menu-area__group:has(input:checked) {
  background: #1d1d29;
  border-radius: 1rem;
}
.menu-area__group.on {
  background: #1d1d29;
  border-radius: 1rem;
}
.menu-area__group.on .menu-area__content {
  z-index: 10;
  display: block;
}

/* .menu-area__group:nth-child(1) label:before {
  background: url('../images/common/d01.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(2) label:before {
  background: url('../images/common/d02.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(3) label:before {
  background: url('../images/common/d03.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(4) label:before {
  background: url('../images/common/d04.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(5) label:before {
  background: url('../images/common/d05.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(1):hover > input + label:before {
  background: url('../images/common/d01_hover.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(2):hover > input + label:before {
  background: url('../images/common/d02_hover.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(3):hover > input + label:before {
  background: url('../images/common/d03_hover.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(4):hover > input + label:before {
  background: url('../images/common/d04_hover.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(5):hover > input + label:before {
  background: url('../images/common/d05_hover.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(1).on input + label:before,
.menu-area__group:nth-child(1) input:checked + label:before {
  background: url('../images/common/d01_active.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(2).on input + label:before,
.menu-area__group:nth-child(2) input:checked + label:before {
  background: url('../images/common/d02_active.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(3).on input + label:before,
.menu-area__group:nth-child(3) input:checked + label:before {
  background: url('../images/common/d03_active.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(4).on input + label:before,
.menu-area__group:nth-child(4) input:checked + label:before {
  background: url('../images/common/d04_active.svg') no-repeat 1.5rem center;
}
.menu-area__group:nth-child(5).on input + label:before,
.menu-area__group:nth-child(5) input:checked + label:before {
  background: url('../images/common/d05_active.svg') no-repeat 1.5rem center;
} */
.menu-area__menu {
  position: relative;
  cursor: pointer;
  padding: 1.2rem 1.5rem;
  display: table;
  width: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  -ms-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.menu-area__menu:before {
  content: ' ';
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.menu-area__text {
  display: table-cell;
  vertical-align: middle;
}

.menu-area__content {
  max-height: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
  position: relative;
  overflow-y: hidden;
  padding: 0;
}

.l-sidebar .btn-area {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding: 15px 20px;
  position: absolute;
  left: 240px;
  z-index: 99;
}
.l-sidebar .btn-area__btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.l-sidebar .btn-area__btn i {
  font-size: 2.8rem;
}
.l-sidebar .btn-area__btn i:hover {
  color: #1ec3e8;
}
.l-sidebar .s-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 1rem;
  gap: 1rem;
  flex-shrink: 0;
  font-size: 1.1rem;
}
.l-sidebar .s-footer span * {
  color: #cbd5e1;
  padding-left: 0.5rem;
}
.l-sidebar .s-footer__info {
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  font-weight: 100;
  letter-spacing: 0.05rem;
  color: #cbd5e1;
}
.l-sidebar .s-footer__info strong {
  color: #cbd5e1;
  line-height: 1.4;
  margin-bottom: 0.2rem;
}
.l-sidebar .s-footer__info strong button i {
  color: #cbd5e1;
  vertical-align: middle;
  font-size: 1.2rem;
}
.l-sidebar .s-footer__info strong button i:hover {
  color: #ffca3a;
  font-size: 1.2rem;
}
.l-sidebar .s-footer__manual {
  width: 100%;
}
.l-sidebar .s-footer__manual button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  color: #bbb2c3;
  width: 100%;
  height: 3rem;
  padding: 0.7rem;
  flex-direction: column;
  border: 0.1rem solid #bbb2c3;
}
.l-sidebar .s-footer__manual button span {
  background: url('../images/common/book-open-reader-solid.svg') no-repeat left center;
  padding-left: 2.5rem;
  line-height: 1.6;
  min-width: 1.6rem;
  line-height: 1.6rem;
}
.l-sidebar .s-footer__manual button:hover {
  box-shadow: 0 -300px 0 0 rgba(75, 125, 201, 0.02) inset;
  transition: ease 0.5s all;
}
.l-sidebar.fold {
  width: 6.9rem;
  transition: var(--transition);
}
.l-sidebar.fold .btn-area {
  left: 5.6em;
  transition: var(--transition);
}
.l-sidebar.fold .l-nav__logo {
  background: url(../images/logo/ico_logoFold.svg) no-repeat center center;
  width: 100%;
  cursor: pointer;
  padding-top: 2.1rem;
}
.l-sidebar.fold .l-nav .login-area .info-area__affiliate {
  display: none;
}
.l-sidebar.fold .l-nav .login-area .info-area .btn-drop {
  position: absolute;
  z-index: 2;
}
.l-sidebar.fold .l-nav .login-area .info-area .btn-drop__btn i {
  display: none;
}
.l-sidebar.fold .l-nav .login-area .info-area .btn-drop__layer {
  left: -1.2rem;
  top: 4.2rem;
}
.l-sidebar.fold .l-nav .login-area .info-area .btn-drop__list {
  justify-content: flex-start;
}
.l-sidebar.fold .l-nav .login-area .alert-area__btn {
  display: none;
}
.l-sidebar.fold .l-nav .login-area .alert-area__btn:nth-child(3) {
  display: block;
}
.l-sidebar.fold .l-nav .menu-area {
  width: 100%;
}
.l-sidebar.fold .l-nav .menu-area__group {
  width: 100%;
  position: relative;
  --transition: 0;
}
.l-sidebar.fold .l-nav .menu-area__menu {
  width: 100%;
}
.l-sidebar.fold .l-nav .menu-area__menu .fa-angle-down:before {
  content: '';
}
.l-sidebar.fold .l-nav .menu-area__text {
  display: none;
}
.l-sidebar.fold .l-nav .menu-area__content {
  position: absolute;
  left: 4.5rem;
  top: 1rem;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
  --transition: 0;
  height: auto;
  width: 22rem;
}
.l-sidebar.fold .l-nav .menu-area__content::before {
  content: '';
  position: absolute;
  right: calc(100% - 1rem);
  top: 3rem;
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-right-color: #1d1d29;
  border-left: 0;
  margin-top: -2rem;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
}
.l-sidebar.fold .l-nav .menu-area__smenu {
  background: #1d1d29;
  width: calc(100% - 1rem);
  margin-left: 1rem;
  --transition: 0;
}
.l-sidebar.fold .l-nav .menu-area__smenu:first-child {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-top: 5px;
}
.l-sidebar.fold .l-nav .menu-area__smenu:last-child {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-bottom: 5px;
}

.l-sidebar.fold .s-footer__info strong,
.l-sidebar.fold .s-footer__info span {
  font-size: 0;
}
.l-sidebar.fold .s-footer__manual > button > span {
  font-size: 0;
  padding-left: 0;
  background-position: center;
}

.form-half-element {
  display: inline-block;
  width: calc(50% - 12px);
}

.form-half-element-left {
  margin-right: 5%;
}

.gridHeaderButton {
  margin-top: 40%;
}

.form-half-element-label {
  margin-bottom: 0;
}

.plan-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.plan-header {
  margin-bottom: 20px;
}

.plan-use-header {
  margin-bottom: 20px;
  padding-left: 15%;
}

.info-box-row {
  display: flex;
  align-items: stretch;
}

.info-box-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.info-box {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  text-align: left;
  height: 100%;
}

.info-label {
  font-size: 14px;
  font-weight: bold;
}

.info-note {
  font-size: 12px;
  color: #aaaaaa;
}
.info-note.blue-text {
  color: #0073e6;
  /* 푸른색으로 변경 */
}

.toggle-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.span-highlight {
  color: #e94cf7;
}

.plan-section {
  margin-top: 30px;
}

.plan-card-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.plan-card {
  background-color: #ffffff;
  border-radius: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.plan-card.active {
  border: 2px solid #e94cf7;
  box-shadow: 0 4px 8px rgba(146, 84, 222, 0.2);
}

.plan-price {
  color: #e94cf7;
  text-align: center;
  margin-bottom: 10px;
}

.plan-title {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  font-size: 14px;
  color: #555555;
}
.plan-features li {
  margin-bottom: 8px;
  padding-left: 16px;
  position: relative;
}
.plan-features li:before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #e94cf7;
  font-size: 12px;
}

.ant-btn-default {
  background-color: #e94cf7;
  border-color: #e94cf7;
}
.ant-btn-primary {
  box-shadow: none !important;
}
.ant-btn-primary:hover {
  color: #0e0e1f;
  border-color: #e2dfe4;
  background: #ffffff !;
}

.ant-btn-default {
  background-color: #ffffff;
  border-color: #d9d9d9;
}
.ant-btn-default:hover {
  box-shadow: inset 0 0 0 80px #0000001a;
}
:where(.css-dev-only-do-not-override-14i19y2).ant-btn:not(:disabled):focus-visible {
  outline: none !important;
}
.plan-features > p {
  padding: 5px 0px 10px 0px;
  font-weight: bold;
}

.ant-down-btn {
  color: gray;
  background-color: hsl(0, 0%, 90%);
}

.ant-used-btn {
  color: #000;
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
}
.ant-used-btn:hover {
  border-color: hsl(0, 0%, 90%);
}

.info-box-left {
  width: 75%;
}

.info-box-right {
  top: 50%;
  position: relative;
}

/*================================
Button Component
 ================================*/
.btn-gray {
  border: #555;
  background-color: #555;
  color: #fff;
}
.btn-gray:hover {
  border: #444 !important;
  background-color: #444 !important;
  color: #fff !important;
}

.btn-pink {
  border: #d954fb;
  background-color: #d954fb;
  color: #fff;
}
.btn-pink:hover {
  box-shadow: inset 0 0 0 80px rgba(0, 0, 0, 0.1);
}

.btn-red {
  border: #ff595e !important;
  background-color: #ff595e !important;
  color: #fff !important;
}
.btn-red:hover {
  border: #df4e52 !important;
  background-color: #df4e52 !important;
  color: #fff !important;
}

.btn-excel {
  border: #11b892;
  padding-left: 35px;
  background: url(../images/common/icon_excel.png) no-repeat 10px center #11b892;
  background-size: 23px;
  color: #fff;
}
.btn-excel:hover {
  border: #0fa683 !important;
  background: url(../images/common/icon_excel.png) no-repeat 10px center #0fa683 !important;
  background-size: 23px !important;
  color: #fff !important;
}

.btn-add {
  border: #1ec3e8;
  padding-left: 35px;
  background: url(../images/common/icon_plus.png) no-repeat 10px center #1ec3e8;
  background-size: 20px;
  color: #fff;
}
.btn-add:hover {
  border: #1bb5d8 !important;
  background: url(../images/common/icon_plus.png) no-repeat 10px center #1bb5d8 !important;
  background-size: 20px !important;
  color: #fff !important;
}

.btn-searchIcon {
  width: 30px;
  height: 30px;
  border: #d954fb;
  background: url(../images/common/icon_search.png) no-repeat center center #d954fb;
  background-size: 50%;
  font-size: 0;
  color: transparent;
}
.btn-searchIcon:hover {
  border: #c54ce4 !important;
  background: url(../images/common/icon_search.png) no-repeat center center #c54ce4 !important;
  background-size: 50% !important;
}

.btn-addIcon {
  width: 24px;
  height: 24px;
  border: #555;
  border-radius: 5px;
  background: url(../images/common/icon_plus.png) no-repeat center center #555;
  background-size: 50%;
  font-size: 0;
  color: transparent;
}
.btn-addIcon:hover {
  border: #444 !important;
  background: url(../images/common/icon_plus.png) no-repeat center center #444 !important;
  background-size: 50% !important;
}
/* 유저페이지 */
.btn-white {
  height: 40px;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid #e2dfe4;
  background-color: #fff;
  color: #000;
}
.btn-white:hover {
  background-color: #f6f6f6;
}
.btn-grad {
  height: 40px;
  padding: 8px 24px;
  border-radius: 4px;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
  color: #fff;
}
.btn-grad:hover {
  background: linear-gradient(90deg, #a33ce3 0%, #d613e7 100%);
}
.btn-follow {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  border-radius: 30px;
  border: 1px solid #e94cf7;
  font-size: 12px;
  color: #e94cf7;
  transition: 0.3s;
}
.btn-follow:hover,
.btn-follow.ing {
  background-color: #e94cf7;
  color: #fff;
}
.btn-web {
  background: url(/src/assets/images/chat/icon_web.png) no-repeat center;
  background-size: contain;
}
.btn-web:hover {
  background: url(/src/assets/images/chat/icon_web_hover.png) no-repeat center;
  background-size: contain;
}
.btn-x {
  background: url(/src/assets/images/chat/icon_twitter.png) no-repeat center;
  background-size: contain;
}
.btn-x:hover {
  background: url(/src/assets/images/chat/icon_twitter_hover.png) no-repeat center;
  background-size: contain;
}
.btn-youtube {
  background: url(/src/assets/images/chat/icon_youtube.png) no-repeat center;
  background-size: contain;
}
.btn-youtube:hover {
  background: url(/src/assets/images/chat/icon_youtube_hover.png) no-repeat center;
  background-size: contain;
}
.btn-insta {
  background: url(/src/assets/images/chat/icon_instagram.png) no-repeat center;
  background-size: contain;
}
.btn-insta:hover {
  background: url(/src/assets/images/chat/icon_instagram_hover.png) no-repeat center;
  background-size: contain;
}
.btn-edit,
.btn-back {
  padding: 8px 12px;
  border-radius: 24px;
  border: 1px solid #e2dfe4;
  font-size: 14px;
  font-weight: 500;
  color: #3e3d53;
  transition: 0.3s;
}
.btn-edit::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(/src/assets/images/common/icon_edit.png) no-repeat center;
  background-size: contain;
  vertical-align: text-bottom;
}
.btn-back::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background: url(/src/assets/images/common/icon_back.png) no-repeat center;
  background-size: contain;
  vertical-align: text-bottom;
}
.btn-edit:hover,
.btn-back:hover {
  background-color: rgb(226, 223, 228, 0.8);
}

.cursor_link {
  cursor: pointer;
}
.ant-form {
  min-width: 90% !important;
}

/*================================
Ant Custom
================================*/
/* select 및 datepicker */
:where(.css-dev-only-do-not-override-5wsri9).ant-upload-wrapper .ant-upload-drag,
:where(.css-dev-only-do-not-override-5wsri9).ant-upload-wrapper .ant-upload-drag:hover {
  border-color: #e21dfd;
  background-color: #fcf7fd;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-5wsri9).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
:where(.css-dev-only-do-not-override-5wsri9).ant-input-outlined:hover,
:where(.css-dev-only-do-not-override-5wsri9).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-5wsri9).ant-input-outlined:focus-within,
:where(.css-dev-only-do-not-override-5wsri9).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-5wsri9).ant-picker-outlined:hover {
  border-color: #e21dfd;
}
.ant-select-open .ant-select-selector,
.ant-picker-focused {
  border-color: #e21dfd !important;
}
.ant-picker-active-bar {
  display: none;
}
.ant-form-item-explain-error {
  font-size: 12px;
}
/* divider 텍스트 */
:where(.css-dev-only-do-not-override-5wsri9).ant-divider .ant-divider-inner-text {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  font-weight: 600;
  color: #3e3d53;
}
@media (max-width: 700px) {
  :where(.css-dev-only-do-not-override-5wsri9).ant-divider .ant-divider-inner-text {
    font-size: 20px;
  }
}
