@charset "UTF-8";
#header {
  width: 100vw;
  /* padding: 13.5px; */
  background: #ffffff;
  height: 52px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
#header .rsWrapper {
  position: relative;
  height: 100%;
}
#header .rsWrapper .logo {
  width: 171px;
}
#header .rsWrapper .menuWrapper {
  width: auto;
  height: 100%;
}
#header .rsWrapper .menuWrapper .menu {
  width: auto;
  padding: 0 20px;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
  height: 100%;
}
#header .rsWrapper .menuWrapper .menu:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 4px;
  transition: all 0.2s ease-in-out;
  width: 0;
  background: linear-gradient(to right, #13d5df, #f138fc);
  z-index: 10;
}
#header .rsWrapper .menuWrapper .menu:hover::before,
#header .rsWrapper .menuWrapper .menu.selected::before {
  width: 100%;
}
#header .rsWrapper .menuWrapper .menu:hover .hoverIcon,
#header .rsWrapper .menuWrapper .menu.selected .hoverIcon {
  opacity: 1;
}
#header .rsWrapper .menuWrapper .menu:hover img,
#header .rsWrapper .menuWrapper .menu.selected img {
  opacity: 0;
}
#header .rsWrapper .menuWrapper .menu .hoverIcon {
  position: absolute;
  left: 20px;
  opacity: 0;
}
#header .rsWrapper .menuWrapper .menu img {
  width: 24px;
  margin: 0 15px 0 0;
  transition: all 0.2s ease-in-out;
}
#header .rsWrapper .menuWrapper .menu p {
  font-size: 16px;
  color: #0e0e1f;
}
#header .rsWrapper .iconBox {
  width: auto;
}
#header .rsWrapper .iconBox div {
  position: relative;
}
#header .rsWrapper .iconBox div img {
  width: 24px;
  margin: 0 20px 0 0;
  cursor: pointer;
  border-radius: 100%;
}
#header .rsWrapper .iconBox div:nth-child(3) img {
  width: 15px;
}
#header .rsWrapper .iconBox div .round {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #fe5139;
  position: absolute;
  right: 16px;
  top: 4px;
  width: 8px;
  padding: 0;
}
#header .rsWrapper .cartWrapper {
  position: absolute;
  top: 52px;
  right: 0;
  background: #ffffff;
  width: 375px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
#header .rsWrapper .cartWrapper > div {
  padding: 20px 24px;
}
#header .rsWrapper .cartWrapper div .titleBox {
  width: auto;
}
#header .rsWrapper .cartWrapper div .titleBox img {
  width: 20px;
}
#header .rsWrapper .cartWrapper div .titleBox h1 {
  margin: 0 16px;
  font-size: 20px;
  font-weight: 700;
}
#header .rsWrapper .cartWrapper div .titleBox div {
  width: auto;
  padding: 4px;
  background: #f34144;
  font-size: 12px;
  color: #ffffff;
  border-radius: 10px;
}
#header .rsWrapper .cartWrapper div > img {
  width: 24px;
  cursor: pointer;
}
#header .rsWrapper .cartWrapper .cartBox {
  padding: 8px 20px 24px;
  flex-wrap: wrap;
}
#header .rsWrapper .cartWrapper .cartBox .cart {
  width: calc(50% - 7.5px);
  margin: 0 15px 20px 0;
  border-radius: 16px;
  overflow: hidden;
}
#header .rsWrapper .cartWrapper .cartBox .cart:nth-child(2n) {
  margin: 0 0 20px;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox {
  position: relative;
  width: 100%;
  height: 90px;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox .thumb {
  width: 100%;
  height: 100%;
  position: relative;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox .thumb .absoluteBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > img {
  width: 100%;
  height: 100%;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 8px;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top div {
  width: auto;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top div > img {
  width: 16px;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top div > h1 {
  font-size: 12px;
  color: #ffffff;
  width: auto;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close {
  width: 24px;
  height: 24px;
  border: 1px solid #a3a1a7;
  background: #ffffff;
  padding: 4px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close:hover {
  background: #a3a1a7;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close img {
  width: 100%;
}
#header .rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div h1 {
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom {
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.1);
  align-items: center !important;
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom img {
  width: 24px;
  margin: 0 4px 0 0;
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom > div {
  width: calc(100% - 24px - 4px);
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom div h1 {
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0 0 5px;
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom div .priceBox div {
  width: auto;
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom div .priceBox div img {
  width: 16px;
  margin: 0 4px 0 0;
}
#header .rsWrapper .cartWrapper .cartBox .cart .bottom div .priceBox p {
  font-size: 13px;
  color: #0e0e1f;
  font-weight: 700;
}
#header .rsWrapper .cartWrapper > .priceBox {
  padding: 0 24px 20px;
}
#header .rsWrapper .cartWrapper > .priceBox .price {
  background: #fdf2ff;
  padding: 10px;
  border-radius: 4px;
  margin: 0 0 16px;
}
#header .rsWrapper .cartWrapper > .priceBox .price h1 {
  color: #525162;
  font-size: 14px;
  font-weight: 600;
}
#header .rsWrapper .cartWrapper > .priceBox .price p {
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 14px;
  font-weight: 400;
}
#header .rsWrapper .cartWrapper > .priceBox #pinkBtn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}
#header .rsWrapper .userWrapper {
  position: absolute;
  top: 52px;
  right: 0;
  background: #ffffff;
  width: auto;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 8px;
}
#header .rsWrapper .userWrapper .userInfo {
  padding: 8px 16px;
}
#header .rsWrapper .userWrapper .userInfo .userThumb {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin: 0 15px 0 0;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
#header .rsWrapper .userWrapper .userInfo .userThumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#header .rsWrapper .userWrapper .userInfo .userThumb .plusBox {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  background: #6f767b;
  border-radius: 100%;
  border: 1px solid #6f767b;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#header .rsWrapper .userWrapper .userInfo .userThumb .plusBox p {
  color: #ffffff;
  font-size: 10px;
}
#header .rsWrapper .userWrapper .userInfo .userThumb .plusBox:hover {
  background: #ffffff;
}
#header .rsWrapper .userWrapper .userInfo .userThumb .plusBox:hover p {
  color: #6f767b;
}
#header .rsWrapper .userWrapper .tabBox {
  padding: 4px;
  background: #f5f5f5;
  border-radius: 8px;
  margin: 0 0 10px;
}
#header .rsWrapper .userWrapper .tabBox .tab {
  width: 49%;
  border-radius: 30px 5px 5px 5px;
  color: #3e3d53;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
}
#header .rsWrapper .userWrapper .tabBox .tab.active {
  background: linear-gradient(to right, #b041f3, #ec15ff);
  color: #ffffff;
  cursor: pointer;
}
#header .rsWrapper .userWrapper .userInfo > div {
  width: calc(100% - 48px - 15px);
}
#header .rsWrapper .userWrapper .userInfo > div h1 {
  font-size: 20px;
  color: #0e0e1f;
  margin: 0 0 4px;
}
#header .rsWrapper .userWrapper .userInfo > div p {
  font-size: 13px;
  color: #6d6c75;
}
#header .rsWrapper .userWrapper .menuBox {
  margin: 16px 0 0;
  padding: 8px 0 0;
  border-top: 1px solid #f0f0f0;
}
#header .rsWrapper .userWrapper .menuBox .menu {
  height: 44px;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
}
#header .rsWrapper .userWrapper .menuBox .menu:hover img {
  opacity: 0;
}
#header .rsWrapper .userWrapper .menuBox .menu:hover img:first-child {
  opacity: 1 !important;
  z-index: 10;
}
#header .rsWrapper .userWrapper .menuBox .menu svg {
  margin: 0 10px 0 0;
  transition: all 0.2s ease-in-out;
}
#header .rsWrapper .userWrapper .menuBox .menu p {
  font-size: 15px;
  color: #6d6c75;
}
#header .rsWrapper .userWrapper .menuBox .menu.fill:hover svg path,
#header .rsWrapper .userWrapper .menuBox .menu.fill.active svg path {
  fill: #00a8e4;
}
#header .rsWrapper .userWrapper .menuBox .menu:not(.fill):hover svg path,
#header .rsWrapper .userWrapper .menuBox .menu.active:not(.fill) svg path {
  fill: #00a8e4;
  stroke: #00a8e4;
}
#header .rsWrapper .userWrapper .menuBox .menu:hover p,
#header .rsWrapper .userWrapper .menuBox .menu.active p {
  color: #0e0e1f;
}
#header .rsWrapper .userWrapper #logoutBtn {
  width: 100%;
  height: 34px;
  border-radius: 24px;
  margin: 20px 0 0;
}
#header .rsWrapper .userWrapper #logoutBtn img {
  width: 18px;
  margin: 0 10px 0 0;
}
#header .rsWrapper .userWrapper #logoutBtn p {
  font-size: 14px;
}
#header .rsWrapper .menuIcon {
  display: none;
}
#header .mobileMenuWrapper,
#header .mobileCart {
  display: none;
}

/* 장바구니 */
/* 유저 */
@media (max-width: 1280px) {
  #header .rsWrapper .menuWrapper .menu {
    padding: 0 10px;
    margin: 0;
  }
  #header .rsWrapper .menuWrapper .menu .hoverIcon {
    left: 10px;
  }
  #header .rsWrapper .menuWrapper .menu img {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 900px) {
  #header .rsWrapper .menuWrapper,
  #header .rsWrapper .iconBox div:not(:last-child) {
    display: none;
  }
  #header .rsWrapper .menuIcon {
    display: flex;
    width: 32px;
  }
  #header .mobileMenuBg {
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    backdrop-filter: blur(1px);
    transition: all 0.2s ease-in-out;
  }
  #header .mobileMenuWrapper {
    width: 280px;
    position: fixed;
    top: 0;
    left: -280px;
    background: #ffffff;
    padding: 40px 18px;
    z-index: -1;
    min-height: 100vh;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: auto;
    transition: all 0.2s ease-in-out;
    display: flex;
  }
  #header .mobileMenuWrapper > div {
    width: 100%;
    justify-content: flex-start;
  }
  #header .mobileMenuWrapper .scroll > div:first-child {
    padding: 0 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 16px;
  }
  #header .mobileMenuWrapper div .mobileIconBox {
    width: auto;
  }
  #header .mobileMenuWrapper div .mobileIconBox div {
    position: relative;
  }
  #header .mobileMenuWrapper div .mobileIconBox div img {
    width: 20px;
    margin: 0 20px 0 0;
  }
  #header .mobileMenuWrapper div .mobileIconBox div .round {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 16px;
    background: #fe5139;
  }
  #header .mobileMenuWrapper div .mobileIconBox div:last-child .round {
    right: -4px;
  }
  #header .mobileMenuWrapper div .mobileIconBox div:last-child img {
    width: 15px;
    margin: 0;
  }
  #header .mobileMenuWrapper div #purpleBtn {
    width: 80px;
    height: 32px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 700;
  }
  #header .mobileMenuWrapper div > img {
    width: 14px;
  }
  #header .mobileMenuWrapper .mobileMenu {
    margin: 0 0 10px;
    height: 52px;
    padding: 0 16px;
    border: none;
  }
  #header .mobileMenuWrapper .mobileMenu > img {
    width: 24px;
    margin: 0 12px 0 0;
  }
  #header .mobileMenuWrapper .mobileMenu p {
    font-size: 16px;
  }
  #header .mobileMenuWrapper .loginUser {
    margin: 0 0 12px;
  }
  #header .mobileMenuWrapper .loginUser > img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin: 0 15px 0 0;
  }
  #header .mobileMenuWrapper .loginUser div {
    width: calc(100% - 48px - 15px);
  }
  #header .mobileMenuWrapper .loginUser div h1 {
    font-size: 20px;
    margin: 0 0 5px;
    color: #000000;
  }
  #header .mobileMenuWrapper .loginUser div p {
    font-size: 13px;
    color: #6d6c75;
  }
  #header .mobileMenuWrapper .pointBox {
    margin: 0 0 16px;
  }
  #header .mobileMenuWrapper .pointBox div {
    width: auto;
  }
  #header .mobileMenuWrapper .pointBox div:first-child img {
    width: 20px;
    margin: 0 8px 0 0;
  }
  #header .mobileMenuWrapper .pointBox div:first-child p {
    font-size: 18px;
  }
  #header .mobileMenuWrapper .pointBox div:last-child img {
    width: 13px;
    margin: 0 0 0 4px;
  }
  #header .mobileMenuWrapper .tabBox {
    padding: 4px;
    background: #f5f5f5;
    border-radius: 8px;
    margin: 0 0 10px;
  }
  #header .mobileMenuWrapper .tabBox .tab {
    width: 49%;
    border-radius: 30px 5px 5px 5px;
    color: #3e3d53;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
  }
  #header .mobileMenuWrapper .tabBox .tab.active {
    background: linear-gradient(to right, #b041f3, #ec15ff);
    color: #ffffff;
  }
  #header .mobileMenuWrapper .followingBox {
    margin: 0 0 22px;
  }
  #header .mobileMenuWrapper .followingBox div {
    width: 50%;
  }
  #header .mobileMenuWrapper .followingBox div h1 {
    font-size: 20px;
    color: #0e0e1f;
    margin: 0 0 5px;
  }
  #header .mobileMenuWrapper .followingBox div p {
    font-size: 14px;
    color: #0e0e1f;
    font-size: 600;
  }
  #header .mobileMenuWrapper .loginMenuBox {
    border-top: 1px solid #e9e9e9;
  }
  #header .mobileMenuWrapper .loginMenuBox .home {
    height: 40px;
    background: #e9e9e9;
    padding: 10px 15px;
    border-radius: 5px;
  }
  #header .mobileMenuWrapper .loginMenuBox .home img {
    width: 20px;
    margin: 0 15px 0 0;
  }
  #header .mobileMenuWrapper .loginMenuBox .home p {
    font-size: 16px;
    color: #3e3d53;
  }
  #header .mobileMenuWrapper .loginMenuBox .home img.activeIcon {
    display: none;
  }
  #header .mobileMenuWrapper .loginMenuBox .home.active p {
    color: #0e0e1f;
  }
  #header .mobileMenuWrapper .loginMenuBox .home.active img {
    display: none;
  }
  #header .mobileMenuWrapper .loginMenuBox .home.active img.activeIcon {
    display: flex;
  }
  #header .mobileMenuWrapper .loginMenuBox .loginMenu {
    padding: 10px 15px;
    height: 48px;
  }
  #header .mobileMenuWrapper .loginMenuBox .loginMenu p {
    font-size: 16px;
  }
  #header .mobileMenuWrapper .loginMenuBox .loginMenu img {
    width: 12px;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper {
    border-bottom: 1px solid #dbdbe0;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu {
    height: 44px;
    padding: 0 20px;
    position: relative;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu img {
    width: 16px;
    margin: 0 15px 0 0;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu img.activeIcon {
    display: none;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu p {
    font-size: 16px;
    color: #6d6c75;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu.active p {
    color: #0e0e1f;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu.active img {
    display: none;
  }
  #header .mobileMenuWrapper .loginMenuBox .logSubMenuWrapper .loginSubMenu.active img.activeIcon {
    display: flex;
  }
  #header .mobileCart {
    display: flex;
  }
  #header .cartWrapper {
    display: none;
  }
  #header .mobileCart#mobileCartActive .cartWrapper {
    left: 0;
  }
  #header .mobileCart .cartWrapper {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: -100%;
    background: #ffffff;
    width: 375px;
    height: 100vh;
    z-index: 10000;
    transition: all 0.2s ease-in-out;
  }
  #header .mobileCart .cartWrapper > div {
    padding: 20px 24px;
  }
  #header .mobileCart .cartWrapper div .titleBox {
    width: auto;
  }
  #header .mobileCart .cartWrapper div .titleBox img {
    width: 20px;
  }
  #header .mobileCart .cartWrapper div .titleBox h1 {
    margin: 0 16px;
    font-size: 20px;
    font-weight: 700;
  }
  #header .mobileCart .cartWrapper div .titleBox div {
    width: auto;
    padding: 4px;
    background: #f34144;
    font-size: 12px;
    color: #ffffff;
    border-radius: 10px;
  }
  #header .mobileCart .cartWrapper div > img {
    width: 24px;
    cursor: pointer;
  }
  #header .mobileCart .cartWrapper .cartBox {
    padding: 8px 20px;
    flex-wrap: wrap;
    overflow-y: auto;
    height: calc(100% - 110px - 64px);
    align-items: flex-start;
  }
  #header .mobileCart .cartWrapper .cartBox .cart {
    width: calc(50% - 7.5px);
    margin: 0 15px 20px 0;
    border-radius: 16px;
    overflow: hidden;
  }
  #header .mobileCart .cartWrapper .cartBox .cart:nth-child(2n) {
    margin: 0 0 20px;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox {
    position: relative;
    width: 100%;
    height: 90px;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > img {
    width: 100%;
    height: 100%;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 8px;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div .top div {
    width: auto;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div .top div > img {
    width: 16px;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div .top div > h1 {
    font-size: 12px;
    color: #ffffff;
    width: auto;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div .top .close {
    width: 24px;
    height: 24px;
    border: 1px solid #a3a1a7;
    background: #ffffff;
    padding: 4px;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div .top .close:hover {
    background: #a3a1a7;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div .top .close img {
    width: 100%;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .thumbnailBox > div h1 {
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom {
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.1);
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom img {
    width: 24px;
    margin: 0 4px 0 0;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom > div {
    width: calc(100% - 24px - 4px);
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom div h1 {
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin: 0 0 5px;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom div .priceBox div {
    width: auto;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom div .priceBox div img {
    width: 16px;
    margin: 0 4px 0 0;
  }
  #header .mobileCart .cartWrapper .cartBox .cart .bottom div .priceBox p {
    font-size: 13px;
    color: #0e0e1f;
    font-weight: 700;
  }
  #header .mobileCart .cartWrapper > .priceBox {
    padding: 20px 24px;
  }
  #header .mobileCart .cartWrapper > .priceBox .price {
    background: #fdf2ff;
    padding: 10px;
    border-radius: 4px;
    margin: 0 0 16px;
  }
  #header .mobileCart .cartWrapper > .priceBox .price h1 {
    color: #525162;
    font-size: 14px;
    font-weight: 600;
  }
  #header .mobileCart .cartWrapper > .priceBox .price p {
    background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 14px;
    font-weight: 600;
  }
  #header .mobileCart .cartWrapper > .priceBox #pinkBtn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
  }
  /* 모바일장바구니 */
  /* 기능 */
  #menuBgVisible {
    display: flex !important;
  }
  #menuVisible {
    opacity: 1 !important;
    left: 0 !important;
    z-index: 10000 !important;
  }
}
@media (max-width: 700px) {
  #header .mobileCart .cartWrapper {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: -100%;
    background: #ffffff;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    transition: all 0.2s ease-in-out;
  }
}
