.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
  line-height: 1;
}
.badge.pro {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
.badge.basic {
  background-image: inherit;
  background-color: #f8ab30;
}
.badge.beginner {
  background-image: inherit;
  background-color: #6d6c75;
}

.badge_s {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
  line-height: 1;
  width: auto;
  padding: 1px 2px;
  font-size: 11px;
  font-weight: 500;
  height: 23px;
  min-width: 44px;
  margin: 0;
}
.badge_s.pro {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
.badge_s.basic {
  background-image: inherit;
  background-color: #f8ab30;
}
.badge_s.beginner {
  background-image: inherit;
  background-color: #6d6c75;
}

.ranking {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  gap: 12px;
  cursor: pointer;
  gap: 8px;
}
.ranking::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.music::before {
  background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.image::before {
  background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.video::before {
  background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.text::before {
  background: url(/src/assets/images/common/i-rankText.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}

.ranking_s {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  gap: 12px;
  cursor: pointer;
  font-size: 14px;
  min-width: 100px;
  gap: 8px;
}
.ranking_s::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking_s::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 16px;
  height: 16px;
  content: "";
}
.ranking_s.music::before {
  background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}
.ranking_s.image::before {
  background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}
.ranking_s.video::before {
  background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}
.ranking_s.text::before {
  background: url(/src/assets/images/common/i-rankText.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}

.btn -default {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border-color: #e2dfe4;
}
.btn -dark {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
}
.btn -dark:hover {
  color: #fff !important;
}
.btn -gra {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
}
.btn -red {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: #f34144 !important;
  color: #fff;
}
.btn -line {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
}
.btn -gray {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
}
.btn -defaultR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border-color: #e2dfe4;
  border-radius: 24px;
}
.btn -darkR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
  border-radius: 24px;
}
.btn -darkR:hover {
  color: #fff !important;
}
.btn -graR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
  border-radius: 24px;
}
.btn -redR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: #f34144 !important;
  color: #fff;
  border-radius: 24px;
}
.btn -lineR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
}
.btn -grayR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
  border-radius: 24px;
}

.i-more {
  background: url(/src/assets/images/common/i-more.svg) no-repeat center;
  width: 18px;
  height: 32px;
  content: "";
  margin-left: 4px;
  margin-right: 4px;
}

button.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
button.icon::before {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: contain;
  content: "";
}
button.icon::before:hover {
  background-repeat: no-repeat;
  background-position: center;
}
button.icon:hover {
  background-repeat: no-repeat;
  background-position: center;
}

.share::before {
  background-image: url(/src/assets/images/common/i-share.svg);
}

.listen::before {
  background-image: url(/src/assets/images/common/i-listen.svg);
  width: 22px;
  height: 20px;
}
.listen::before:hover {
  background-image: url(/src/assets/images/common/i-listenA.svg);
}

.keep::before {
  background: url(/src/assets/images/common/i-keep.svg);
}
.keep::before:hover {
  background-image: url(/src/assets/images/common/i-keepA.svg);
}

.put::before {
  background: url(/src/assets/images/common/i-basket.svg);
  width: 22px;
}
.put::before:hover {
  background-image: url(/src/assets/images/common/i-basketA.svg);
}

.sale::before {
  background: url(/src/assets/images/common/i-sale.svg);
  width: 22px;
}
.sale::before:hover {
  background-image: url(/src/assets/images/common/i-sale.svg);
}

.siren {
  font-size: 0;
}
.siren::before {
  background: url(/src/assets/images/common/i-siren.svg);
  width: 22px;
}
.siren:hover {
  background: url(/src/assets/images/common/i-sirenOn.svg);
}
.siren.on::before {
  background: url(/src/assets/images/common/i-sirenOn.svg);
}

.delete {
  padding: 4px;
}
.delete::before {
  background: url(/src/assets/images/common/i_del.svg);
  width: 22px;
}
.delete::before:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}

#board-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#board-detail .board-detail-wrapper {
  width: 960px;
  margin: auto;
  padding: 40px 0;
}
#board-detail .board-detail-wrapper .btn-gray {
  width: 72px;
}
#board-detail .board-detail-wrapper :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item {
  margin: 0;
}
#board-detail .board-detail-wrapper :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item.ant-form-item-has-success .ant-form-item-control-input-content {
  font-size: 16px;
  color: #4a4b50;
  font-weight: 400;
  padding-top: 40px;
}
#board-detail .board-detail-wrapper .title-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 40px;
}
#board-detail .board-detail-wrapper .title-container .board-category {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.75px;
  text-align: left;
  color: #939ca7;
}
#board-detail .board-detail-wrapper .title-container .info-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #0e0e1f;
}
#board-detail .board-detail-wrapper .title-container .info-container .view-count b {
  font-weight: 500;
  padding-left: 4px;
}
#board-detail .board-detail-wrapper .title-container .info-container .like-button-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 0;
}
#board-detail .board-detail-wrapper .title-container .info-container .like-button-container button {
  width: auto;
}
#board-detail .board-detail-wrapper .title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  align-items: start;
}
#board-detail .board-detail-wrapper .title-box h2 {
  line-height: 1.6;
}
#board-detail .board-detail-wrapper .title-box button {
  margin-top: 4px;
}
#board-detail .board-detail-wrapper .title-content {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #323146;
  align-items: center;
}
#board-detail .board-detail-wrapper .profile-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 24px 0;
  gap: 24px;
}
#board-detail .board-detail-wrapper .profile-container .user-icon .ant-avatar {
  margin: 0;
  width: 48px;
  height: 48px;
}
#board-detail .board-detail-wrapper .profile-container .user-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  gap: 8px;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: auto;
  align-items: center;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  height: 70%;
  gap: 4px;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-content {
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2px;
  z-index: 10;
  border: 1px solid #c718e2;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-content > .dropdown-item {
  padding: 8px 12px;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-content > .dropdown-item:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-content > .dropdown-item .ranking_s::after {
  display: none;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding: 0;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .star-rating {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: auto;
}
#board-detail .board-detail-wrapper .profile-container .user-info .info-container .star-rating .anticon-star {
  font-size: 16px;
  color: #ccc;
}
#board-detail .board-detail-wrapper .profile-container .user-info .userArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}
#board-detail .board-detail-wrapper .profile-container .user-info .userArea p {
  font-size: 16px;
}
#board-detail .board-detail-wrapper .button-group {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
#board-detail .board-detail-wrapper .date-info {
  flex-grow: 0;
  font-family: "Pretendard";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0e0e1f;
}
#board-detail .board-detail-wrapper .document-container.view {
  height: auto;
  min-height: 450px;
  /* max-height: 800px; */
  /* overflow-y: auto; */
  padding: 40px 0;
  /* border-radius: 2px;*/
  font-size: 16px;
  color: #4a4b50;
  line-height: 1.6;
}
#board-detail .board-detail-wrapper .file-attachment {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  padding: 12px 0 16px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
#board-detail .board-detail-wrapper .file-attachment .file-title {
  font-size: 12px;
  font-weight: bold;
  color: #464548;
}
#board-detail .board-detail-wrapper .file-attachment .file-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  list-style: none;
  width: 100%;
  gap: 12px;
}
#board-detail .board-detail-wrapper .file-attachment .file-list .file-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
#board-detail .board-detail-wrapper .file-attachment .file-list .file-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
#board-detail .board-detail-wrapper .file-attachment .file-list .file-item button {
  width: 100%;
}
#board-detail .board-detail-wrapper .file-attachment .file-list .file-item span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
#board-detail .board-detail-wrapper .file-attachment .file-item button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0;
  border: 0;
  box-shadow: none;
  height: 24px;
  gap: 12px;
}
#board-detail .board-detail-wrapper .file-attachment .file-item button::before {
  content: "";
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #fff url(/src/assets/images/common/btn-down.svg) no-repeat center;
  border-radius: 100%;
  border: 1px solid #a3a1a7;
}
#board-detail .board-detail-wrapper .file-attachment .content-container {
  height: auto;
  min-height: 450px;
  max-height: 800px;
  overflow-y: auto;
  padding: 8px;
  border-radius: 2px;
}
#board-detail .board-detail-wrapper .back-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 40px 0 24px;
}
#board-detail .board-detail-wrapper .back-button-container .back-button {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 24px;
  border: solid 1px #e2dfe4;
  cursor: pointer;
}
#board-detail .board-detail-wrapper .back-button-container .back-button-text {
  font-size: 14px;
  font-weight: 600;
  color: #3e3d53;
}
#board-detail .board-detail-wrapper .ant-form-item.btnArea {
  width: 100%;
}
#board-detail .board-detail-wrapper .ant-form-item.btnArea .button-group {
  justify-content: center;
}
#board-detail .board-detail-wrapper .ant-form-item.btnArea .button-group :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item .ant-form-item-control-input-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  gap: 16px;
}
#board-detail .board-detail-wrapper .comment-system {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
}
#board-detail .board-detail-wrapper .comment-system .write {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  padding: 16px 0 24px;
}
#board-detail .board-detail-wrapper .comment-system .write :where(.css-dev-only-do-not-override-1x0dypw).ant-comment .ant-comment-content {
  margin: 0;
}
#board-detail .board-detail-wrapper .comment-system h2 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #0e0e1f;
  font-size: 32px;
  font-weight: 700;
  line-height: 100%; /* 32px */
  padding: 12px 0 20px;
}
#board-detail .board-detail-wrapper .comment-system h3 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: #0e0e1f;
  letter-spacing: 0.3px;
  font-size: 16px;
  font-weight: 500;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment .repleWriteArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment .repleWriteArea * {
  height: 48px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment .repleWriteArea span {
  display: flex;
  justify-content: center;
  align-items: center;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment .repleWriteArea textarea {
  padding-top: 12px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested {
  margin-inline-start: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleWrite {
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px 16px 16px 72px;
  border-radius: 70px 16px 16px 72px;
  background: #f9f9f9;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px 0 16px 72px;
  background-color: #ececee;
  border-radius: 70px 16px 16px 72px;
  margin: 0;
  gap: 12px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner + .ant-comment-nested:has(div) {
  border-top: 1px solid #ececee;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner + .ant-comment-nested .repleWrite {
  padding: 16px 16px 16px 0;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author, #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name {
  width: 100%;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box, #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box .btnArea, #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-right: 16px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box .btn-lineR, #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box .btn-lineR {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
  background: inherit;
  height: 32px;
  padding: 8px 12px;
  min-width: 44px;
  cursor: pointer;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author .box .btn-lineR:hover, #board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-author-name .box .btn-lineR:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
  border: 1px solid transparent;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content-detail {
  margin-right: 16px;
}
#board-detail .board-detail-wrapper .comment-system .ant-comment-nested .repleBox .ant-comment .ant-comment-inner .ant-comment-content .rereWrite {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: stretch;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px;
  background-color: #ececee;
  border-radius: 72px 72px 16px 72px;
  margin: 0;
  gap: 12px;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name {
  width: 100%;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .info,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 8px;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .info .comment-name,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info .comment-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: start;
  height: 32px;
  padding: 0;
  text-decoration: underline;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .info .comment-date,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info .comment-date {
  margin-left: 0;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-right: 16px;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btn-lineR,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btn-lineR {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
  background: inherit;
  height: 32px;
  padding: 8px 12px;
  min-width: 44px;
  cursor: pointer;
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btn-lineR:hover,
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btn-lineR:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#board-detail .board-detail-wrapper .comment-system .repleBox .ant-list :where(.css-dev-only-do-not-override-1x0dypw).ant-list * {
  outline: none;
  font-size: 12px;
}
#board-detail .board-detail-wrapper .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea .info,
#board-detail .board-detail-wrapper #board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .info {
  gap: 0;
}
#board-detail .board-detail-wrapper .ant-comment-inner .ant-comment-content .ant-comment-content-author .box .btnArea .btnArea,
#board-detail .board-detail-wrapper #board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-author-name .box .btnArea {
  padding-right: 0 !important;
}
#board-detail :where(.css-dev-only-do-not-override-1x0dypw).ant-comment .ant-comment-content-author > a,
#board-detail :where(.css-dev-only-do-not-override-1x0dypw).ant-comment .ant-comment-content-author > span {
  padding-inline-end: 0;
}
#board-detail .repleBox .ant-comment-inner {
  display: flex;
  padding-block: 0;
}
#board-detail .repleBox .ant-comment-avatar .ant-avatar {
  margin-left: 0;
  width: 140px;
  height: 140px;
}
#board-detail .repleBox .ant-comment-avatar .ant-avatar img {
  width: 140px;
  height: 140px;
}
#board-detail .repleBox .ant-comment-avatar + .ant-comment-content {
  gap: 12px;
}
#board-detail :where(.css-dev-only-do-not-override-1x0dypw).ant-avatar {
  background: #e4e4e7;
}
#board-detail :where(.css-dev-only-do-not-override-1x0dypw).ant-comment .ant-comment-avatar {
  margin-inline-end: 0px;
}
#board-detail :where(.css-dev-only-do-not-override-1x0dypw).ant-comment .ant-comment-content {
  gap: 12px;
}
#board-detail textarea:where(.css-dev-only-do-not-override-1x0dypw).ant-input:focus {
  background: #fff;
}
#board-detail textarea:where(.css-dev-only-do-not-override-1x0dypw).ant-input:focus + button {
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}
#board-detail .ant-form-item-control-input-content .write {
  justify-content: flex-start;
}