#login {
  width: 580px;
  padding: 20px 70px;
}
#login .top {
  margin: 0 0 20px;
  background: #ffffff;
  padding: 0;
}
#login .top div {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#login .top div:hover {
  background: #cccccc;
}
#login .logo {
  width: 310px;
  padding: 15px 0 40px;
}
#login .logo img {
  width: 100%;
}
#login .email .input {
  width: calc(100% - 120px - 10px) !important;
}
#login .email #pinkBtn {
  width: 120px;
  height: 55px;
  border-radius: 5px;
  margin: 0 0 16px;
}
#login .input {
  width: 100%;
  height: 55px;
  /* background: #eeeeee; */
  border-radius: 5px;
  box-shadow: none;
  padding: 16px 20px;
  font-size: 16px;
  margin: 0 0 16px;
}
#login .input::placeholder {
  color: #a3a1a7;
}
#login .input:hover {
  border: 1px solid #ec15ff;
}
#login .input:focus {
  background: #ffffff;
  border: 1px solid #ec15ff;
}
#login .check {
  margin: 0 0 16px;
}
#login #purpleBtn {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  font-size: 18px;
  margin: 0 0 26px;
}
#login .signup {
  margin: 0 0 24px;
}
#login .signup p {
  color: #676767;
  font-size: 13px;
  padding: 0 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#login .signup span:hover {
  color: #ec15ff;
}
#login .signup p:first-child {
  /*border-right: 1px solid #cccccc;*/
}
#login .line {
  position: relative;
  margin: 0 0 20px;
}
#login .line p {
  font-size: 16px;
  z-index: 2;
  padding: 0 10px;
  background: #ffffff;
  color: #6d6c75;
}
#login .line .absoluteBox {
  position: absolute;
  top: 50%;
  height: 1px;
  left: 0;
  margin: -0.5px 0 0;
  background: #dfdce4;
}
#login #kakao,
#login #naver,
#login #facebook,
#login #google {
  width: 100%;
  height: 58px;
  margin: 0 0 16px;
  border-radius: 4px;
}
#login #kakao img,
#login #naver img,
#login #facebook img,
#login #google img {
  width: 20px;
  margin: 0 20px 0 0;
}
#login #kakao p,
#login #naver p,
#login #facebook p,
#login #google p {
  font-size: 18px;
}
#login .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

@media (max-width: 700px) {
  #login {
    width: 100%;
    padding: 20px;
  }
  #login .input {
    font-size: 14px;
  }
  #login .input::placeholder {
    font-size: 14px;
  }
}