.modalArea {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalArea .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.modalArea .sectionArea {
    height: 212px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 24px;
    padding: 12px;
    border-radius: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
}

.modalArea .modalInArea {
    background: white;
    width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 16px;
    padding: 20px;
    position: relative;
}

.modalArea .popTitle {
    height: 69px;
    background-color: #fff;
}

.modalArea .popTitle span {
    width: 227px;
    height: 29px;
    font-family: 'Pretendard';
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #0e0e1f;
}

.modalArea .box {
    width: 105%;
    height: 188px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #c2b8cb;
}

.modalArea .text {
    height: 28px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 0;
}

.modalArea .text span {
    width: 93px;
    height: 14px;
    flex-grow: 0;
    font-family: 'Pretendard';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #464548;
}

.modalArea .saveLi {
    height: 144px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
    padding: 0;
}

.modalArea .saveTitle {
    height: 40px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0;
}

.modalArea .saveListContainer {
    width: 30%;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #c2b8cb;
}

.modalArea .write {
    height: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 0;
    background-color: #fff;
}

.modalArea .saveBtn {
    width: 120px;
    height: 41px;
    flex-grow: 0;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 12px;
    border-radius: 24px;
    background-color: #e94cf7;
    color: #fff;
}

.modalArea .selectAiModel {
    width: 70%;
    height: 40px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px #dfdce4;
    background-color: #f7f7f8;
}
