@charset "UTF-8";
/*================================
Ant Modal Custom
================================*/
.ant-modal-body {
  line-height: auto;
  font-family: "pretendard";
}

.ant-modal .ant-modal-close {
  width: 24px;
  height: 24px;
  top: 20px;
  right: 20px;
  background: url(../images/common/close_icon.png) no-repeat center;
  background-size: contain;
}
.ant-modal .ant-modal-close span {
  display: none;
}
.ant-modal .ant-modal-close:hover {
  background-color: inherit;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-modal .ant-modal-content .ant-btn-primary {
  background-color: #A3A1A7;
}

/*================================
Modal Contents
================================*/
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 16px;
}
.modalContent .ticketList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 560px;
  overflow-y: scroll;
}
.modalContent .notice {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
  margin-top: 10px;
  border-top: 1px dashed #c4c3c7;
  font-size: 12px;
  font-weight: 500;
  color: #a3a1a7;
}
.modalContent .notice p:first-child {
  color: #000;
}

/*================================
Receipt Component
================================*/
.modalReceipt {
  display: flex;
  gap: 40px;
  padding: 16px;
  background: #F9F9F9;
}

@media (max-width: 700px) {
  .modalReceipt {
    flex-direction: column;
  }
}
/* 신용카드 옵션 */
.optList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 280px;
  height: 310px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.optList .opt {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 500;
  background-color: #F9F8FA;
  cursor: pointer;
}
.optList .opt input {
  display: none;
}
.optList .opt::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
.optList .opt:has(input[value=credit])::before {
  background: url(../images/common/icon_card.png) no-repeat center;
  background-size: contain;
}
.optList .opt:has(input[value=pay])::before {
  background: url(../images/common/icon_pay.png) no-repeat center;
  background-size: contain;
}
.optList .opt:has(input[value=phone])::before {
  background: url(../images/common/icon_phone.png) no-repeat center;
  background-size: contain;
}
.optList .opt:has(:checked) {
  background-color: #525162;
  color: #fff;
}
.optList .opt:has(:checked):has(input[value=credit])::before {
  background: url(../images/common/icon_card_hover.png) no-repeat center;
}
.optList .opt:has(:checked):has(input[value=pay])::before {
  background: url(../images/common/icon_pay_hover.png) no-repeat center;
}
.optList .opt:has(:checked):has(input[value=phone])::before {
  background: url(../images/common/icon_phone_hover.png) no-repeat center;
}
.optList .opt:is(button) {
  background-color: #EFEEF2;
}
.optList .opt:is(button)::before {
  background: url(../images/common/icon_coupon.png) no-repeat center;
  background-size: contain;
}

@media (max-width: 700px) {
  .optList {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .optList .opt {
    flex: 48%;
  }
}
.receipt {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
}
.receipt__content {
  flex: 2;
  border-radius: 16px 16px 0 0;
  border: 1px solid #B041F3;
  border-bottom: none;
  padding: 20px 40px;
  background-color: #fff;
}
.receipt__content h5 {
  margin-bottom: 25px;
  background: linear-gradient(90deg, #CA79FC 0%, #FF5EB6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 600;
}
.receipt__content p {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #464548;
}
.receipt__content p b {
  font-size: 16px;
}
.receipt__content p b.red {
  color: #F34144;
}
.receipt__total {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-radius: 0 0 16px 16px;
  background: linear-gradient(90deg, #CA79FC 0%, #FF5EB6 100%);
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.receipt__total b {
  font-weight: 600;
}
.receipt__total::before {
  content: "";
  position: absolute;
  top: -16px;
  left: 0;
  width: 16px;
  height: 32px;
  border: 1px solid #B041F3;
  border-left: none;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #F9F9F9;
}
.receipt__total::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 32px;
  top: -16px;
  right: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border: 1px solid #B041F3;
  border-right: none;
  background-color: #F9F9F9;
}

/*================================
Ticket Component
================================*/
.ticket {
  max-width: 480px;
  display: flex;
  margin-bottom: 16px;
  color: #fff;
  cursor: pointer;
  /* 티켓 발급 상태 */
}
.ticket__left {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 0px 8px 8px 0px;
  background: linear-gradient(90deg, #FF8A14 0%, #FFA514 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.ticket__left h5 {
  font-size: 44px;
  font-weight: 900;
}
.ticket__left h5 span {
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.ticket__left .ticketInfo {
  font-size: 12px;
  font-weight: 500;
}
.ticket__right {
  flex: 1 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-left: 1px dashed #fff;
  border-radius: 8px 0 0 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background: url(../images/common/icon_down.png) no-repeat center center #ffa514;
  background-size: 40px 40px;
  font-size: 0px;
  font-weight: 600;
}
.ticket.off {
  color: #A3A1A7;
}
.ticket.off .ticket__left {
  background: #E6E6E6;
}
.ticket.off .ticket__right {
  background-image: none;
  background-color: #E6E6E6;
}
.ticket.off .ticket__right b {
  font-size: 16px;
}

/*================================
Button Component
================================*/
.btn-coupon {
  padding: 8px 12px;
  border-radius: 24px;
  background: linear-gradient(90deg, #FFA514 0%, #FF8A14 100%);
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  /* 등록 가능 쿠폰 없을 경우 */
}
.btn-coupon span {
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  padding: 4px;
  margin-left: 8px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #fff;
  font-size: 12px;
  font-weight: 600;
  color: #F34144;
}
.btn-coupon.off {
  background: #E6E6E6;
  color: #A3A1A7;
}
.btn-coupon.off span {
  color: #A3A1A7;
}

/*================================
Input Component
================================*/
input.default {
  outline: none;
  width: 400px;
  height: 40px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid #E2DFE4;
  background: #F7F7F8;
  transition: 0.3s;
}
input.default ::placeholder {
  color: #A3A1A7;
}
input.default:focus {
  border-color: #e94cf7;
  box-shadow: 0 0 5px rgba(233, 76, 247, 0.5);
  background: #fff;
}

/*================================
Layout
================================*/
.l-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.l-section {
  width: 100%;
}
.l-section .tit {
  font-size: 16px;
  font-weight: 700;
  color: #3E3D53;
}
.l-section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 2px solid #000;
}
.l-section__header h1 {
  margin-right: 25px;
}
.l-section__header label {
  font-size: 16px;
  font-weight: 700;
}
.l-section__header > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.l-section__header > div:last-child {
  margin-left: auto;
}