.form .ant-form {
    width: 100%;
}

.form .ant-form-item {
    margin: 0px;
}

.form .blueSpan{
    color: #0d88d4;
}

.form .redSpan{
    color: #f34144;
}

.form .rsWrapper .titleBox .title {
    width: fit-content;
    padding: 12px;
}

.form .rsWrapper .titleBox .title img {
    width: 24px;
    margin: 0 15px 0 0;
}

.form .rsWrapper .titleBox .title h1 {
    font-size: 24px;
    font-weight: 600;
    color: #3e3d53;
    white-space: nowrap;
}

.form .rsWrapper .titleBox .line {
    width: calc(100% / 2);
    border: 1px dashed #e6e6e6;
}


.form .info-area {
    align-items: flex-start;
    padding: 30px 0 4px;
    gap: 24px;
}

.form .info-area:last-of-type {
    border-bottom: none;
}

.form .ai,
.form .aiInfo {
    border-bottom: 1px solid #dbdbe0;
    padding: 30px 0;
}

.form .aiInfo {
    align-items: flex-start;
}

.form .info-area .title {
    width: 180px;
    flex-wrap: wrap;
}

.form .info-area .title img {
    width: 20px;
    margin: 0 14px 0 0;
}

.form .info-area .title h1 {
    font-size: 16px;
    font-weight: 600;
    color: #3e3d53;
}

.form .info-area .title #darkGrey {
    width: 132px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    margin: 12.5px 0 0;
    gap: 10px;
}


.form .info-area .content {
    width: calc(100% - 180px);
    flex-wrap: wrap;
}

.form .info-area .content .box {
    margin: 0 0 26px;
    align-items: flex-start;
}

.form .info-area .content .box.third {
    width: calc(100% / 3 - 27px);
}

.form .info-area .content .box .half>div {
    width: calc(50% - 6px);
}

.form .info-area .content .box .info {
    gap: 8px;
}


.form .info-area .content .box .info h1 {
    font-size: 12px;
    font-weight: 500;
    color: #464548;
}

.form .info-area .content .box .info input {
    width: 100%;
    height: 40px;
    background: #f7f7f8;
    border: 1px solid #e2dfe4;
    border-radius: 4px;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;
    resize: none;
}

.form .info-area .content .box .info textarea {
    background: #f7f7f8;
    border: 1px solid #e2dfe4;
    padding: 10px;
}

.form .info-area .content .box .info .plusBox {
    width: 180px;
    height: 180px;
}

.form .info-area .content .box .info .plusBox>div {
    height: 180px;
    border-radius: 4px;
    padding: 8px 16px;
    border: 1px dashed #e21dfd;
    background: #fcf7fd;
    cursor: pointer;
    overflow: hidden;
}

.form .info-area .content .box .info .plusBox .plus {
    background: #f0dbf4;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    color: #c49bcc;
    margin: 0 0 8px;
}

.form .info-area .content .box .info .plusBox p {
    color: #c49bcc;
    text-align: center;
    line-height: 1.2;
    font-size: 12px;

}


.form .info-area .content .box .info input::placeholder,
.form .myInfo .content .box .infoBox .box2 .info input::placeholder,
.form .sellerInfo .content .info input::placeholder,
.form .payInfo .content .info input::placeholder,
.form .myInfo .content .info input::placeholder,
.form .myInfo .content .info textarea::placeholder {
    color: #a3a1a7;
}

.form .info-area .content .box .info input:disabled,
.form .myInfo .content .box .infoBox .box2 .info input:disabled,
.form .sellerInfo .content .info input:disabled,
.form .myInfo .content .info input:disabled,
.form .payInfo .content .info input:disabled,
.form .myInfo .content .info textarea:disabled {
    background: #e9ecf1;
    border: 1px solid #e2dfe4;
    color: b-inputD;
}

.form .info-area .content .box .info input:focus,
.form .myInfo .content .box .infoBox .box2 .info input:focus,
.form .sellerInfo .content .info input:focus,
.form .myInfo .content .info input:focus,
.form .payInfo .content .info input:focus,
.form .myInfo .content .info textarea:focus {
    border: 1px solid #b041f3;
    background: #ffffff;
}

.form .info-area .content .box .info #darkGrey {
    width: 105px;
    height: 33px;
    border-radius: 4px;
    font-size: 14px;
}

.form .info-area .content .box .info>.rowBox {
    position: relative;
}

.form .info-area .content .box .info>.rowBox .absoluteBox {
    position: absolute;
    top: 40px;
    right: 0;
    width: 400px;
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

.form .info-area .content .box .info>.rowBox .absoluteBox .absolueTitle {
    margin: 0 0 31px;
}

.form .info-area .content .box .info>.rowBox .absoluteBox .absolueTitle h1 {
    font-size: 16px;
    font-weight: 700;
    color: #0e0e1f;
    margin: 0;
}

.form .info-area .content .box .info>.rowBox .absoluteBox .absolueTitle p {
    font-size: 24px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.form .info-area .content .box .info>.rowBox .absoluteBox .absolueTitle p:hover {
    color: #e21dfd
}

.form .info-area .content .box .info>.rowBox .absoluteBox .box p {
    font-size: 12px;
    color: #464548;
    margin: 0 0 9px;
}

.form .info-area .content .box .info>.rowBox .absoluteBox div #darkGrey {
    margin: 0 16px 0 0;
}

.form .info-area .content .box .info>.rowBox .absoluteBox div #darkGrey,
.form .info-area .content .box .info>.rowBox .absoluteBox div #purpleBtn {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
}

.form .info-area .content .box .info>.rowBox>input {
    width: calc(100% - 104px - 12px)
}

.form .info-area .content .box .info>.rowBox #darkGrey {
    width: 104px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
}

.form .info-area .content .box .info>.rowBox .ant-select {
    width: calc(50% - 6px);
    height: 40px;
}


.form .info-area .content .box .tabBox .tab {
    width: auto;
    padding: 8px 12px;
    border: 1px solid #cccccc;
    font-size: 13px;
    color: #0e0e1f;
    font-weight: 600;
    border-radius: 20px;
    margin: 0 12px 0 0;
}

.form .info-area .content .box .tagBox .tag {
    width: auto;
    padding: 8px 10px;
    border: 1px solid #cccccc;
    border-radius: 50px;
    margin: 0 12px 0 0;
}
.form .info-area .content .box .tagBox .tag>p {
    font-size: 13px;
    color: #0e0e1f;
    font-weight: 600;
    margin: 0 15px 0 0;
}

.form .info-area .content .box .tagBox .tag>img {
    width: 8px;
}

.form .ai .content>.tabBox {
    width: auto;
}

.form .ai .content .tabBox .aiTab {
    width: auto;
    height: auto;
    padding: 9px 12px;
    border: 1px solid #cccccc;
    border-radius: 30px;
    margin: 0 12px 0 0;

}

.form .ai .content .tabBox .aiTab h1 {
    font-size: 13px;
    color: #0e0e1f;
    margin: 0 10px 0 0;
}

.form .ai .content .tabBox .aiTab p {
    font-size: 13px;
    color: #0e0e1f;
}

.form .aiInfo .content .ant-checkbox,
.form .aiInfo .content .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

.form .aiInfo .content .ant-checkbox+span img {
    width: 21px;
    margin: 0 0 -6px;
}

.form .aiInfo .content>p {
    font-size: 12px;
    color: #464548;
    margin: 0 0 10px;
}

.form .aiInfo .content p span {
    color: #0d88d4;
}

.form .aiInfo .content .tabBox .tab {
    width: auto;
    padding: 8px 12px;
    border: 1px solid #cccccc;
    font-size: 13px;
    color: #0e0e1f;
    font-weight: 600;
    border-radius: 20px;
    margin: 0 12px 0 0;
}

.form .btnBox #logoutBtn,
.form .btnBox #darkGrey,
.form .btnBox #purpleBtn {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    margin: 14px 8px 0;

}

.form .secession {
    margin: 34px 0 0;
}

.form .secession .title #logoutBtn {
    width: 125px;
    height: 34px;
    border-radius: 24px;
}

.form .secession .title #logoutBtn img {
    width: 18px;
    margin: 0 10px 0 0;
}

/* ///////////// */
/* ///////////// */
/* SELLER MODAL */
/* ///////////// */
/* ///////////// */
.sellerModal .title {
    margin: 0 0 37px;
}

.sellerModal .title h1 {
    font-size: 24px;
    font-weight: 700;
    color: #0e0e1f;
}

.sellerModal .title p {
    font-size: 24px;
}

.sellerModal .check {
    margin: 0 0 17px;
}

.sellerModal .check p {
    font-size: 13px;
    color: #000000;
    margin: 0 0 -3px 20px;
    border-bottom: 1px solid #000000;
    line-height: 1;
}

.sellerModal .scroll {
    max-height: 600px;
    overflow-y: auto;
    justify-content: flex-start;
}

.sellerModal .scroll .boxWrapper1,
.sellerModal .scroll .boxWrapper2,
.sellerModal .scroll .boxWrapper3,
.sellerModal .scroll .boxWrapper4 {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin: 0 0 24px;
}

.sellerModal .scroll .boxWrapper1 .box p,
.sellerModal .scroll .boxWrapper2 .box p,
.sellerModal .scroll .boxWrapper3 .box p,
.sellerModal .scroll .boxWrapper4 .box p {
    font-size: 12px;
    color: #0e0e1f;
    margin: 0 0 9px;
}

.sellerModal .scroll .boxWrapper1 .box:first-child {
    width: 200px;
}

.sellerModal .scroll .boxWrapper1 .box:last-child {
    width: calc(100% - 200px - 40px)
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab {
    background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
    border-radius: 4px;
    padding: 4px;
    height: 40px;
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab div {
    width: calc(50% - 2px);
    font-size: 14px;
    color: #ffffff;
    border-radius: 4px;
    height: 100%;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab div:hover {
    background: #ffffff;
    color: #464548;
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab div#active {
    background: #ffffff;
    color: #464548;
}

.sellerModal .scroll .boxWrapper1 .box:last-child #darkGrey {
    width: 88px;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
}

.sellerModal .scroll .boxWrapper1 .box:last-child input {
    width: calc(100% - 88px - 12px);

}

.sellerModal .scroll .boxWrapper1 .box input,
.sellerModal .scroll .boxWrapper2 .box input,
.sellerModal .scroll .boxWrapper3 .box input,
.sellerModal .scroll .boxWrapper4 .box input {
    height: 40px;
    outline: none;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    border-radius: 4px;
    padding: 0 12px;
    font-size: 13px;
}

.sellerModal .scroll .boxWrapper1 .box input::placeholder,
.sellerModal .scroll .boxWrapper2 .box input::placeholder,
.sellerModal .scroll .boxWrapper3 .box input::placeholder,
.sellerModal .scroll .boxWrapper4 .box input::placeholder {
    color: #a3a1a7;
}

.sellerModal .scroll .boxWrapper1 .box input:disabled,
.sellerModal .scroll .boxWrapper2 .box input:disabled,
.sellerModal .scroll .boxWrapper3 .box input:disabled,
.sellerModal .scroll .boxWrapper4 .box input:disabled {
    border: 1px solid #e2dfe4;
    background: #e9ecf1;
    color: #524f56
}

.sellerModal .scroll .boxWrapper2>div:first-child {
    padding: 0 0 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    margin: 0 0 16px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box {
    width: 200px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box input {
    width: 100%;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child {
    width: 224px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child input {
    width: calc(100% - 88px - 12px);
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child #darkGrey {
    width: 88px;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child {
    width: 200px;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child {
    width: calc(100% - 200px - 40px);
}

.sellerModal .scroll .boxWrapper2>div:not(:last-child) {
    margin: 0 0 17px;
}

.sellerModal .scroll .boxWrapper2 div .box input {
    width: 100%;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
.sellerModal .scroll .boxWrapper2 div:nth-child(4) .box {
    width: calc(100% / 2 - 20px);
}

.sellerModal .scroll .boxWrapper3>div:first-child,
.sellerModal .scroll .boxWrapper4>div:first-child {
    margin: 0 0 17px;
}

.sellerModal .scroll .boxWrapper3 div:first-child .box,
.sellerModal .scroll .boxWrapper4 div:first-child .box {
    width: 200px;
}

.sellerModal .scroll .boxWrapper3 div .box input,
.sellerModal .scroll .boxWrapper4 div .box input {
    width: 100%;
}

.sellerModal .scroll .boxWrapper4 div:first-child .ant-select {
    width: 200px;
    height: 40px;
}

.sellerModal .scroll .boxWrapper4 div:last-child input {
    width: 212px;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content>p {
    font-size: 13px;
    margin: 0 16px;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .inputRound {
    width: 40px;
    border-radius: 100%;
    margin: 0 16px 0 0;
}


.sellerModal .scroll .boxWrapper4 div:last-child .box .content .round {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #000000;
    margin: 0 16px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .round:last-child {
    margin: 0 44px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content #darkGrey {
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
}

.sellerModal .scroll .btnBox #purpleBtn,
.sellerModal .scroll .btnBox #logoutBtn {
    width: 100px;
    height: 40px;
    margin: 0 5px;
    font-size: 14px;
    border-radius: 4px;
}

/* //////// */
/* //////// */
/* AIMODAL */
/* //////// */
/* //////// */

.aiModal .title {
    margin: 0 0 37px;
}

.aiModal .title h1 {
    font-size: 24px;
    font-weight: 700;
    color: #0e0e1f;
}

.aiModal .title p {
    font-size: 24px;
}

.aiModal .top {
    padding: 12px;
    border-radius: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
    align-items: flex-end;
    margin: 0 0 28px;
}

.aiModal .top .box {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #c2b8cb;
    height: 188px;
    width: calc(100% - 120px - 24px);
    background: #ffffff;
}

.aiModal .top .box .thumb {
    width: 123px;

}

.aiModal .top .box .thumb p {
    font-size: 12px;
    font-weight: 700;
    color: #464548;
    margin: 0 0 13px;
}

.aiModal .top .box .thumb .plusBox {
    width: 100%;
    height: 123px;
    border-radius: 4px;
    background: #f3f3f3;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.aiModal .top .box .thumb .plusBox .plus {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.1);
    font-size: 20px;
    color: #ffffff;
}

.aiModal .top .box .thumb .plusBox:hover {
    background: #f0dbf4;
}

.aiModal .top .box>div {
    width: calc(100% - 123px - 20px);
}

.aiModal .top .box>div .infoBox:not(:last-child) {
    width: 100%;
    margin: 0 0 12px;
}

.aiModal .top .box>div .infoBox p {
    font-size: 12px;
    font-weight: 700;
    color: #464548;
}

.aiModal .top .box>div .infoBox .ant-select {
    width: 300px;
    height: 40px;
}

.aiModal .top .box>div .infoBox input {
    height: 40px;
    width: 300px;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    outline: none;
    padding: 0 12px;
    border-radius: 4px;
    font-size: 13px;
}

.aiModal .top .box>div .infoBox input::placeholder {
    font-size: 13px;
    color: #a3a1a7;
}

.aiModal .top #purpleBtn {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 24px;
}

.aiModal .filter {
    margin: 0 0 16px;
}

.aiModal .filter .ant-select {
    border-radius: 4px;
    width: 120px;
    height: 40px;
}

.aiModal .filter>div {
    width: auto;
}

.aiModal .filter>div input {
    width: 140px;
    height: 40px;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    font-size: 13px;
}

.aiModal .filter>div p {
    font-size: 13px;
    margin: 0 12px;
}

.aiModal .filter>div #purpleBtn {
    width: 40px;
    height: 40px;
    margin: 0 0 0 12px;
    font-size: 20px;
    border-radius: 4px;
}

.aiModal .contentTop {
    border-top: 2px solid #000000;
    background: #f3f2fa;
    height: 48px;
}

.aiModal .contentTop div {
    font-size: 14px;
    color: #323146;
    font-weight: 500;
}

.aiModal .scroll {
    max-height: 350px;
    justify-content: flex-start;
    overflow: auto;
}

.aiModal .contentTop div:nth-child(1),
.aiModal .scroll .content div:nth-child(1) {
    width: 180px;
}

.aiModal .contentTop div:nth-child(2),
.aiModal .contentTop div:nth-child(4),
.aiModal .scroll .content div:nth-child(2),
.aiModal .scroll .content div:nth-child(4) {
    width: 120px;
}

.aiModal .scroll .content div:nth-child(4) .tab {
    padding: 4px 8px;
    font-size: 13px;
    color: #ffffff;
    width: auto;
    border-radius: 50px;
}

.aiModal .scroll .content div:nth-child(4) .tab.success {
    background: #525162;
}

.aiModal .scroll .content div:nth-child(4) .tab.accept {
    background: #0fc9f3
}

.aiModal .scroll .content div:nth-child(4) .tab.ing {
    background: #f8c930
}

.aiModal .scroll .content div:nth-child(4) .tab.fail {
    background: #fe393c
}

.aiModal .contentTop div:nth-child(3),
.aiModal .scroll .content div:nth-child(3) {
    width: 140px;
}

.aiModal .scroll .content div:nth-child(3) img {
    width: 80%;
}

.aiModal .contentTop div:nth-child(6),
.aiModal .scroll .content div:nth-child(6) {
    width: 80px;
}

.aiModal .scroll .content div:nth-child(6) img {
    width: 20px;
}

.aiModal .contentTop div:nth-child(5),
.aiModal .scroll .content div:nth-child(5) {
    width: calc(100% - 180px - 120px - 120px - 140px - 80px);
}

.aiModal .scroll .content {
    border-bottom: 1px solid #e2dfe4;
    padding: 8px 0;
}

.aiModal .scroll .content div:nth-child(5) {
    font-size: 16px;
    color: #464548;
}

.aiModal .scroll .content div {
    font-size: 13px;
    color: #000000;
    font-weight: 600;
}



@media (max-width : 800px) {

    .form .info-area {
        flex-direction: column;
        margin: 0 0 30px;
        padding: 0;
        gap: 0;
    }

    .form .sellerInfo,
    .form .ai,
    .form .aiInfo {
        padding: 0 0 30px;
        width: 100%;
        flex-direction: column;
        margin: 0 0 30px;
    }

    .form .info-area .title,
    .form .myInfo .title,
    .form .ai .title,
    .form .aiInfo .title,
    .form .sellerInfo .title,
    .form .payInfo .title,
    .form .secession title {
        width: 100%;
        margin: 0 0 30px;
    }

    .form .info-area .content,
    .form .myInfo .content,
    .form .ai .content,
    .form .aiInfo .content,
    .form .sellerInfo .content,
    .form .payInfo .content {
        width: 100%;
        flex-wrap: wrap;
    }

    .form .info-area .content .box,
    .form .myInfo .content .box,
    .form .myInfo .content .box .infoBox .box2,
    .form .myInfo .content .box .infoBoxUpdate .box2,
    .form .sellerInfo .content .info,
    .form .payInfo .content .info {
        flex-direction: column;
        margin: 0;
    }

    .form .info-area .content .box.third{
        width: 100%;
    }

    .form .info-area .content .box:nth-child(3) {
        flex-direction: row;
    }

    .form .info-area .content .box #logoutBtn {
        width: 80px;
        height: 30px;
        border-radius: 4px;
        font-size: 12px;
    }

    .form .info-area .content .box .info,
    .form .myInfo .content .box .infoBox .box2 .info,
    .form .myInfo .content .box .infoBoxUpdate .box2:not(:last-child) .info,
    .form .myInfo .content .box .infoBoxUpdate {
        width: 100%;
        margin: 0 0 30px;
        padding: 0;
    }

    .form .myInfo .content .box .thumb {
        width: 100%;
        margin: 0 0 30px;
    }

    .form .myInfo .content .box .thumb img {
        width: 140px;
        height: 140px;
    }

    .form .myInfo .content .box .infoBox {
        padding: 0;
        width: 100%;
    }

    .form .myInfo .content .box .infoBox .box2:first-child {
        margin: 0;
    }

    .form .myInfo .content .box .infoBox .box2:last-child,
    .form .myInfo .content .box .infoBoxUpdate .box2:last-child {
        flex-direction: row;
    }

    .form .info-area .content .box .info input,
    .form .myInfo .content .box .infoBoxUpdate .box2 .info input,
    .form .sellerInfo .content .info input,
    .form .payInfo .content .info input,
    .form .myInfo .content .info input,
    .form .myInfo .content .info textarea {
        font-size: 13px;
    }

    .form .info-area .content .box .info>.rowBox {
        flex-wrap: wrap;
    }

    .form .info-area .content .box .info:first-child>.rowBox .ant-select {
        width: 86px;
    }

    .form .info-area .content .box .info:first-child>.rowBox>.mobileInput {
        width: calc(100% - 86px - 10px);
    }

    .form .info-area .content .box .info>.rowBox #darkGrey.mobileBtn {
        margin: 7px 0 0;
        width: 100%;
    }

    .form .info-area .content .box:nth-child(3)#update {
        flex-direction: column;
    }

    .form .ai .content .box p span,
    .form .myInfo .content .info p,
    .form .myInfo .content .info h1 span,
    .form .info-area .content .box .info h1 span {
        font-size: 10px;
    }

    .form .myInfo .content .box .thumbUpdate {
        width: auto;
        margin: 0 0 30px;
    }

    .form .myInfo .content .box .thumbUpdate img {
        margin: 0 10px 0 0;
    }

    .form .myInfo .content .box .infoBoxUpdate .box2 .info {
        width: calc(50% - 5px);
    }

    .form .myInfo .content .info:nth-child(3) input {
        margin: 0 0 5px;
    }

    .form .myInfo .content .info textarea {
        height: 40px;
    }

    .form .ai .content>.tabBox {
        width: 100%;
        flex-wrap: wrap;
        margin: 10px 0 0;
    }

    .form .aiInfo #darkGrey {
        width: 100%;
        height: 40px;
        font-size: 14px;
        border-radius: 4px;
        margin: 30px 0 0;
    }

    .form .secession {
        margin: 0 0 30px;
    }

    .form .btnBox {
        justify-content: space-between;
    }

    .form .btnBox #logoutBtn,
    .form .btnBox #darkGrey,
    .form .btnBox #purpleBtn {
        margin: 0;
        width: calc(100% / 3 - 10px);

    }

    .form .btnBox#update {
        justify-content: space-between;
    }

    .form .btnBox#update #logoutBtn,
    .form .btnBox#update #purpleBtn {
        width: calc(50% - 8px);
    }

    .mobilePwModal .title {
        margin: 0 0 30px;
    }

    .mobilePwModal .title h1 {
        color: #0e0e1f;
        font-size: 20px;
        font-weight: 700;
    }

    .mobilePwModal .title p {
        font-size: 24px;
    }

    .mobilePwModal .box p {
        color: #464548;
        font-size: 12px;
        margin: 0 0 5px;
    }

    .mobilePwModal .box input {
        border: 1px solid #e2dfe4;
        background: #f7f7f8;
        height: 38px;
        width: 100%;
        margin: 0 0 20px;
        padding: 0 10px;
        border-radius: 4px;
    }

    .mobilePwModal .box input::placeholder {
        color: #a3a1a7;
    }

    .mobilePwModal div #darkGrey,
    .mobilePwModal div #purpleBtn {
        width: calc(50% - 10px);
        height: 40px;
        border-radius: 4px;
    }

    .sellerModal .scroll {
        max-height: none;
    }

    .sellerModal .check {
        justify-content: space-between;
    }


    .sellerModal .scroll .boxWrapper2>div,
    .sellerModal .scroll .boxWrapper2>div:first-child,
    .sellerModal .scroll .boxWrapper1,
    .sellerModal .scroll .boxWrapper2,
    .sellerModal .scroll .boxWrapper3,
    .sellerModal .scroll .boxWrapper4 {
        flex-direction: column;
        align-items: flex-start;
    }

    .sellerModal .scroll .boxWrapper3>div:first-child,
    .sellerModal .scroll .boxWrapper4>div:first-child {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
    }

    .sellerModal .scroll .boxWrapper4>div:first-child input,
    .sellerModal .scroll .boxWrapper4 div:first-child .ant-select,
    .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child,
    .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child,
    .sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
    .sellerModal .scroll .boxWrapper2 div:nth-child(4) .box,
    .sellerModal .scroll .boxWrapper1 .box:last-child,
    .sellerModal .scroll .boxWrapper2 div:first-child .box:last-child {
        width: 100%;
    }

    .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child,
    .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child,
    .sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
    .sellerModal .scroll .boxWrapper2 div:nth-child(4) .box,
    .sellerModal .scroll .boxWrapper1 .box {
        margin: 0 0 11px;
    }


    .sellerModal .scroll .boxWrapper2>div:not(:last-child),
    .sellerModal .scroll .boxWrapper1 .box:last-child {
        margin: 0 0 10px;
    }

    .sellerModal .scroll .boxWrapper3 div:first-child .box,
    .sellerModal .scroll .boxWrapper4 div:first-child .box,
    .sellerModal .scroll .boxWrapper2 div:first-child .box {
        width: 100%;
        margin: 0 0 10px;
    }


    .sellerModal .scroll .boxWrapper2 div:first-child .box:last-child input {
        width: calc(100% - 88px - 12px);
    }

    .sellerModal .scroll .boxWrapper4>div:last-child>div>div {
        flex-wrap: wrap;
    }

    .sellerModal .scroll .boxWrapper4 div:last-child .box .content .inputRound {
        margin: 0 5px 0 0;
    }

    .sellerModal .scroll .boxWrapper4 div:last-child .box .content .round {
        width: 10px;
        height: 10px;
        margin: 0 5px 0 0;
    }

    .sellerModal .scroll .boxWrapper4>div:last-child>div>div input {
        width: 40%;
    }

    .sellerModal .scroll .boxWrapper4 div:last-child .box .content #darkGrey {
        width: 100%;
        margin: 20px 0 0;
    }

    .sellerModal .scroll .btnBox {
        justify-content: space-between;
    }

    .sellerModal .scroll .btnBox #purpleBtn,
    .sellerModal .scroll .btnBox #logoutBtn {
        width: calc(50% - 8px);
    }
}



/* 프롬프트 판매 */
#promptSales .essential {
    height: 80px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 40px 40px 20px;
}

#promptSales .essential label,
#promptSales .essential lable a {
    height: 24px;
    flex-grow: 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
}

#promptSales .info-area .content .box .info.tagBox input{
    width: 200px;
    margin: 0 12px 0 0;
}