@charset "UTF-8";
#root {
  min-width: 360px;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
  line-height: 1;
}
.badge.pro {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
.badge.basic {
  background-image: inherit;
  background-color: #f8ab30;
}
.badge.beginner {
  background-image: inherit;
  background-color: #6d6c75;
}

.badge_s {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
  line-height: 1;
  width: auto;
  padding: 1px 2px;
  font-size: 11px;
  font-weight: 500;
  height: 23px;
  min-width: 44px;
  margin: 0;
}
.badge_s.pro {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}
.badge_s.basic {
  background-image: inherit;
  background-color: #f8ab30;
}
.badge_s.beginner {
  background-image: inherit;
  background-color: #6d6c75;
}

.ranking {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  gap: 12px;
  cursor: pointer;
  gap: 8px;
}
.ranking::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.music::before {
  background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.image::before {
  background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.video::before {
  background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking.text::before {
  background: url(/src/assets/images/common/i-rankText.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}

.ranking_s {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  gap: 12px;
  cursor: pointer;
  font-size: 14px;
  min-width: 100px;
  gap: 8px;
}
.ranking_s::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 20px;
  height: 20px;
  content: "";
}
.ranking_s::after {
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
  width: 16px;
  height: 16px;
  content: "";
}
.ranking_s.music::before {
  background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}
.ranking_s.image::before {
  background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}
.ranking_s.video::before {
  background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}
.ranking_s.text::before {
  background: url(/src/assets/images/common/i-rankText.svg) no-repeat center;
  width: 18px;
  height: 18px;
  content: "";
}

.btn -default {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border-color: #e2dfe4;
}
.btn -dark {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
}
.btn -dark:hover {
  color: #fff !important;
}
.btn -gra {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
}
.btn -red {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: #f34144 !important;
  color: #fff;
}
.btn -line {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
}
.btn -gray {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
}
.btn -defaultR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border-color: #e2dfe4;
  border-radius: 24px;
}
.btn -darkR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
  border-radius: 24px;
}
.btn -darkR:hover {
  color: #fff !important;
}
.btn -graR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%) !important;
  color: #fff;
  border-radius: 24px;
}
.btn -redR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: #f34144 !important;
  color: #fff;
  border-radius: 24px;
}
.btn -lineR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #3e3d53;
  color: #3e3d53;
  border-radius: 24px;
}
.btn -grayR {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
  border-radius: 24px;
}

#whiteBtn {
  min-width: 90px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  border-color: #ddd;
}
#whiteBtn img {
  width: 18px;
  margin: 0 12px 0 0;
}
#whiteBtn p {
  font-size: 14px;
}

#darkGrey3 {
  min-width: 77px;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  /* margin: 0 0 0 5px; */
}
#darkGrey3 div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#darkGrey3 div img {
  width: 18px;
  margin: 0 8px 0 0;
}
#darkGrey3 div img.delete {
  width: 16px;
  padding: 2px;
}
#darkGrey3 p {
  font-size: 14px;
}

.ant-btn-primary:hover,
.ant-btn-default:hover {
  box-shadow: inset 0 0 0 80px rgba(0, 0, 0, 0.1019607843);
}

.i-more {
  background: url(/src/assets/images/common/i-more.svg) no-repeat center;
  width: 18px;
  height: 32px;
  content: "";
  margin-left: 4px;
  margin-right: 4px;
}

button.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
button.icon::before {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: contain;
  content: "";
}
button.icon::before:hover {
  background-repeat: no-repeat;
  background-position: center;
}
button.icon:hover {
  background-repeat: no-repeat;
  background-position: center;
}

.share::before {
  background-image: url(/src/assets/images/common/i-share.svg);
}

.listen::before {
  background-image: url(/src/assets/images/common/i-listen.svg);
  width: 22px;
  height: 20px;
}
.listen::before:hover {
  background-image: url(/src/assets/images/common/i-listenA.svg);
}

.keep::before {
  background: url(/src/assets/images/common/i-keep.svg);
}
.keep::before:hover {
  background-image: url(/src/assets/images/common/i-keepA.svg);
}

.put::before {
  background: url(/src/assets/images/common/i-basket.svg);
  width: 22px;
}
.put::before:hover {
  background-image: url(/src/assets/images/common/i-basketA.svg);
}

.sale::before {
  background: url(/src/assets/images/common/i-sale.svg);
  width: 22px;
}
.sale::before:hover {
  background-image: url(/src/assets/images/common/i-sale.svg);
}

.siren {
  font-size: 0;
}
.siren::before {
  background: url(/src/assets/images/common/i-siren.svg);
  width: 22px;
}
.siren:hover {
  background: url(/src/assets/images/common/i-sirenOn.svg);
}
.siren.on::before {
  background: url(/src/assets/images/common/i-sirenOn.svg);
}

.delete {
  padding: 4px;
}
.delete::before {
  background: url(/src/assets/images/common/i_del.svg);
  width: 22px;
}
.delete::before:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}

/* 프롬프트 타이틀 탭 */
#depth2 > div > .title > div,
#prompt > div > .title > div {
  margin: 0 8px;
  width: auto;
  position: relative;
  gap: 36px;
}
#depth2 > div > .title div h1,
#prompt > div > .title div h1 {
  font-size: 32px;
  font-weight: bold;
  color: #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-wrap: nowrap;
}
#depth2 > div > .title div h1:hover,
#prompt > div > .title div h1:hover {
  color: #0e0e1f;
}
#depth2 > div > .title div#active h1,
#prompt > div > .title div#active h1 {
  color: #0e0e1f;
}
#depth2 > div > .title div .round,
#prompt > div > .title div .round {
  display: none;
}
#depth2 > div > .title div#active .round,
#prompt > div > .title div#active .round {
  display: flex;
  position: absolute;
  top: -6px;
  left: -6px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #fe5139;
}

#depth2 .title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 16px 28px;
}
#depth2 > div > .title .overflowBox {
  padding: 0;
  height: 40px;
}
#depth2 > div > .title .overflowBox div h1 {
  padding: 0 12px 0;
}
#depth2 > div > .title .overflowBox div#active .round {
  top: 0px;
  left: 0px;
}

#pinkBtn2 {
  font-weight: 600;
  transition: none;
}

@media (max-width: 700px) {
  #prompt > div > .title {
    padding: 30px 0 20px;
  }
  #prompt > div > .title div {
    gap: 20px;
  }
  #prompt > div > .title div h1 {
    font-size: 26px;
  }
  #depth2 .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 20px;
  }
  #depth2 > div > .title .overflowBox div h1 {
    font-size: 26px;
  }
  #depth2 > div > .title .overflowBox div#active .round {
    top: 0px;
    left: 0px;
  }
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal .ant-modal-content,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal .ant-modal-content,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal .ant-modal-content {
  padding: 0;
}
#root:has(section.userPage) + div .ant-modal-header:where(.css-dev-only-do-not-override-1x0dypw).ant-modal .ant-modal-title,
#root:has(section.userPage) + div .ant-modal-header .ant-modal-title,
.ant-modal-root .ant-modal-header:where(.css-dev-only-do-not-override-1x0dypw).ant-modal .ant-modal-title,
.ant-modal-root .ant-modal-header .ant-modal-title,
.userPage .ant-modal-header:where(.css-dev-only-do-not-override-1x0dypw).ant-modal .ant-modal-title,
.userPage .ant-modal-header .ant-modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  padding: 20px 24px;
  font-size: 24px;
}
#root:has(section.userPage) + div .ant-modal-body,
.ant-modal-root .ant-modal-body,
.userPage .ant-modal-body {
  padding: 16px 40px;
}
#root:has(section.userPage) + div .ant-modal-body .ant-modal-confirm-body-wrapper,
.ant-modal-root .ant-modal-body .ant-modal-confirm-body-wrapper,
.userPage .ant-modal-body .ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
#root:has(section.userPage) + div .ant-modal-body:before, #root:has(section.userPage) + div .ant-modal-body:after,
.ant-modal-root .ant-modal-body:before,
.ant-modal-root .ant-modal-body:after,
.userPage .ant-modal-body:before,
.userPage .ant-modal-body:after {
  display: none;
}
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper > input.ant-input,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper > input.ant-input,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper > input.ant-input {
  display: flex;
  height: 38px;
  padding: 7px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--b-input, #e2dfe4);
  background: #f7f7f8;
}
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper > input.ant-input:hover,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper > input.ant-input:hover,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper > input.ant-input:hover {
  background-color: transparent;
}
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper.ant-input-password input,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper.ant-input-password input,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper.ant-input-password input {
  border: 0;
}
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover {
  background: transparent;
}
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover,
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus,
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within {
  border-color: #c718e2;
}
#root:has(section.userPage) + div .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within input,
.ant-modal-root .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within input,
.userPage .ant-modal-body :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within input {
  background: transparent;
}
#root:has(section.userPage) + div .ant-modal-body .btnBox button.btn-default,
.ant-modal-root .ant-modal-body .btnBox button.btn-default,
.userPage .ant-modal-body .btnBox button.btn-default {
  border: 1px solid #525162;
}
#root:has(section.userPage) + div .ant-modal-footer,
#root:has(section.userPage) + div .ant-modal-confirm-btns,
.ant-modal-root .ant-modal-footer,
.ant-modal-root .ant-modal-confirm-btns,
.userPage .ant-modal-footer,
.userPage .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 40px 24px;
}
#root:has(section.userPage) + div #populerPromptSlider .list > div .absoluteBox .title > p,
.ant-modal-root #populerPromptSlider .list > div .absoluteBox .title > p,
.userPage #populerPromptSlider .list > div .absoluteBox .title > p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 4px 10px;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #f138fc;
  font-size: 40px;
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body-wrapper::before,
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body-wrapper::after,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body-wrapper::before,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body-wrapper::after,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body-wrapper::before,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body-wrapper::after {
  display: none;
  content: "";
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  gap: 24px;
  padding: 36px 40px 16px;
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-content *,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-content *,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-content * {
  font-family: "Pretendard Variable", "Pretendard";
  color: #000;
  font-size: 18px;
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body > .anticon,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body > .anticon,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-modal-confirm .ant-modal-confirm-body > .anticon {
  margin-inline-end: 0px;
}
#root:has(section.userPage) + div .ant-modal-confirm-btns,
.ant-modal-root .ant-modal-confirm-btns,
.userPage .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
#root:has(section.userPage) + div .ant-btn-primary,
.ant-modal-root .ant-btn-primary,
.userPage .ant-btn-primary {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}
#root:has(section.userPage) + div :where(.ant-modal.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div :where(.ant-modal.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.ant-modal.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.ant-modal.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.ant-modal.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.ant-modal.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#root:has(section.userPage) + div .ant-btn-default,
.ant-modal-root .ant-btn-default,
.userPage .ant-btn-default {
  background-color: #ffffff;
  border-color: #e2dfe4;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}
#root:has(section.userPage) + div .ant-btn-default.btn-gray,
.ant-modal-root .ant-btn-default.btn-gray,
.userPage .ant-btn-default.btn-gray {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #a3a1a7 !important;
  border-color: #a3a1a7 !important;
  color: #fff;
}
#root:has(section.userPage) + div .ant-btn-default.btn-dark,
.ant-modal-root .ant-btn-default.btn-dark,
.userPage .ant-btn-default.btn-dark {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #525162 !important;
  border-color: #525162;
  color: #fff;
}
#root:has(section.userPage) + div .ant-btn-default.btn-dark:hover,
.ant-modal-root .ant-btn-default.btn-dark:hover,
.userPage .ant-btn-default.btn-dark:hover {
  color: #fff !important;
}
#root:has(section.userPage) + div .ant-btn-default.icon,
.ant-modal-root .ant-btn-default.icon,
.userPage .ant-btn-default.icon {
  border: 0;
  padding: 4px;
  min-width: auto;
}
#root:has(section.userPage) + div .ant-btn-default.icon span,
#root:has(section.userPage) + div .ant-btn-default.icon p,
.ant-modal-root .ant-btn-default.icon span,
.ant-modal-root .ant-btn-default.icon p,
.userPage .ant-btn-default.icon span,
.userPage .ant-btn-default.icon p {
  display: none;
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000;
  border-color: #e2dfe4;
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn:not(:disabled):focus-visible,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn:not(:disabled):focus-visible,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn:not(:disabled):focus-visible {
  outline: none;
  outline-offset: 0;
}
#root:has(section.userPage) + div :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active,
.ant-modal-root :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active,
.userPage :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):active {
  background: #f138fc;
  color: #fff;
}

.major {
  display: flex;
  padding: 0;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.major img {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: auto;
  height: 26px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px 0 0;
  padding: 2px;
  line-height: 1;
  width: auto;
  padding: 1px 2px;
  font-size: 11px;
  font-weight: 500;
  height: 23px;
  min-width: 44px;
  margin: 0;
}

.majorModel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 12px;
  gap: 2px;
  text-wrap-mode: nowrap;
  flex-wrap: wrap;
}
.majorModel span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding: 4px 5px;
  background: linear-gradient(90deg, rgba(202, 121, 252, 0.7) 0%, rgba(255, 94, 182, 0.7) 100%);
  word-wrap: nowrap;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 1;
}
.majorModel span.title {
  color: var(--c-ff, #fff);
  text-align: center;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.5);
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 12.1px */
  letter-spacing: -0.22px;
  background: transparent;
  margin: 0;
}

.classRank {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 4px;
}
.classRank span {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  color: #fff;
}
.classRank span::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-image: url("/src/assets/images/common/btn_down.svg") no-repeat right center;
}
.classRank .ant-dropdown-menu-title-content {
  gap: 8px;
}
.classRank .ant-dropdown-menu-title-content *,
.classRank .ant-dropdown-menu-title-content p {
  color: #0e0e1f !important;
}

.absolBox,
.absoluteBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.absolBox .user,
.absoluteBox .user {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  border-radius: 8px;
  padding: 8px;
}
.absolBox .user p,
.absolBox .user h1,
.absoluteBox .user p,
.absoluteBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
.absolBox .user > div,
.absoluteBox .user > div {
  padding: 0;
}
.absolBox .user img,
.absoluteBox .user img {
  display: none;
}
.absolBox .title,
.absoluteBox .title {
  margin: 0;
}
.absolBox .title h1,
.absoluteBox .title h1 {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  line-height: 1.4;
  margin: 0;
}
.absolBox .title .greyBox h1,
.absoluteBox .title .greyBox h1 {
  text-shadow: none;
}

.absolBox .user {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}
.absolBox .user p,
.absolBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
.absolBox .title {
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  padding: 8px;
  margin: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
}
.absolBox .title h1,
.absolBox .title p {
  background: none;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.absolBox .title h1 {
  line-height: 1.4;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
  color: #ffffff;
}
.profile .flexColStart {
  gap: 4px;
}
.profile h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}
.profile h2 {
  font-size: 11px;
  font-weight: 400;
}
.profile .snsArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.profile .snsArea a img {
  margin: 0;
  width: 16px;
}
.profile .snsArea a img:hover {
  fill: #ca79fc;
}
.profile .followBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.profile .followBox li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: #fff;
}
.profile .followBox li p {
  font-size: 13px;
}
.profile .followBox li span {
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
}
.profile .followBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
.profile .followBtn button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #b041f3;
  background: rgba(230, 26, 254, 0.2);
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  gap: 4px;
}
.profile .followBtn button::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url(/src/assets/images/profile/follow.svg) no-repeat center center;
}
.profile .followBtn button.donation::before {
  content: "";
  background: url(/src/assets/images/profile/donation.svg) no-repeat center center;
}
.profile .followBtn button.request::before {
  content: "";
  background: url(/src/assets/images/profile/request.svg) no-repeat center center;
}
.profile .followBtn .bottom {
  padding: 4px 0;
}
.profile .majorModel {
  padding: 0;
}
.profile .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profile .bottom div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}
.profile .bottom div h3 {
  font-weight: 500;
}

#whiteBtn:hover {
  background: inherit;
  color: inherit;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0;
  background: url(/src/assets/images/common/bg-dash.png) repeat-x 0 38px;
  padding: 20px 0 60px;
  margin: 0;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text span {
  background: #fff;
  font-size: 24px;
  padding: 0 12px;
  font-weight: 600;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text::before {
  position: relative;
  width: 36px;
  height: 24px;
  border-block-start: 1px solid transparent;
  border-block-start-color: inherit;
  border-block-end: 0;
  content: "";
  margin-top: -22px;
  background: #fff url(/src/assets/images/profile/profile_01.svg) no-repeat 16px 0px;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text::after {
  width: 0;
}

.profileTitle,
#myHome .rsWrapper .promptBox {
  display: flex;
  width: calc(100% - 80px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: url(/src/assets/images/common/bg-dash.png) repeat-x center;
}
.profileTitle div,
#myHome .rsWrapper .promptBox div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  gap: 12px;
  padding: 0 24px;
  font-size: 20px;
  font-weight: 700;
}
.profileTitle div img,
#myHome .rsWrapper .promptBox div img {
  height: 24px;
}
.profileTitle div button,
#myHome .rsWrapper .promptBox div button {
  font-size: 14px;
  border-radius: 50px;
  padding: 4px;
  height: 24px;
  width: 24px;
  margin: 0;
}
.profileTitle div button:hover,
#myHome .rsWrapper .promptBox div button:hover {
  border-radius: 50px;
  background: #e3e1e4;
  transition: all 0.2s ease;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before, :where(.css-dev-only-do-not-override-1x0dypw).ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: none;
}

.profileList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
}

#myHome .rsWrapper > .box div {
  font-size: 20px;
}
#myHome > .top > .rsWrapper > .profile {
  padding: 12px;
}

#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox {
  padding: 0;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user {
  width: 100%;
  margin-bottom: 0;
  padding: 4px 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user p,
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .user img {
  display: none;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox .price {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 8px;
}
#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents .absoluteBox div {
  justify-content: start;
}

#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p,
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child h1,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p {
  width: fit-content;
}
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p p,
#listWrapper .rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child p h1,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p p,
#listWrapper .rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child p h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}

.contentsList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.contentsList .contents {
  margin: 0;
}

#popularWrapper .rankBox .rsWrapper .infoBox .contentsBox .contentsList .contents {
  margin: 0;
}

.rankBox .absolBox,
.rankBox .absoluteBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rankBox .absolBox .flexRowBetween,
.rankBox .absoluteBox .flexRowBetween {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 8px;
  padding: 0 8px;
  justify-content: space-between;
}
.rankBox .absolBox .flexRowBetween .user,
.rankBox .absoluteBox .flexRowBetween .user {
  padding: 16px 0;
  background: none;
}
.rankBox .absolBox .flexRowBetween .user p,
.rankBox .absolBox .flexRowBetween .user h1,
.rankBox .absoluteBox .flexRowBetween .user p,
.rankBox .absoluteBox .flexRowBetween .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
.rankBox .absolBox h1,
.rankBox .absoluteBox h1 {
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.rankBox .absolBox .priceBox,
.rankBox .absoluteBox .priceBox {
  padding: 16px;
  justify-content: flex-end;
  align-items: flex-end;
}

#infoWrapper .listBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
#infoWrapper .listBox .searchBox form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#infoWrapper .listBox .searchBox form span {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
#infoWrapper .listBox .searchBox form span div {
  width: auto;
}
#infoWrapper .listBox .list {
  cursor: pointer;
}
#infoWrapper .listBox .list .card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
#infoWrapper .listBox .list .card p {
  line-height: 1;
  z-index: 2;
}
#infoWrapper .listBox .list:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(176, 65, 243, 0.8) 0%, rgba(236, 21, 255, 0.8) 100%);
  z-index: 1;
  transition: all 0.2s ease;
}
#infoWrapper .listBox .list:hover .image-wrapper {
  background: linear-gradient(90deg, rgba(176, 65, 243, 0.8) 0%, rgba(236, 21, 255, 0.8) 100%);
  opacity: 0.3;
}
#infoWrapper .listBox .list:nth-child(4n) {
  margin-right: 0;
}

header {
  box-shadow: inset 0 -1px 0px 0px rgb(230, 230, 230);
}

#header .rsWrapper {
  cursor: pointer;
}
#header .rsWrapper .userWrapper .userInfo {
  padding: 16px 8px;
}
#header .rsWrapper .iconBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
}
#header .rsWrapper .iconBox .pre {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #e2dfe4;
  padding: 8px 12px;
  border-radius: 8px;
  height: 36px;
  margin-left: 4px;
  font-weight: 600;
  text-wrap-mode: nowrap;
}
#header .rsWrapper .iconBox .pre img {
  margin: 0;
  width: 16px;
}
#header .rsWrapper .iconBox .pre:hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
#header .rsWrapper .iconBox .pre:last-child {
  border-color: #c718e2;
  color: #c718e2;
}

.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .flexRowCenter {
  width: auto;
}
.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .flexRowCenter img {
  display: none;
}
.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rsWrapper .cartWrapper .cartBox .cart .thumbnailBox > div .top .close img {
  width: 72%;
  height: 72%;
}

.profileSection .rsWrapper.flexColCenter {
  width: 100%;
  margin: 0;
  min-width: 320px;
}
.profileSection .rsWrapper.flexColCenter #myHome .promptThumList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1600px;
  padding: 24px 0 64px;
  gap: 28px;
}
.profileSection .rsWrapper.flexColCenter #myHome .promptThumList #populerPromptSlider {
  margin-bottom: 12px;
}
.profileSection .rsWrapper.flexColCenter #myHome .top .anticon {
  font-size: 16px;
}
.profileSection .rsWrapper.flexColCenter #myHome .top :where(.css-dev-only-do-not-override-1x0dypw).ant-rate .ant-rate-star:not(:last-child) {
  margin: 0;
}
.profileSection .rsWrapper.flexColCenter #plan {
  width: 1600px;
}
.profileSection .rsWrapper :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
  width: 100%;
}

.rsWrapper .listBox .gerneBox .absolBox div .user,
.rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child,
.rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 8px;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 8px;
}
.rsWrapper .listBox .gerneBox .absolBox div .user img,
.rsWrapper .staff .staffList .staffBox .absoluteBox > div:first-child img,
.rsWrapper .newContents .staffList .staffBox .absoluteBox > div:first-child img {
  display: none;
}

.rsWrapper .info div .contentsBox .musicBox .music > .user,
.rsWrapper .thumbBox .absolBox > div > div {
  width: auto;
}
.rsWrapper .info div .contentsBox .musicBox .music > .user img,
.rsWrapper .thumbBox .absolBox > div > div img {
  display: none;
}
.rsWrapper .info div .contentsBox .musicBox .music > .user p,
.rsWrapper .info div .contentsBox .musicBox .music > .user h1,
.rsWrapper .thumbBox .absolBox > div > div p,
.rsWrapper .thumbBox .absolBox > div > div h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}

#popularSlider .list > div .absoluteBox .user {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
  padding-left: 0;
  height: 36px;
  border-radius: 16px 16px 0 0;
}
#popularSlider .list > div .absoluteBox .user p,
#popularSlider .list > div .absoluteBox .user h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}
#popularSlider .list > div .absoluteBox .user div {
  height: auto;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 8px;
}
#popularSlider .list > div .absoluteBox .user div img {
  display: none;
}
#popularSlider .list > div .absoluteBox .user .aiBox p,
#popularSlider .list > div .absoluteBox .user .aiBox h1 {
  padding: 4px;
  border-radius: 8px;
  background: #141424;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%; /* 11px */
  width: fit-content;
}

.contentsList .absoluteBox .flexRowStart img {
  display: none;
}

.absoluteBox .title .greyBox .imgBox .ant-rate {
  font-size: 14px;
}
.absoluteBox .title .greyBox .imgBox .ant-rate:where(.css-dev-only-do-not-override-1x0dypw).ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 0px;
}

#otherWrapper .rsWrapper > div .content .listBox .list .absoluteBox .user img {
  display: none;
}

@media (max-width: 1280px) {
  #musicWrapper .rsWrapper > div:first-child {
    flex-direction: row;
  }
}
#musicWrapper .filter .listBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title {
  width: 75%;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div {
  padding: 4px 0;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div img {
  display: none;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div p,
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title div h1 {
  border-radius: 12px;
  border: 1px solid #c4c3c7;
  background: #fff;
  width: auto;
  color: #6d6c75;
  font-size: 11px;
  padding: 4px;
  font-weight: 700;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus, #musicWrapper .rsWrapper > div .content .listBox .list .titleBox .title :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within {
  border: 0;
  box-shadow: none;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .userBox {
  width: 25%;
}
#musicWrapper .rsWrapper > div .content .listBox .list .titleBox .gerneBox .absolBox div .title div img {
  background: #fff;
  border-radius: 100%;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox {
  gap: 4px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox h1 {
  min-width: 24px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div {
  padding-left: 4px;
  font-weight: 600;
}
#musicWrapper .rsWrapper > div .content .listBox .list .rankBox div img {
  width: 16px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .views .i-playHit {
  background: url(/src/assets/images/common/i-play.svg) no-repeat left center;
  padding-left: 24px;
}
#musicWrapper .rsWrapper > div .content .listBox .list .play img {
  width: calc(100% - 2px);
}
#musicWrapper .rsWrapper > div .content .listBox .list p {
  font-size: 14px;
  font-weight: 500;
}
#musicWrapper .rsWrapper > div .content .titleBox .search img {
  margin: 0;
}

.reWrapper .titleBox .search {
  gap: 16px;
}
.reWrapper .titleBox .search img {
  margin: 0;
}
.reWrapper .titleBox .search :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper {
  padding: 0;
  background: transparent;
  border-width: 0;
}

#musicInfo .rsWrapper .thumbBox .absolBox > div > img {
  width: 48px;
}

#musicBox .affixBox .tabBox,
#musicBox .affixBox .top {
  background: rgba(255, 255, 255, 0.3);
}
#musicBox .listBox.playlist .list .titleBox .user {
  margin: 0;
}
#musicBox .listBox.playlist .list .titleBox .user img {
  display: none;
}
#musicBox .listBox.playlist .list .titleBox .user p,
#musicBox .listBox.playlist .list .titleBox .user h1 {
  border-radius: 12px;
  border: 1px solid #c4c3c7;
  background: #fff;
  width: auto;
  color: #6d6c75;
  font-size: 11px;
  padding: 4px;
  font-weight: 700;
}
#musicBox .listBox.playlist .list .titleBox .user h1 {
  color: #0e0e1f;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background: transparent;
}
#musicBox .listBox.playlist .list p {
  font-size: 24px;
  width: 16px;
}

.thumbBox .absolBox > div {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
  min-height: 36px;
  display: flex;
  align-items: start;
  justify-content: start;
}

.thumbBox .absolBox .name {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%);
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

#aiWrapper > h1 span {
  font-size: 48px;
}

.mainIcon {
  margin: -100px 78px -20px 0;
  width: 136px;
}

#newSlider .musicBox {
  padding: 10px;
}
#newSlider .musicBox div:first-child {
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

#top10 #newSlider .slick-list {
  height: 290px;
}
#top10 #newSlider .slick-center {
  width: 280px;
}
#top10 #newSlider .slick-current,
#top10 #newSlider .slick-current.slick-active:hover {
  transform: scale(1.57895);
}
#top10 #newSlider .slick-active:hover {
  transform: scale(1.05);
}
#top10 #newSlider #active {
  cursor: pointer;
}

.titleBox .noMore:hover {
  cursor: default;
}
.titleBox .search {
  border-radius: 4px;
  border: 1px solid var(--b-input, #e2dfe4);
}
.titleBox .search:has(:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined):focus, .titleBox .search:has(:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined):focus-within {
  border: 1px solid #c718e2;
  background: #fff !important;
}
.titleBox .search:has(:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined):hover {
  background: inherit;
}
.titleBox .search :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}
.titleBox .search :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined img {
  margin: 0;
}
.titleBox .search :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover {
  background: inherit;
}
.titleBox .search :where(.css-dev-only-do-not-override-1x0dypw).ant-input-affix-wrapper .ant-input-clear-icon {
  font-size: 20px;
}

.searchBox > div:last-child div {
  gap: 12px;
}

#builderWrapper .bgImg .builderBox > div {
  flex-wrap: wrap;
}

#builder div .content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
#builder div .content:has(.builderGuide) {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
#builder div .content .layout .ant-layout {
  width: 100%;
  margin: 0;
}
#builder div .content .layout .ant-layout header {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
#builder div .content .layout .ant-layout main {
  padding: 2px 0 0;
  margin: 0;
}
#builder div .content .layout .ant-layout main .imgThum {
  background-color: #f7f7f8;
}
#builder div .content .layout .ant-layout main .builderGuideImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 200px;
}
#builder div .content .layout .ant-layout aside .btnArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#builder div .content .layout .ant-layout aside .btnArea button.ant-btn {
  height: 32px;
  padding: 0 8px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider {
  padding: 28px 16px !important;
  border-right: 2px solid var(--bg-gray, #f3f3f3);
  border-left: 2px solid var(--bg-gray, #f3f3f3);
  background: #fff;
  gap: 24px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch {
  height: 29px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch.ant-switch-checked {
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch span {
  height: 22px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-layout-sider {
  background: #fff;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-layout-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-layout-sider :where(.css-dev-only-do-not-override-1x0dypw).ant-tree {
  margin-top: 12px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-switch-handle {
  top: 4px;
  left: 4px;
  height: 22px;
  width: 22px;
  border-radius: 100px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-switch-handle ::before {
  border-radius: 100px;
}
#builder div .content .layout .ant-layout .ant-layout-has-sider .ant-switch .ant-switch-inner {
  height: 24px;
}
#builder div .content .layout .ant-layout .top {
  display: none;
}
#builder div .content .layout .ant-layout .listBox .imgThum {
  width: 100%;
  height: 100%;
}
#builder div .content .builderGuide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#builder div .content .builderGuide h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(to right, #13d5df, #f138fc);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin: 48px 0 80px;
  line-height: 1.2;
}
#builder div .content .builderGuide h2 {
  color: var(--t-dark, #0e0e1f);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  padding-bottom: 32px;
}
#builder div .content .builderGuide h2 b {
  color: var(--t-pink, #c718e2);
  font-weight: 500;
  padding-bottom: 24px;
}
#builder div .content .builderGuide .tab {
  border: 0;
}
#builder div .content .listBox .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#builder div .content .listBox .list:has(.thumBorder[style="disply: block"]) > .absoluteBox {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}
#builder div .content .listBox .list .absoluteBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#builder div .content .listBox .list .thumBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 4px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
}
#builder div .content .listBox .list .thumBorder :hover {
  border: 4px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-image-slice: 1;
}
#builder div .content :where(.css-dev-only-do-not-override-1x0dypw).ant-switch .ant-switch-inner {
  padding-inline-start: 32px;
  padding-inline-end: 8px;
}
#builder div .headerMenu.ant-menu-horizontal > .ant-menu-item + li span {
  border-bottom-left-radius: 16px;
}
#builder div .selectWrapper {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 16px;
}
#builder div .selectWrapper button {
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #e94cf7 !important;
  border-color: #e94cf7;
  color: #fff;
}
#builder div .right > h2 {
  position: relative;
}
#builder div .right > h2 button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 12px;
  width: 24px;
  height: 24px;
  background: url(/src/assets/images/common/btn-downB.svg) no-repeat right 5px center;
  content: "";
}
#builder div .right > h2 button.btn-fold {
  transform: rotate(180deg);
  background-image: url(/src/assets/images/common/btn-downB.svg);
}
#builder div .right .selectBox > div .ant-btn-default {
  min-width: auto;
}

.soundEffectModal .top {
  margin: 0 0 20px;
}
.soundEffectModal .top h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: 700;
}
.soundEffectModal .top .svg {
  cursor: pointer;
}
.soundEffectModal .top .svg svg path {
  transition: all 0.2s ease-in-out;
}
.soundEffectModal .top .svg:hover svg path {
  fille: #ff5eb6;
}
.soundEffectModal .scrollBox {
  padding: 4px 0 4px 4px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  overflow: auto;
  height: 420px;
  align-items: flex-start;
  justify-content: flex-start;
}
.soundEffectModal .scrollBox div {
  min-width: 300px;
  width: 100%;
}
.soundEffectModal .btnBox {
  margin: 40px 0 0;
  justify-content: center;
}
.soundEffectModal .btnBox #purpleBtn,
.soundEffectModal .btnBox #logoutBtn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
}
.soundEffectModal .btnBox #purpleBtn.btn-default,
.soundEffectModal .btnBox #logoutBtn.btn-default {
  border: 1px solid #525162;
}

#popularWrapper .rankBox .rsWrapper .thumbnail .absoluteBox {
  padding: 0;
}

#myProfile > .rsWrapper.flexColCenter {
  max-width: 1520px;
  justify-content: center;
  margin: auto;
  min-width: 200px;
}
#myProfile > .rsWrapper.flexColCenter + .ant-form {
  width: 100%;
}
#myProfile .myInfo .content .box {
  gap: 40px;
}
#myProfile .myInfo .content .box .noResult {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #f7f7f8;
  border-radius: 8px;
  padding: 20px;
  color: #6d6c75;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  height: 140px;
}
#myProfile .myInfo .content .box .thumb + .infoBox .box2 .info {
  width: calc(50% + 90px);
}
#myProfile .myInfo .content .box .thumb + .infoBox .box2 .info:first-child {
  width: calc(50% - 53px);
}
#myProfile .myInfo .content .box .thumbUpdate {
  width: 140px;
}
#myProfile .myInfo .content .box .thumbUpdate :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item {
  padding: 0;
  width: 140px;
  height: 140px;
}
#myProfile .myInfo .content .box .thumbUpdate :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item .ant-upload-btn {
  padding: 0;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-wrapper {
  border-radius: 4px;
  overflow: hidden;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-form-item-control {
  display: flex;
  justify-content: center;
  align-items: center;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-form-item-control .ant-form-item-control-input {
  align-items: flex-start;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-drag {
  background: transparent;
  border: 0;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-drag-container {
  width: 100%;
  position: relative;
}
#myProfile .myInfo .content .box .thumbUpdate .ant-upload-drag-container .imageContainer {
  width: 100%;
}
#myProfile .myInfo .content .box .profileUpload,
#myProfile .myInfo .content .box :where(.css-dev-only-do-not-override-1x0dypw).ant-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
}
#myProfile .myInfo .content .infoBoxUpdate {
  width: 100%;
  padding: 0;
  gap: 30px;
}
#myProfile .myInfo .content .infoBoxUpdate img {
  border-radius: 16px;
}
#myProfile .myInfo .info .ant-image {
  width: 100%;
  margin: 0;
  border: none;
  background: transparent;
}
#myProfile .myInfo .info h1 + div {
  width: 100%;
  margin: 0;
  border: none;
  background: transparent;
}
#myProfile .myInfo .info h1 + div :where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0;
}
#myProfile .myInfo .info .plusBannerBox .ant-form-item,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container {
  width: 100%;
}
#myProfile .myInfo .info .plusBannerBox .ant-form-item .imageContainer,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container .imageContainer {
  position: relative;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 150px;
  max-height: 300px;
  overflow: hidden;
}
#myProfile .myInfo .info .plusBannerBox .ant-form-item .ant-form-item-control,
#myProfile .myInfo .info .plusBannerBox .ant-form-item .ant-form-item-control-input,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container .ant-form-item-control,
#myProfile .myInfo .info .plusBannerBox .ant-upload-drag-container .ant-form-item-control-input {
  height: min-content;
}
#myProfile .myInfo .info .plusBannerBox .plus {
  margin: 20px 0 4px;
}
#myProfile .myInfo .info .plusBannerBox p {
  margin-block-end: 20px;
}
#myProfile .myInfo .about_me {
  display: flex;
  padding: 4px 0px;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  min-height: 30px;
}

.userInfo .box:last-child {
  margin-bottom: 0;
}
.userInfo .info span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.userInfo .info .ant-select-selection-wrap {
  flex-direction: row;
}

h1 {
  font-weight: 600;
}

.content:has(.flexColStart.box) {
  gap: 0;
}
.content:has(.flexColStart.box) .flexColStart.box {
  gap: 0;
}

.noResult {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #f7f7f8;
  border-radius: 8px;
  padding: 20px;
  color: #6d6c75;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
}

.communitySection .rsWrapper.flexColCenter {
  padding-bottom: 20px;
}

#infoWrapper .listBox {
  justify-content: flex-start;
  gap: 36px;
  width: auto;
  margin: 0 auto 40px;
  width: 100%;
}
#infoWrapper .listBox .list {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 16px;
  width: 352px;
  gap: 36px;
}

#depth2 .titleBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}
#depth2 .titleBox .title {
  padding: 0;
}

.boardsSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.boardsSection .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1520px;
  display: flex;
  padding-bottom: 8px;
  margin: auto;
  padding: 24px 0;
  border-bottom: 2px solid #000;
  margin: 0 16px;
  width: calc(100% - 32px);
}
.boardsSection .title .titleBox {
  color: var(--t-dark, #0e0e1f);
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  justify-content: start;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  text-wrap-mode: nowrap;
}
.boardsSection .title .titleBox::before {
  background: url(/src/assets/images/common/i-community.svg) no-repeat left center;
  content: "";
  width: 24px;
  height: 24px;
}
.boardsSection .title .searchBox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: center;
  gap: 8px;
}
.boardsSection .title .searchBox div {
  margin-bottom: 0;
}
.boardsSection .title .searchBox button[type=submit].ant-btn-primary {
  padding: 8px;
  background: #e94cf7 url(/src/assets/images/common/search-solid.svg) no-repeat center;
  font-size: 0px;
  min-width: 40px;
  height: 40px;
}
.boardsSection .searchBox form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100% !important;
  gap: 8px;
}
.boardsSection .searchBox form :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item {
  margin: 0;
}
.boardsSection .searchBox form .btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}
.boardsSection .searchBox form .btn .searchCount {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.boardsSection .searchBox form .btn .searchCount span {
  margin-left: 16px;
}
.boardsSection .searchBox form .select {
  width: 120px;
}
.boardsSection .searchBox form .inputText {
  width: 400px;
}
.boardsSection .searchBox form .inputText :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
  flex: 0 0 400px;
}
.boardsSection .searchBox form button[type=submit].ant-btn-primary {
  padding: 8px;
  background: #e94cf7 url(/src/assets/images/common/search-solid.svg) no-repeat center;
  font-size: 0px;
  min-width: 40px;
  height: 40px;
}
.boardsSection .boardWrapper .ant-layout .searchBox {
  padding: 16px 0;
  position: relative;
}
.boardsSection .boardWrapper .ant-layout .searchBox .result {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 40px;
}
.boardsSection .boardWrapper .ant-layout .searchBox .result .searchCount {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  gap: 20px;
}
.boardsSection .boardWrapper .ant-layout .searchBox .result .searchCount span {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.boardsSection .boardWrapper .ant-layout .searchBox .btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.boardsSection .boardWrapper .ant-layout .searchBox .btn .search {
  display: none;
}

.ant-layout {
  width: 100%;
  padding: 0;
  margin: auto;
  margin-bottom: 64px;
}
.ant-layout .aui-grid {
  width: 100%;
}

.rsWrapper :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
  width: 120px;
}

img,
:where(.css-dev-only-do-not-override-1x0dypw).ant-card-bordered {
  border: 0;
}

.rhap_progress-bar-show-download {
  background: rgba(255, 255, 255, 0.3);
}

.rhap_progress-bar {
  height: 2px;
}

#musicBox .rhap_progress-section .rhap_progress-container .rhap_progress-indicator {
  height: 4px;
  top: -1px;
}

#musicBox .rhap_controls-section {
  margin-top: 12px;
  height: 32px;
}
#musicBox .rhap_controls-section div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#musicBox .rhap_controls-section div button {
  height: 100%;
}
#musicBox .top .tavBox {
  background: rgba(255, 255, 255, 0.3);
}
#musicBox .listBox {
  background: rgba(255, 255, 255, 0.3);
  gap: 8px;
}

#musicBox .rhap_controls-section .rhap_main-controls .rhap_button-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#musicBox .rhap_controls-section .rhap_shuffle-controls .rhap_button-shuffle {
  padding: 8px 12px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-rate {
  color: #ffa514;
  font-size: 24px;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-rate .ant-rate-star-second {
  color: rgba(0, 0, 0, 0.2);
}

.loginSection:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined {
  background: #eee;
  font-weight: 500;
}
.loginSection #login :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
  flex: 0 0 1;
}

#login input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
#login input:-webkit-autofill:focus, #login input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
#login input:autofill {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}
#login input:autofill:hover, #login input:autofill:focus, #login input:autofill:active {
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}

#sign-in {
  font-size: 14px;
}
#sign-in:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox-wrapper::hover:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
#sign-in:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox-wrapper::hover :where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border: #ec15ff;
  background-color: inherit;
}

/* radio */
:where(.css-dev-only-do-not-override-1x0dypw).ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
:where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  border-color: transparent;
  width: 20px;
  height: 20px;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #ec15ff;
}

/* RATE */
:where(.css-dev-only-do-not-override-1x0dypw).ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
}

:where(.css-dev-only-do-not-override-apn68).ant-rate {
  color: #ffa514;
}
:where(.css-dev-only-do-not-override-apn68).ant-rate .ant-rate-star:not(:last-child) {
  margin: 0 2px;
}
:where(.css-dev-only-do-not-override-apn68).ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

/* checkbox */
:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #c718e2;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-checkbox .ant-checkbox-inner:after {
  top: 43%;
  inset-inline-start: 25%;
  display: table;
  width: 6.714286px;
  height: 12.142857px;
  border: 3px solid #fff;
  border-top: 0;
  border-inline-start: 0;
}

.profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-form {
  width: 1600px;
  gap: 10px;
  padding: 20px 40px;
}
.profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-row:has(.thumbUpdate) {
  padding: 0;
}
.profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item {
  margin: 0;
}
.profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  padding: 0;
  gap: 24px;
}
.profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-row .title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: start;
  color: #3e3d53;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  gap: 12px;
  margin: 0;
}
.profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-row .title::before {
  background: url(/src/assets/images/profile/profile_01.svg) no-repeat left center;
  width: 20px;
  height: 20px;
  display: inline-block;
}

#board-detail {
  min-height: 800px;
  height: auto;
  width: 960px;
  margin: auto;
}

.fillter + div .titleBox :where(.css-dev-only-do-not-override-1x0dypw) .ant-layout-header {
  display: flex;
  height: 71px;
  padding-bottom: 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.fillter + div .titleBox :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus,
.fillter + div .titleBox :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within {
  border-color: #c718e2;
  box-shadow: none;
  background-color: inherit;
}
.fillter + div .titleBox :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined {
  background: inherit;
  border-width: 0;
}
.fillter + div .titleBox :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus,
.fillter + div .titleBox :where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within {
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 13px;
  border-radius: 4px;
  border: 1px solid var(--b-input, #e2dfe4);
  background: #f7f7f8;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  display: flex;
  width: 140px;
  height: 40px;
  padding: 4px 8px 4px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--c-selectLine, #dfdce4);
  background: var(--bg-input, #f7f7f8);
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-select .ant-select-arrow {
  margin-top: -12px;
  width: 24px;
  height: 24px;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-select .ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
  background: url(/src/assets/images/common/arr_down.svg) no-repeat center;
  width: 24px;
  height: 24px;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-select .ant-select-arrow .anticon svg {
  display: none;
}

.ant-select-selector:hover {
  background: #fff !important;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
  background-color: #fdf2ff;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-select-dropdown .ant-select-item {
  min-height: 40px;
  padding-top: 10px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-select-dropdown {
  padding: 0;
  border-radius: 4px;
  border: 1px solid #c718e2;
  background: #fff;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #c718e2;
  box-shadow: none;
  background: #fff;
  outline: 0;
}

#builder div .content .layout .ant-layout div div .input:focus + button {
  background-image: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}

#builder div .content .layout .selectWrapper .ant-select,
:where(.css-dev-only-do-not-override-1x0dypw).ant-select-single {
  height: 40px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item:has(.ant-upload-list-item-thumbnail) {
  padding: 0;
  border: 0;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-image-preview-root .ant-image-preview-img-wrapper {
  border-radius: 16px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item::before,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item::before {
  width: 100%;
  height: 100%;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-eye,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-eye,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-download,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-download,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-delete,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions .anticon-delete {
  color: #0e0e1f;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions,
:where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ffffff;
  background-color: #c718e2;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
:where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
  color: #000;
  border-color: #e2dfe4;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-switch .ant-switch-handle::before {
  border-radius: 100%;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-switch:hover:not(.ant-switch-disabled),
:where(.css-dev-only-do-not-override-1x0dypw).ant-switch {
  background: #0d88d4;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  color: #eee;
  background: linear-gradient(90deg, #b041f3 0%, #ec15ff 100%);
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-switch .ant-switch-inner .ant-switch-inner-checked {
  margin-inline-start: calc(-100% + 22px - 56px);
  margin-inline-end: calc(100% - 22px + 56px);
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-switcher,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-checkbox {
  margin-inline-end: 0px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-indent-unit,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-switcher:before,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-switcher {
  width: 16px;
}

.ant-tree-treenode-selected svg {
  fill: #fff;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-treenode {
  line-height: 36px;
  color: #0e0e1f;
  font-weight: 400;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-switcher:not(.ant-tree-switcher-noop):hover:before {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 14px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: none;
}

.ant-tree-treenode-selected,
.ant-tree-treenode-selected:hover,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:before,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover:before {
  background: linear-gradient(90deg, #ca79fc 0%, #ff5eb6 100%);
  color: #fff;
  font-weight: 500;
}
.ant-tree-treenode-selected span,
.ant-tree-treenode-selected:hover span,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:before span,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover:before span {
  color: #fff;
}
.ant-tree-treenode-selected span svg,
.ant-tree-treenode-selected:hover span svg,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:before span svg,
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover:before span svg {
  fill: #fff;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-treenode:before {
  display: none;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-treenode {
  margin-bottom: 0;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper:hover {
  color: #fff;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-node-content-wrapper {
  height: 34px;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-treenode:not(.ant-tree-treenode-disabled) .ant-tree-node-content-wrapper:hover {
  color: #0e0e1f;
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent !important;
  color: #fff !important;
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected.ant-tree-treenode svg {
  fill: #fff;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-node-content-wrapper {
  padding-inline: 4px;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:focus-within,
:where(.css-dev-only-do-not-override-1x0dypw).ant-input-outlined:hover {
  border-color: #c718e2;
  background-color: #ffffff;
  background: none;
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-tree .ant-tree-treenode {
  align-items: center;
}

.aui-grid {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  border: 0;
  /* 편집 모드 기본 텍스트 인풋  */
}
.aui-grid .aui-textinputer {
  border: 2px solid linear-gradient(90deg, #e94cf7 0%, #b041f3 100%);
}
.aui-grid .aui-grid-cell-hover-style {
  background: #f5f5f5 !important;
}
.aui-grid .aui-grid-left-main-panel {
  border-radius: 8px 0 0 0;
}
.aui-grid .aui-grid-left-main-panel .aui-grid-header-panel {
  border-radius: 8px 0 0 8px;
}
.aui-grid .aui-grid-left-main-panel .aui-grid-header-panel {
  min-height: 48px;
  border-radius: 8px;
}
.aui-grid .aui-grid-left-main-panel .aui-grid-default-header:hover {
  background: #f3f2fa !important;
}
.aui-grid .aui-grid-body-panel .aui-grid-table tr td {
  border-bottom: 1px solid var(--b-input, #e2dfe4);
  background: #fff;
  cursor: pointer;
}
.aui-grid .aui-grid-body-panel .aui-grid-table tr td .aui-grid-renderer-base {
  line-height: 1.2;
}
.aui-grid .aui-grid-body-panel .aui-grid-table tr td .aui-grid-renderer-base .group {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  line-height: 1;
  height: auto;
  gap: 8px;
}
.aui-grid .aui-grid-body-panel .aui-grid-table tr td .comment {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #525162;
  height: 34px;
  line-height: 1;
}
.aui-grid .aui-grid-body-panel .aui-grid-table tr td .comment span,
.aui-grid .aui-grid-body-panel .aui-grid-table tr td .comment svg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: 1;
  font-weight: 400;
}
.aui-grid main {
  margin-bottom: 24px;
}
.aui-grid .aui-grid-default-header:first-child {
  border-radius: 8px 0 0 8px;
}
.aui-grid .aui-grid-default-header:hover {
  background: #f3f2fa;
}
.aui-grid .aui-grid-row-background {
  background: rgb(250, 250, 250);
}
.aui-grid .aui-grid-nodata-msg-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #a3a1a7;
  text-align: center;
  margin-top: 0px;
  background: transparent url(/src/assets/images/common/help_icon.svg) no-repeat left center/30px;
  padding: 10px 0 10px 52px;
  min-height: 120px;
  background-image: url(/src/assets/images/common/help_icon.svg) center center no-repeat;
}
.aui-grid .aui-grid-info-layer:has(.aui-grid-nodata-msg-layer) {
  background-color: #f5f5f5;
  box-shadow: inset 0px 2px 0px #fff;
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):active,
:where(.css-dev-only-do-not-override-1x0dypw).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):active {
  color: #fff;
  border-color: #e94cf7;
  background: #ffffff;
}

.boardsSection .title {
  margin: 0 16px;
  padding: 20px 0;
  width: calc(100% - 32px);
}
.boardsSection .title .titleBox::before {
  width: 20px;
  height: 20px;
  background-size: 20px;
  content: "";
}
.boardsSection .title .searchBox.none::before {
  width: 24px;
  height: 24px;
  background-size: 18px;
  content: "";
  background: url(/src/assets/images/common/search-solid.svg) no-repeat center;
  fill: #525162;
  display: none;
}
.boardsSection .boardWrapper .searchBox .result .searchCount {
  gap: 12px !important;
  font-size: 14px !important;
}
.boardsSection .boardWrapper .searchBox .btn {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 12px;
}
.boardsSection .boardWrapper .searchBox .btn-search {
  width: 32px;
  height: 32px;
  content: "";
  background: url(/src/assets/images/common/i-search.svg) no-repeat center/18px;
  fill: #525162;
  border-radius: 4px;
  display: none;
}
.boardsSection .boardWrapper .searchBox .btn-search::hover {
  box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
  border-color: transparent;
  outline: rgba(255, 94, 182, 0.7);
}
.boardsSection .boardWrapper .searchBox .btn .search {
  display: flex;
  position: absolute;
  left: -14px;
  right: -14px;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  padding: 8px;
  top: 8px;
  z-index: 2;
}
.boardsSection .boardWrapper .searchBox form .inputText {
  width: calc(100% - 120px - 40px - 24px);
}
.boardsSection .boardWrapper .searchBox form .inputText :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
  width: 100%;
}

@media (max-width: 700px) {
  #root:has(section.userPage) + div .ant-btn-default.btn-dark,
  .ant-modal-root .ant-btn-default.btn-dark,
  .userPage .ant-btn-default.btn-dark {
    font-size: 14px;
    height: 32px;
    min-width: 72px;
    font-weight: 500;
  }
  .profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-form {
    width: 100%;
    padding: 0;
  }
  #myHome .filter {
    border: none;
  }
  #myHome .filter .rsWrapper .listBox .title img {
    margin: 0;
  }
  .boardsSection .title {
    margin: 0 16px;
    padding: 20px 0;
    width: calc(100% - 32px);
  }
  .boardsSection .title .titleBox {
    font-size: 18px;
  }
  .boardsSection .title .titleBox::before {
    width: 20px;
    height: 20px;
    background-size: 20px;
    content: "";
  }
  .boardsSection .title .searchBox.none::before {
    width: 24px;
    height: 24px;
    background-size: 18px;
    content: "";
    background: url(/src/assets/images/common/search-solid.svg) no-repeat center;
    fill: #525162;
  }
  .boardsSection .boardWrapper .searchBox .result .searchCount {
    gap: 12px !important;
    font-size: 14px !important;
  }
  .boardsSection .boardWrapper .searchBox .btn {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 12px;
  }
  .boardsSection .boardWrapper .searchBox .btn-search {
    width: 32px;
    height: 32px;
    content: "";
    background: url(/src/assets/images/common/i-search.svg) no-repeat center/18px;
    fill: #525162;
    border-radius: 4px;
    display: flex !important;
  }
  .boardsSection .boardWrapper .searchBox .btn-search::hover {
    box-shadow: inset 0 0 0 200px rgba(0, 0, 0, 0.2);
    border-color: transparent;
    outline: rgba(255, 94, 182, 0.7);
  }
  .boardsSection .boardWrapper .searchBox .btn .search {
    display: flex;
    position: absolute;
    left: -14px;
    right: -14px;
    background: #fff;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    padding: 8px;
    top: 8px;
    z-index: 2;
  }
  .boardsSection .boardWrapper .searchBox form .inputText {
    width: calc(100% - 120px - 80px - 40px);
  }
  .boardsSection .boardWrapper .searchBox form .inputText :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
    width: 100%;
  }
  .boardsSection .boardWrapper .aui-grid-header-renderer {
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
  }
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .aui-grid-renderer-base .group {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 2px;
    font-weight: 500;
    font-size: 14px;
  }
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .comment {
    padding: 0;
    background: transparent;
    height: auto;
    gap: 4px;
  }
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .comment span,
  .boardsSection .boardWrapper .aui-grid-body-panel .aui-grid-table tr td .comment svg {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #525162;
    line-height: 1;
    font-weight: 500;
    font-size: 11px;
    fill: #525162;
    height: 16px;
  }
  .boardsSection #board-detail {
    min-height: auto;
    width: 100%;
    padding: 0 16px;
  }
  .boardsSection #board-detail .board-detail-wrapper {
    width: 100%;
    padding: 0;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container {
    gap: 5px;
    padding-top: 30px;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container {
    gap: 12px;
    font-size: 12px;
    line-height: 1;
    height: auto;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container .date-info,
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container svg {
    font-size: 12px;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-container .info-container .ant-btn {
    height: auto;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 23px;
    margin-top: 5px;
  }
  .boardsSection #board-detail .board-detail-wrapper .title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 23px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container {
    padding: 4px 0px;
    gap: 10px;
    margin-block-end: 20px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-icon .ant-avatar {
    width: 38px;
    height: 38px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info {
    gap: 4px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container .dropdown-item {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    padding: 0;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container .star-rating {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: auto;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .info-container .star-rating .anticon-star {
    font-size: 13px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .userArea {
    gap: 4px;
  }
  .boardsSection #board-detail .board-detail-wrapper .profile-container .user-info .userArea p {
    font-size: 14px;
  }
  .boardsSection #board-detail .board-detail-wrapper .file-attachment {
    padding: 16px 0;
  }
  .boardsSection #board-detail .board-detail-wrapper .file-list {
    gap: 8px;
    font-size: 12px;
  }
  .boardsSection #board-detail .board-detail-wrapper .back-button-container {
    padding: 30px 0;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item .ant-form-item-control-input {
    min-height: auto;
  }
  .comment-system {
    gap: 0px !important;
  }
  .comment-system h2 {
    display: none !important;
  }
  .comment-system .repleWriteArea * {
    min-height: 72px;
  }
  .comment-system .repleBox .ant-comment {
    border-radius: 20px 20px 16px 20px !important;
    gap: 10px !important;
    padding: 10px !important;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-comment .ant-comment-inner {
    padding-block: 0;
  }
  #board-detail .board-detail-wrapper .comment-system .repleBox .ant-comment .ant-comment-inner {
    gap: 8px;
  }
  #board-detail .repleBox .ant-comment-avatar .ant-avatar,
  #board-detail .repleBox .ant-comment-avatar .ant-avatar img {
    width: 32px;
    height: 32px;
  }
  #builder div .content .builderGuide h1 {
    font-size: 24px;
  }
  #builder div .content .builderGuide h2 {
    font-size: 16px;
  }
  #builder div .content .builderGuide p img {
    width: 100%;
  }
  #builder div .content:has(.builderGuide) .mobileTab {
    display: none;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item.ant-form-item-has-success .ant-form-item-control-input-content {
    padding-top: 30px !important;
  }
  .ranking_s {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    gap: 12px;
    cursor: pointer;
    font-size: 14px;
    min-width: 100px;
    gap: 4px;
    min-width: 34px;
    font-size: 10px;
    height: 18px;
  }
  .ranking_s::after {
    background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
    width: 20px;
    height: 20px;
    content: "";
  }
  .ranking_s::after {
    background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
    width: 16px;
    height: 16px;
    content: "";
  }
  .ranking_s.music::before {
    background: url(/src/assets/images/common/i-rankMusic.svg) no-repeat center/12px;
    width: 12px;
    height: 12px;
    content: "";
  }
  .ranking_s.image::before {
    background: url(/src/assets/images/common/i-rankImage.svg) no-repeat center/12px;
    width: 12px;
    height: 12px;
    content: "";
  }
  .ranking_s.video::before {
    background: url(/src/assets/images/common/i-rankVideo.svg) no-repeat center/12px;
    width: 12px;
    height: 12px;
    content: "";
  }
  .ranking_s.text::before {
    background: url(/src/assets/images/common/i-rankText.svg) no-repeat center/12px;
    width: 12px;
    height: 12px;
    content: "";
  }
  .ranking_s::after {
    background: url(/src/assets/images/common/btn-downB.svg) no-repeat center;
    width: 12px;
    height: 12px;
    content: "";
    background-size: 12px;
  }
  #builder > div .tab #pinkBtn2 {
    height: 32px;
  }
}
@media (max-width: 800px) {
  #myProfile .userInfo .content .box .info:first-child > .rowBox > .mobileInput {
    width: 100%;
  }
  #myProfile .userInfo .content .box .info > .rowBox #darkGrey {
    width: 100%;
  }
  #myProfile .myInfo .content .box .thumbUpdate {
    width: 100%;
  }
  #myProfile .myInfo .content .box .thumbUpdate .plusBox {
    width: 100%;
    height: 140px;
  }
  #myProfile .myInfo .content .box .infoBox .box2:last-child,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2:last-child {
    flex-direction: column;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 0;
  }
  #myProfile .myInfo {
    padding-bottom: 20px;
  }
  .profileSection :where(.css-dev-only-do-not-override-1x0dypw).ant-form-item {
    width: 100%;
  }
  .profileSection .rsWrapper :where(.css-dev-only-do-not-override-1x0dypw).ant-row div:first-child {
    margin: 0 !important;
    width: 100% !important;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList {
    width: 100%;
    padding: 0;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList > div {
    width: 100%;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList #myProfile .myInfo .content .box .thumb .flexRowBetween {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList #myProfile .myInfo .content .box .thumb .flexRowBetween .flexRowBetween {
    width: auto;
    justify-content: center;
  }
  .profileSection .rsWrapper.flexColCenter #myHome .promptThumList #myProfile .myInfo .content .box .thumb {
    margin: 0;
  }
  #myProfile .myInfo .content .box .thumb + .infoBox .box2 .info:first-child,
  #myProfile .myInfo .content .box .thumb + .infoBox .box2 .info:last-child {
    width: 100%;
  }
  #myProfile .myInfo .content .box .infoBox .box2:last-child {
    flex-direction: column;
  }
  #builder div .selectWrapper button,
  #builder div .content .layout .selectWrapper .ant-select,
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-single {
    height: 32px;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    width: 108px;
    height: 32px;
  }
  #builder div .selectWrapper {
    padding: 10px 0;
  }
  #builder > div .tab {
    padding: 0 0 2px;
    border-bottom: none;
  }
  .selectWrapper {
    flex-wrap: wrap;
    margin: 0;
  }
  #builder div .content .layout .ant-layout .ant-layout-has-sider {
    flex-direction: column;
  }
  #builder div .content .layout .ant-layout header {
    overflow: hidden;
  }
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected .ant-menu-title-content,
  #builder div .content .layout .ant-layout .ant-menu.headerMenu .ant-menu-title-content,
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item,
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item {
    height: 40px;
    line-height: 1;
    font-size: 12px;
    font-weight: 400;
  }
  #builder div .content .layout .ant-layout .headerMenu.ant-menu-horizontal > .ant-menu-item {
    min-width: 90px;
  }
  #builder div .content .layout .ant-layout main .builderGuideImg {
    height: 130px;
  }
}
@media (max-width: 900px) {
  #builder div .content .builderGuide h1 {
    font-size: 36px;
  }
  #builder div .content .builderGuide h2 {
    font-size: 20px;
  }
  #builder div .content .builderGuide p img {
    width: 100%;
  }
  #builder div .content:has(.builderGuide) .mobileTab {
    display: none;
  }
  #builder div .content .layout .ant-layout .ant-layout-has-sider {
    border-bottom: 2px solid var(--bg-gray, #f3f3f3);
  }
}
:where(.css-dev-only-do-not-override-1x0dypw).ant-layout-sider,
:where(.css-dev-only-do-not-override-1x0dypw).ant-form,
:where(.css-dev-only-do-not-override-1x0dypw).ant-btn,
:where(.css-dev-only-do-not-override-1x0dypw).ant-dropdown,
:where(.css-dev-only-do-not-override-1x0dypw).ant-dropdown-menu-submenu,
:where(.css-dev-only-do-not-override-1x0dypw)[class^=ant-image],
:where(.css-dev-only-do-not-override-1x0dypw)[class*=" ant-image"],
:where(.css-dev-only-do-not-override-1x0dypw).ant-list,
:where(.css-dev-only-do-not-override-1x0dypw).ant-comment,
:where(.css-dev-only-do-not-override-1x0dypw).ant-input,
:where(.css-dev-only-do-not-override-1x0dypw).ant-col {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.noResult * {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #a3a1a7;
  text-align: center;
  margin-top: 0px;
  background: transparent url(/src/assets/images/common/help_icon.svg) no-repeat left center/30px;
  padding: 10px 0 10px 52px;
  min-height: 120px;
}

.none {
  display: none !important;
}