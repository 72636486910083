#section {
  position: fixed;
  bottom: 8%;
  right: 20px;
  z-index: 100;
  width: auto !important;
}

#section .up {
  width: auto;
  margin: 0 0 -24px;
}

#section .up div {
  width: 72px;
  height: 72px;
  background: #00000007;
  border-radius: 100% 100% 0 0;
  cursor: pointer;
}

#section .up.active {
  margin: 0 0 20px;
}

#section .up.active div {
  border-radius: 100%;
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
}

#section .up > div > img {
  width: 24px;
}

#section .relativeMenu { 
  visibility: hidden;
  height: 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  opacity: 0;
}

#section .relativeMenu#active {
  visibility: visible;
  height: auto;
  opacity: 1;
}

#section .relativeMenu .menu {
  margin: 0 0 16px;
  width: auto;
}

#section .relativeMenu .menu p {
  font-size: 16px;
  color: #0e0e1f;
  margin: 0 10px 0 0;
  text-shadow: 0px 6px 8px rgb(0, 0, 0, 0.25);
}

#section .relativeMenu .menu div {
  width: 64px;
  height: 64px;
  background: #3e3d53;
  border-radius: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

#section .relativeMenu .menu div img {
  width: 32px;
}

#section .closeBox .menuBox {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background: #13d5df;
  cursor: pointer;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
}

#section .closeBox.active .menuBox {
  background: #12b8c2;
}

#section .closeBox .menuBox .activeIcon {
  display: none;
}

#section .closeBox .menuBox .activeIcon#activeIcon {
  display: flex;
}

#section .closeBox .menuBox img#noneVisible {
  display: none;
}

#section .closeBox .menuBox img {
  width: 38px;
}

@media (max-width: 700px) {
  #section .up {
    margin: 0 0 -15px;
  }

  #section .up div {
    width: 48px;
    height: 48px;
  }

  #section .closeBox .menuBox {
    width: 48px;
    height: 48px;
  }

  #section .closeBox .menuBox img {
    width: 26px;
  }

  #section .up div img,
  #section .relativeMenu .menu div img {
    width: 20px;
  }

  #section .relativeMenu .menu div {
    width: 48px;
    height: 48px;
  }
}
