article>.top {
    padding: 50px 0;
    background: linear-gradient(to bottom, rgba(131, 223, 227, 0.2), rgba(255, 182, 228, 0.2));
}

article .top .rsWrapper {
    align-items: flex-start;
}

article .top .rsWrapper .left,
article .top .rsWrapper .right {
    width: calc(50% - 32px);
}

article .top .rsWrapper .left .user {
    padding: 0 8px 10px;
}

article .top .rsWrapper .left .user div {
    width: auto;
}

article .top .rsWrapper .left .user div img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin: 0 12px 0 0;
}

article .top .rsWrapper .left .user div p {
    font-size: 16px;
    font-weight: 600;
    color: #0e0e1f;
}



article .top .rsWrapper .left .imgScroll {
    height: 729px;
    overflow: auto;
    justify-content: flex-start;
}

article .top .rsWrapper .left .imgScroll div .activeSiren,
article .top .rsWrapper .left .imgBox div .activeSiren {
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    margin: 0 0 12px;
}

article .top .rsWrapper .left .imgScroll div .activeSiren div,
article .top .rsWrapper .left .imgBox div .activeSiren div {
    width: calc(100% / 5);
    position: relative;
}

article .top .rsWrapper .left .imgScroll div .activeSiren div:before,
article .top .rsWrapper .left .imgBox div .activeSiren div:before {
    content: "";
    padding: 0 0 100%;
}

article .top .rsWrapper .left .imgScroll div .activeSiren div img,
article .top .rsWrapper .left .imgBox div .activeSiren div img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

article .top .rsWrapper .left .imgScroll div .thumbnail,
article .top .rsWrapper .left .imgBox div .thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    margin: 0 0 13px;

}

article .top .rsWrapper .left .imgScroll div .thumbnail::after,
article .top .rsWrapper .left .imgBox div .thumbnail::after {
    content: "";
    padding: 0 0 100%;
}

article .top .rsWrapper .left .imgScroll div .thumbnail>img,
article .top .rsWrapper .left .imgBox div .thumbnail>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    z-index: 10;
    align-items: flex-end;
}



article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div {
    width: auto;
    cursor: pointer;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div>img {
    width: 26px;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon {
    position: relative;
    width: 26px;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon:hover img.activeIcon,
article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon#active img.activeIcon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon:hover img.activeIcon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon#active img.activeIcon {
    opacity: 1;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon img,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon img {
    width: 100%;
    height: auto;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon img.activeIcon,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon img.activeIcon {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .2s ease-in-out;
    z-index: 2;
}

article .top .rsWrapper .left .imgScroll div .thumbnail .absoluteBox div .icon:first-child,
article .top .rsWrapper .left .imgBox div .thumbnail .absoluteBox div .icon:first-child {
    margin: 0 0 20px;
}

article .top .rsWrapper .left .barBox {
    margin: 0 0 16px;
}

article .top .rsWrapper .left .barBox .bar {
    position: relative;
    margin: 0 0 5px;

}

article .top .rsWrapper .left .barBox .bar .activeBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
}

article .top .rsWrapper .left .barBox p {
    font-size: 11px;
    color: #656262;
}

article .top .rsWrapper .left .barBox span {
    color: #0e0e1f;
}

article .top .rsWrapper .left .play {
    margin: 0 0 35px;
}

article .top .rsWrapper .left .play img {
    width: 30px;
    margin: 0 50px 0 0;
}

article .top .rsWrapper .left .play img:nth-child(5) {
    margin: 0;
}

article .top .rsWrapper .left #purpleBtn {
    width: 200px;
    height: 34px;
    border-radius: 4px;
}

article .top .rsWrapper .left #purpleBtn img {
    width: 20px;
    margin: 0 10px 0 0;
}

article .top .rsWrapper .left #purpleBtn {
    font-size: 14px;
}

article .top .rsWrapper .right .icon {
    padding: 0 8px 10px;
}

article .top .rsWrapper .right .icon img {
    width: 24px;
    cursor: pointer;
}

article .top .rsWrapper .right>h1 {
    font-size: 36px;
    font-weight: 600;
    color: #0e0e1f;
    margin: 0 0 32px;
    width: 100%;
}

article .top .rsWrapper .right .user .userIcon {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    padding: 0;
    background: linear-gradient(to bottom, #13d5df 0%, #f138fc 100%);
}

article .top .rsWrapper .right .user .userIcon>img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

article .top .rsWrapper .right .user .userIcon p {
    font-size: 40px;
    color: #ffffff;
}

article .top .rsWrapper .right .user>div {
    width: calc(100% - 80px - 15px);
    padding: 0 0 0 15px;
}



article .top .rsWrapper .right .user div .grade div {
    width: auto;
    padding: 8px 4px;
    border-radius: 20px;
    background: #f8ab30;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 8px 0 0;
}

article .top .rsWrapper .right .user div h2 {
    font-size: 24px;
    font-weight: 500;
    color: #0e0e1f;
}

article .top .rsWrapper .right .user div .grade {
    margin: 0 0 7px;
}

article .top .rsWrapper .right .user div p {
    font-size: 16px;
    font-weight: 500;
    color: #0e0e1f;
}

article .top .rsWrapper .right .user {
    margin: 0 0 15px;
}

article .top .rsWrapper .right .userInfo {
    margin: 0 0 12px;
}


article .top .rsWrapper .right .userInfo .playNum {
    padding: 8px 12px;
    border-radius: 24px;
    border: solid 1px #3e3d53;
    margin: 0 24px 0 0;
    width: auto;
}

article .top .rsWrapper .right .userInfo .playNum img {
    width: 18px;
    margin: 0 10px 0 0;
}


article .top .rsWrapper .right .userInfo .playNum p {
    color: #3e3d53;
    font-size: 12px;
}

article .top .rsWrapper .right .userInfo>div {
    width: auto;

}

article .top .rsWrapper .right .userInfo>div p {
    font-size: 16px;
    color: #0e0e1f;
    margin: 0 0 0 4px;
}

article .top .rsWrapper .right .content {
    padding: 16px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.05);
    justify-content: space-between;
    margin: 0 0 24px;
}

article .top .rsWrapper .right .content .typeBox {
    flex-wrap: wrap;
}

article .top .rsWrapper .right .content .typeBox .type {
    width: auto;
    padding: 8px 12px;
    border-radius: 20px;
    background: #ffffff;
    color: #3e3d53;
    margin: 0 12px 16px 0;
}


article .top .rsWrapper .right .content .text {
    margin: 0 0 16px;
}

article .top .rsWrapper .right .content .stirenText {
    justify-content: flex-start;
    height: 180px;
    overflow: hidden;
}

article .top .rsWrapper .right .content .text {
    justify-content: flex-start;
    height: 395px;
    overflow: hidden;
}


article .top .rsWrapper .right .content .stirenText p,
article .top .rsWrapper .right .content .text p {
    width: 100%;
    font-size: 14px;
    line-height: 1.6;
    color: #323146;
    height: 100%;
    overflow: hidden;
    vertical-align: top;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 16;
    margin: 0 0 20px;
}

article .top .rsWrapper .right .content .stirenText .more p,
article .top .rsWrapper .right .content .text .more p {
    width: auto;
}

article .top .rsWrapper .right .content .tagBox p {
    font-size: 13px;
    font-weight: 600;
    color: #0e0e1f;
    margin: 0 16px 0 0;
}

article .top .rsWrapper .right #greyBtn5 {
    width: 163px;
    height: 34px;
    border-radius: 24px;
    margin: 0 0 24px;
}

article .top .rsWrapper .right #greyBtn5 img {
    width: 18px;
    margin: 0 10px 0 0;
}

article .top .rsWrapper .right #greyBtn5 p {
    font-size: 14px;
}

article .top .rsWrapper .right #logoutBtn {
    width: 163px;
    height: 34px;
    border-radius: 24px;
    margin: 0 0 24px;
}

article .top .rsWrapper .right #logoutBtn img {
    width: 18px;
    margin: 0 10px 0 0;
}

article .top .rsWrapper .right #logoutBtn p {
    font-size: 14px;
}



article .top .rsWrapper .right .priceBox>h1 {
    font-size: 32px;
    font-weight: 600;
    color: #0e0e1f;
}

#detail .top .rsWrapper .right .priceBox>div{
    width: auto;
}

article .top .rsWrapper .right .priceBox #purpleBtn {
    position: relative;
    margin: 0 0 0 16px;
}

article .top .rsWrapper .right .priceBox #purpleBtn img.markIcon {
    position: absolute;
    top: -14px;
    right: -14px;
    width: 28px;

}

article .top .rsWrapper .right .priceBox #darkGrey,
article .top .rsWrapper .right .priceBox #purpleBtn {
    width: 273px;
    height: 51px;
    border-radius: 24px;
    font-size: 16px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
}

article .top .rsWrapper .right .priceBox #darkGrey img,
article .top .rsWrapper .right .priceBox #purpleBtn img {
    width: 20px;
    margin: 0 10px 0 0;
}

article .top .rsWrapper .right .priceBox .downloadBox #purpleBtn {
    width: 200px;
    height: 34px;
    border-radius: 4px;
    font-size: 14px;
}
article .top .rsWrapper .right .priceBox .downloadBox #purpleBtn:hover {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
    color: #fff;
}


article .rsWrapper .tip {
padding: 20px 0 0;
height: 100px;
justify-content: flex-start;
overflow: auto;
}

article .tip div h1 {
font-size: 14px;
font-weight: bold;
color: #323146;
margin: 0 0 20px;
}

article .tip .text p {
font-size: 14px;
color: #323146;
overflow: hidden;
height: 100%;
text-overflow: ellipsis;
word-break: break-all;
-webkit-box-orient: vertical;
-webkit-line-clamp: 16;

}

article .rsWrapper .prompt {
margin: 40px 0 0 0;
}

article .rsWrapper .prompt h1 {
font-size: 32px;
font-weight: bold;
color: #0e0e1f;
margin: 0 0 30px;
}

article .rsWrapper .prompt>div {
padding: 8px;
border-radius: 4px;
border: 1px solid #ec15ff;
background: rgba(255, 182, 228, 0.2);
}

article .rsWrapper .prompt>div textarea.ant-input-outlined {
background: transparent;
border: none;
box-shadow: none;
}

article .rsWrapper .prompt>div .btnBox {
height: 40px;
}

article .rsWrapper .prompt>div .btnBox>p {
font-size: 14px;
padding: 0 0 0 12px;
}

article .rsWrapper .prompt>div .btnBox #pinkBtn {
width: 77px;
height: 40px;
border-radius: 4px;
}

article .rsWrapper .reviewBox {
margin: 40px 0 44px 0;
}

article .rsWrapper .reviewBox div {
width: auto;
}

article .rsWrapper .reviewBox h1 {
font-size: 32px;
font-weight: bold;
color: #0e0e1f;
margin: 0 40px 0 0;
}

article .rsWrapper .reviewBox div h2 {
color: #0e0e1f;
font-size: 16px;
font-weight: 600;
margin: 0 8px 0 0;
}

article .rsWrapper .reviewBox div p {
font-size: 14px;
color: #87858b;
margin: 0 0 0 5px;
}

article .rsWrapper .reviewCreate {
margin: 0 0 40px;
}

article .rsWrapper .reviewCreate .review {
width: auto;
}

article .rsWrapper .reviewCreate .review p {
font-size: 16px;
color: #0e0e1f;
margin: 0 0 24px;
}

article .rsWrapper .reviewCreate .create {
width: calc(100% - 224px - 180px);
padding: 0 40px;
}

article .rsWrapper .reviewCreate .create p {
font-size: 16px;
font-weight: 600;
color: #0e0e1f;
margin: 0 0 12px;
}

article .rsWrapper .reviewCreate .create div .input:focus {
border: 1px solid #b041f3;
background: #ffffff;
}

article .rsWrapper .reviewCreate .create div .input {
width: calc(100% - 73px - 12px);
height: 48px;
border-radius: 4px;
background: #f4f5f7;
padding: 16px 12px;
background: #f4f5f7;

border: none;
box-shadow: none;

}


article .rsWrapper .reviewCreate .create div .input::placeholder {
color: #a3a1a7;
}

article .rsWrapper .reviewCreate .create div #greyBtn {
width: 73px;
height: 48px;
font-size: 14px;
border-radius: 4px;
}

article .rsWrapper .reviewCreate .question {
width: 224px;
}

article .rsWrapper .reviewCreate .question>p {
font-size: 16px;
font-weight: 600;
color: #0e0e1f;
margin: 0 0 20px;
}

article .rsWrapper .reviewCreate .question #darkGrey2:hover .hoverIcon {
opacity: 1;
}

article .rsWrapper .reviewCreate .question #darkGrey2 .hoverIcon {
opacity: 0;
position: absolute;
left: 41px;

}

article .rsWrapper .reviewCreate .question #darkGrey2 {
width: 100%;
height: 48px;
border-radius: 50px;
position: relative;
}

article .rsWrapper .reviewCreate .question #darkGrey2 img {
width: 25px;
margin: 0 10px 0 0;
}

article .rsWrapper .reviewCreate .question #darkGrey2 p {
font-size: 13px;
}


#detail .rsWrapper .reviewList .list {
width: 50%;
padding: 16px;
border-radius: 70px 70px 16px 70px;
background: rgba(62, 61, 93, 0.1);
align-items: flex-end;
margin: 0 0 24px;
}

#detail .rsWrapper .reviewList .list.answer {
width: 100%;
padding: 16px;
border-radius: 70px 70px 16px 70px;
background: rgba(62, 61, 93, 0.1);
align-items: flex-end;
margin: 0 0 24px;
}


#detail .rsWrapper .reviewList .list .userReview {
align-items: flex-start;

}

#detail .rsWrapper .reviewList .list.answer .userReview {
width: 50%;
align-items: flex-start;
}

#detail .rsWrapper .reviewList .list .userReview>img {
width: 140px;
height: 140px;
border-radius: 100%;
}

#detail .rsWrapper .reviewList .list .userReview>div {
width: calc(100% - 140px);
padding: 0 32px;
}

#detail .rsWrapper .reviewList .list .userReview>div .top {
margin: 0 0 13px;
}

#detail .rsWrapper .reviewList .list .userReview>div .top div {
width: auto;
}

#detail .rsWrapper .reviewList .list .userReview>div .top div p {
font-size: 16px;
color: #0e0e1f;
border-bottom: 1px solid #0e0e1f;
}

#detail .rsWrapper .reviewList .list .userReview>div .top #darkGreyBorder {
width: 45px;
height: 30px;
font-size: 12px;
border-radius: 24px;
}

#detail .rsWrapper .reviewList .list .userReview>div .rateBox {
margin: 0 0 12px;
}

#detail .rsWrapper .reviewList .list .userReview>div .rateBox .line {
height: 18px;
width: 1px;
background: #d7d4db;
margin: 0 12px;
}

#detail .rsWrapper .reviewList .list .userReview>div .rateBox p {
font-size: 12px;
color: #6d6c75;
}

#detail .rsWrapper .reviewList .list .userReview>div .content {
font-size: 14px;
color: #0e0e1f;
line-height: 1.4;
}

#detail .rsWrapper .reviewList .list .answer {
width: 50%;
padding: 16px 16px 16px 72px;
background: rgba(255, 255, 255, 0.7);
border-radius: 70px 0 0 0;
flex-wrap: wrap;
}

#detail .rsWrapper .reviewList .list .answer>div {
margin: 0 0 20px;
}

#detail .rsWrapper .reviewList .list .answer>div>div {
width: auto;
}

#detail .rsWrapper .reviewList .list .answer>div>div h1 {
font-size: 16px;
color: #0e0e1f
}

#detail .rsWrapper .reviewList .list .answer>div>div p {
font-size: 12px;
color: #6d6c75;
margin: 0 0 0 20px;
}

#detail .rsWrapper .reviewList .list .answer>div>p {
font-size: 18px;
color: #6d6c75;
}

#detail .rsWrapper .reviewList .list .answer .content {
font-size: 14px;
color: #0e0e1f;
margin: 0;

}


#detail .rsWrapper .reviewList .list .answer .input {
width: calc(100% - 73px - 12px);
height: 48px;
background: #f4f5f7;
padding: 16px 12px;
font-size: 13px;
border: none;
box-shadow: none;
}

#detail .rsWrapper .reviewList .list .answer #greyBtn {
width: 73px;
height: 48px;
border-radius: 4px;
}

#detail .rsWrapper .allReview {
margin: 0 0 80px;
}

#detail .rsWrapper .allReview p {
font-size: 14px;
color: #0e0e1f;
font-weight: bold;
border-bottom: 2px solid #0e0e1f;
cursor: pointer;
transition: all .2s ease-in-out;
}

#detail .rsWrapper .allReview p:hover {
color: #ec15ff;
border-bottom: 2px solid #ec15ff;
}

article .rsWrapper .allReview {
margin: 0 0 80px;
}

article .rsWrapper .allReview p {
font-size: 14px;
color: #0e0e1f;
font-weight: bold;
border-bottom: 2px solid #0e0e1f;
cursor: pointer;
transition: all .2s ease-in-out;
}

article .rsWrapper .allReview p:hover {
color: #ec15ff;
border-bottom: 2px solid #ec15ff;
}

article .rsWrapper .promptBox {
position: relative;
margin: 0 0 30px;
}

article .rsWrapper .promptBox .line {
position: absolute;
top: 50%;
left: 0;
border: 1px dashed #c2b8cb;
margin: -0.5px 0 0;
width: 100%;

}

article .rsWrapper .promptBox>div {
padding: 0 24px;
background: #ffffff;
z-index: 2;
width: auto;
}

article .rsWrapper .promptBox>div img {
width: 20px;
}

article .rsWrapper .promptBox>div p {
font-size: 20px;
margin: 0 12px;
color: #3e3d53;
font-weight: 700;
}

article .rsWrapper .promptBox>div #greyBtn {
width: 22px;
height: 22px;
border-radius: 100%;
font-size: 14px;
}

article .rsWrapper .slide {
margin: 0 0 52px;
}

#reviewModal .reviewTitle {
margin: 0 0 20px;
padding: 8px 0;
border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

#reviewModal .reviewTitle h1,
#reviewModal .reviewTitle p {
font-size: 24px;
color: #0e0e1f;
}

#reviewModal .reviewTitle p {
cursor: pointer;
transition: all .2s ease-in-out;
}

#reviewModal .reviewTitle p:hover {
color: #ec15ff;
}

#reviewModal .reviewBox {
margin: 0 0 25px;
}

#reviewModal .reviewBox h2 {
font-size: 24px;
font-weight: bold;
color: #0e0e1f;
margin: 0 40px 0 0;
}

#reviewModal .reviewBox h3 {
color: #0e0e1f;
font-size: 16px;
margin: 0 5px 0 0;
}

#reviewModal .reviewBox p {
font-size: 14px;
color: #87858b;
margin: 0 0 0 5px;
border-bottom: 1px solid #87858b;
line-height: 1;
}

#reviewModal .listBox {
height: 500px;
justify-content: flex-start;
overflow: auto;
}



#reviewModal .listBox .listScroll .list {
padding: 16px;
border-radius: 70px 70px 16px 70px;
background: rgba(62, 61, 93, 0.1);
align-items: flex-end;
margin: 0 0 24px;
flex-wrap: wrap;
}

#reviewModal .listBox .listScroll .list .userReview {
align-items: flex-start;
}

#reviewModal .listBox .listScroll .list .userReview>img {
width: 140px;
height: 140px;
border-radius: 100%;
}

#reviewModal .listBox .listScroll .list .userReview>div {
width: calc(100% - 140px);
padding: 0 32px;
}

#reviewModal .listBox .listScroll .list .userReview>div .top {
margin: 0 0 13px;
}

#reviewModal .listBox .listScroll .list .userReview>div .top p {
font-size: 16px;
color: #0e0e1f;
}

#reviewModal .listBox .listScroll .list .userReview>div .top #greyBtn {
width: 45px;
height: 30px;
font-size: 12px;
border-radius: 24px;
}

#reviewModal .listBox .listScroll .list .userReview>div .rateBox {
margin: 0 0 12px;
}

#reviewModal .listBox .listScroll .list .userReview>div .rateBox .line {
height: 18px;
width: 1px;
background: #d7d4db;
margin: 0 12px;
}

#reviewModal .listBox .listScroll .list .userReview>div .rateBox p {
font-size: 12px;
color: #6d6c75;
}

#reviewModal .listBox .listScroll .list .userReview>div .content {
font-size: 14px;
color: #0e0e1f;
line-height: 1.4;
}

#reviewModal .listBox .listScroll .list .answer {
padding: 16px 16px 16px 72px;
background: rgba(255, 255, 255, 0.7);
border-radius: 0 0 0 70px;
margin: 24px 0 0;
}

#reviewModal .listBox .listScroll .list .answer div {
align-items: flex-end;
}

#reviewModal .listBox .listScroll .list .answer div h1 {
font-size: 16px;
color: #0e0e1f;
margin: 0 8px 0 0;
}

#reviewModal .listBox .listScroll .list .answer div p {
font-size: 12px;
color: #6d6c75;
}

#reviewModal .listBox .listScroll .list .answer>p {
font-size: 14px;
color: #0e0e1f;
margin: 16px 0 0;
}



.imageModal {
height: 100vh;
width: 100vw;
background: rgba(0, 0, 0, 0.8);
z-index: 1000;
position: fixed;
top: 0;
left: 0;
padding: 30px 8px;
}

.imageModal>div {
position: relative;
height: 100%;
}

.imageModal .pagination {
position: absolute;
top: 0;
left: 0;
z-index: 10;
}

.imageModal .pagination h1 {
font-size: 14px;
color: #ffffff;
margin: 0 42px 0 0;
}

.imageModal .pagination p {
font-size: 24px;
color: #ffffff;
cursor: pointer;
transition: all .2s ease-in-out;
}

.imageModal .pagination p:hover {
color: #ec15ff;
}


article .top .rsWrapper .right .mobileUser,
article .top .rsWrapper .right .mobilePrice,
article .rsWrapper .mobileQuestion,
article .rsWrapper .reviewList .list .userReview>div .top div img,
article .rsWrapper .reviewList .list .userReview>div .top div .mobileRate,
article .rsWrapper .reviewList .list .userReview>div .top>p,
article .rsWrapper .reviewList .list .mobileBtn,
article .top .rsWrapper .right .mobilePrice #purpleBtn {
display: none;
}



@media (max-width : 1400px) {

article .top .rsWrapper .right .priceBox #darkGrey,
article .top .rsWrapper .right .priceBox #purpleBtn {
width: 35%;
}

article .top .rsWrapper .right .content .text {
height: 210px;
}
}

@media (max-width :1280px) {
article .top .rsWrapper .right .content .text {
height: 130px;
}
}

@media(max-width : 1100px) {

article .top .rsWrapper .left,
article .top .rsWrapper .right {
width: 100%;
}


article .top .rsWrapper,
article .rsWrapper .reviewList .list {
flex-wrap: wrap;
}

article .rsWrapper .reviewList .list .userReview,
article .rsWrapper .reviewList .list .answer {
width: 100%;
margin: 0 0 30px;
}
}

@media (max-width : 900px) {
article .top .rsWrapper .left .imgBox div .thumbnail {
margin: 0;
}

article .top .rsWrapper .left .play img {
width: 20px;
margin: 0 40px 0 0;
}

article .top .rsWrapper .left #purpleBtn,
article .top .rsWrapper .right .icon,
article .top .rsWrapper .right .user,
article .top .rsWrapper .right .priceBox>h1,
article .top .rsWrapper .right #greyBtn5,
article .top .rsWrapper .right #logoutBtn,
article .rsWrapper .reviewCreate .question,
article .rsWrapper .reviewCreate .review p,
article .rsWrapper .reviewCreate .create p,
article .rsWrapper .reviewCreate .create div .input,
article .rsWrapper .reviewList .list .userReview>img,
article .rsWrapper .reviewList .list .userReview>div .top #greyBtn,
article .rsWrapper .reviewList .list .userReview>div .rateBox {
display: none;
}


article .top .rsWrapper .right .mobileUser .userIcon {
width: 32px;
height: 32px;
border-radius: 100%;
padding: 0;
background: linear-gradient(to bottom, #13d5df 0%, #f138fc 100%);
}

article .top .rsWrapper .right .mobileUser .userIcon>img {
width:100%;
height:100%;
border-radius:100%;
}

article .top .rsWrapper .right .mobileUser>div {
width: calc(100% - 80px - 15px);
padding: 0 0 0 15px;
}



article .top .rsWrapper .right .mobileUser div .grade div {
width: auto;
padding: 3px 7px;
border-radius: 20px;
background: #f8ab30;
font-size: 11px;
font-weight: 600;
color: #ffffff;
margin: 0 8px 0 0;
}

article .top .rsWrapper .right .mobileUser div h2 {
font-size: 16px;
font-weight: 500;
color: #0e0e1f;
}

article .top .rsWrapper .right .mobileUser div .grade {
margin: 0 0 7px;
}

article .top .rsWrapper .right .mobileUser div p {
font-size: 12px;
font-weight: 500;
color: #6d6c75;
}

article .top .rsWrapper .right .mobileUser {
margin: 0 0 15px;
display: flex;
}

article .top .rsWrapper .right>h1 {
font-size: 20px;
}

article .top .rsWrapper .right .userInfo {
flex-direction: column;
align-items: flex-start;
}

article .top .rsWrapper .right .userInfo .playNum {
margin: 0 0 10px
}

article .top .rsWrapper .right .priceBox #darkGrey,
article .top .rsWrapper .right .priceBox #purpleBtn {
width: 49%;
}

article .top .rsWrapper .right .mobilePrice {
display: flex;
margin: 0 0 30px;
}


article .top .rsWrapper .right .mobilePrice h1 {
font-size: 26px;
color: #0e0e1f;
font-weight: 600;
}

article .top .rsWrapper .right .mobilePrice #purpleBtn {
width: 95px;
height: 35px;
border-radius: 4px;
display: flex;
}

article .top .rsWrapper .right .mobilePrice #purpleBtn img {
width: 15px;
margin: 0 10px 0 0;
}

article .top .rsWrapper .right .mobilePrice #purpleBtn p {
font-size: 14px;
}

article .top .rsWrapper .right .content .typeBox .type {
font-size: 12px;
margin: 0 8px 8px 0;
}

article .rsWrapper .prompt h1 {
margin: 50px 0 30px;
}

article .rsWrapper .mobileQuestion {
margin: 0 0 30px;
display: flex;
}

article .rsWrapper .mobileQuestion>p {
font-size: 14px;
font-weight: 600;
color: #0e0e1f;
margin: 0 0 20px;
}

article .rsWrapper .mobileQuestion #darkGrey2:hover .hoverIcon {
opacity: 1;
}

article .rsWrapper .mobileQuestion #darkGrey2 .hoverIcon {
opacity: 0;
position: absolute;
left: 41px;

}

article .rsWrapper .mobileQuestion #darkGrey2 {
width: 100%;
height: 48px;
border-radius: 50px;
position: relative;
}

article .rsWrapper .mobileQuestion #darkGrey2 img {
width: 25px;
margin: 0 10px 0 0;
}

article .rsWrapper .mobileQuestion #darkGrey2 p {
font-size: 13px;
}

article .rsWrapper .reviewBox {
justify-content: space-between;
margin: 0 0 10px;
}

article .rsWrapper .reviewBox h1 {
font-size: 20px;
}

article .rsWrapper .reviewCreate .create {
width: 100%;
padding: 0;

}

article .rsWrapper .reviewCreate .create div {
flex-wrap: wrap;
justify-content: flex-end;
}

article .rsWrapper .reviewCreate {
flex-direction: column;
align-items: flex-start;
}

article .rsWrapper .reviewCreate .review {
margin: 0 0 10px;
}

article .rsWrapper .reviewCreate .create div .mobileInput:focus {
border: 1px solid #b041f3;
background: #ffffff;
}

article .rsWrapper .reviewCreate .create div .mobileInput {
display: initial;
width: 100%;
height: 130px;
border-radius: 4px;
background: #f4f5f7;
padding: 16px 12px;
background: #f4f5f7;
border: none;
box-shadow: none;
margin: 0 0 10px;
}


article .rsWrapper .reviewCreate .create div .mobileInput::placeholder {
color: #a3a1a7;
}

article .rsWrapper .reviewList .list .userReview>div {
width: 100%;
padding: 14px 10px;
}

article .rsWrapper .reviewList .list .userReview>div .content {
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
white-space: nowrap;
}

article .rsWrapper .reviewList .list {
width: 100%;
border-radius: 20px;
}

article .rsWrapper .reviewList .list .answer {
border-radius: 30px 0 0 0;
padding: 10px;
flex-wrap: wrap;
justify-content: flex-end;
margin: 0;
}

article .rsWrapper .reviewList .list .answer .mobileInput {
display: initial;
width: 100%;
height: 130px;
background: #f4f5f7;
padding: 16px 12px;
font-size: 13px;
border: none;
box-shadow: none;
margin: 0 0 10px;
}

article .rsWrapper .reviewList .list .userReview>div .top div img,
article .rsWrapper .reviewList .list .userReview>div .top div .mobileRate,
article .rsWrapper .reviewList .list .userReview>div .top>p {
display: initial;
}

article .rsWrapper .reviewList .list .userReview>div .top div img {
width: 30px;
height: 30px;
border-radius: 100%;
margin: 0 10px 0 0;
}

article .rsWrapper .reviewList .list .userReview>div .top div p {
font-size: 14px;
margin: 0 8px 0 0;
border-bottom: 1px solid #0e0e1f;
}

article .rsWrapper .reviewList .list .userReview>div .top>p {
font-size: 12px;
color: #6d6c75;
}

article .rsWrapper .reviewList .list .userReview {
margin: 0;
}

article .rsWrapper .reviewList .list .mobileBtn {
display: flex;
}


article .rsWrapper .reviewList .list .mobileBtn #greyBtn {
width: 45px;
height: 30px;
font-size: 12px;
border-radius: 24px;
margin: 0 0 10px;
}

article .rsWrapper .allReview {
align-items: flex-end;
}

article .top .rsWrapper .left .imgScroll {
height: 350px;
}

article .top .rsWrapper .right .tip div h1,
article .top .rsWrapper .right .tip div p {
font-size: 12px;
}



.imageModal .pagination h1 {
display: none;
}

.imageModal .pagination {
padding: 0 16px;
}

.imageModal div>h1 {
position: absolute;
top: 200px;
right: 16px;
color: #ffffff;
font-size: 14px;
z-index: 10;
}


#reviewModal .reviewTitle {
border-bottom: none;
}

#reviewModal .reviewBox {
flex-wrap: wrap;
}

#reviewModal .reviewBox h1 {
font-size: 26px;
font-weight: bold;
color: #323146;
margin: 0 0 5px;
}

#reviewModal .reviewBox h2 {
width: 100%;
margin: 0 0 10px;
font-size: 20px;
}



#reviewModal .listBox .listScroll .list .userReview>div {
width: 100%;
padding: 14px 10px;
}

#reviewModal .listBox .listScroll .list .userReview>div .content {
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
white-space: nowrap;
}

#reviewModal .listBox .listScroll .list {
width: 100%;
border-radius: 20px;
}

#reviewModal .listBox .listScroll .list .answer {
border-radius: 30px 0 0 0;
padding: 10px;
flex-wrap: wrap;
justify-content: flex-end;
margin: 0;
}

#reviewModal .listBox .listScroll .list .answer .mobileInput {
display: initial;
width: 100%;
height: 130px;
background: #f4f5f7;
padding: 16px 12px;
font-size: 13px;
border: none;
box-shadow: none;
margin: 0 0 10px;
}

#reviewModal .listBox .listScroll .list .userReview>div .top div {
width: auto;
}

#reviewModal .listBox .listScroll .list .userReview>div .top div img,
#reviewModal .listBox .listScroll .list .userReview>div .top div .mobileRate,
#reviewModal .listBox .listScroll .list .userReview>div .top>p {
display: initial;
}

#reviewModal .listBox .listScroll .list .userReview>div .top div img {
width: 30px;
height: 30px;
border-radius: 100%;
margin: 0 10px 0 0;
}

#reviewModal .listBox .listScroll .list .userReview>div .top div p {
font-size: 14px;
margin: 0 8px 0 0;
border-bottom: 1px solid #0e0e1f;
}

#reviewModal .listBox .listScroll .list .userReview>div .top>p {
font-size: 12px;
color: #6d6c75;
}

#reviewModal .listBox .listScroll .list .userReview {
margin: 0;
}

#reviewModal .listBox .listScroll .list .mobileBtn {
display: flex;
}


#reviewModal .listBox .listScroll .list .mobileBtn #greyBtn {
width: 45px;
height: 30px;
font-size: 12px;
border-radius: 24px;
margin: 0 0 10px;
}

#reviewModal .listBox {
height: auto;
overflow: hidden;
}

}


