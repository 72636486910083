/* 메세지 전체 레이아웃 */
#myMessage {
    padding: 0 0 50px;
}
#myMessage>div {
    border-bottom: solid 2px #000;
}
#myMessage>div>.title {
    padding: 40px 0 48px;
    overflow: auto;
}
#myMessage>div>.title .overflowBox {
    min-width: 800px;
}
#myMessage>div>.title .overflowBox>div {
    margin: 0 30px;
    width: auto;
    position: relative;
}
#myMessage>div>.title .overflowBox div h1 {
    font-size: 32px;
    font-weight: bold;
    color: #cccccc;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
#myMessage>div>.title .overflowBox div h1:hover,
#myMessage>div>.title .overflowBox div#active h1 {
    color: #0e0e1f;
}
#myMessage>div>.title .overflowBox div .round {
    display: none;
}
#myMessage>div>.title .overflowBox div#active .round {
    display: flex;
    position: absolute;
    top: -6px;
    left: -10px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fe5139;
}

/* 탭 하단 레이아웃 */
#message {
    height: 80vh;
    padding: 25px 0 40px;
}
#message .rsWrapper {
    height: 100%;
}

/* 메세지 리스트 영역 */
#message .rsWrapper .list {
    max-width: 400px;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #f3f3f3;
    overflow: hidden;
    height: 100%;
    justify-content: flex-start;
    overflow-y: auto;
}
#message .rsWrapper .list .top {
    padding: 8px 16px;
    border-bottom: solid 1px #dfdce4;
    background-color: #fff;
    font-size: 14px;
}
#message .rsWrapper .list .top #logoutBtn {
    width: 77px;
    height: 35px;
    border-radius: 4px;
}
#message .rsWrapper .list .top #logoutBtn img {
    width: 16px;
}
#message .rsWrapper .list .box#active {
    background: #ffffff;
}
#message .rsWrapper .list .box#new {
    background: #fdf2ff;
}
#message .rsWrapper .list .box {
    padding: 8px 12px 8px 24px;
    border-bottom: solid 1px #eae8ec;
}
#message .rsWrapper .list .box:has(.ant-checkbox-wrapper-checked) {
    background-color: #fdf2ff;
}
#message .rsWrapper .list .box>div:first-child {
    width: auto;
    position: relative;
}
#message .rsWrapper .list .box>div:first-child img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
#message .rsWrapper .center .message .reciver>div>div>.round,
#message .rsWrapper .list .box>div:first-child .round {
    border: 2px solid #ffffff;
    background: #e94cf7;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
}
#message .rsWrapper .list .box .more {
    width: 20px;
    position: relative;
    margin: 0;
}
#message .rsWrapper .list .box>div {
    width: calc(100% - 40px - 20px);
    margin: 0 12px 0 -4px;
}
#message .rsWrapper .list .box>div>div:first-child {
    margin: 0 0 4px;
}
#message .rsWrapper .list .box>div>div>h1 {
    font-size: 16px;
    font-weight: 600;
    color: #0e0e1f;
}
#message .rsWrapper .list .box>div>div>.count {
    width: 26px;
    height: auto;
    background: #f34144;
    border-radius: 100px;
    padding: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
}
#message .rsWrapper .list .box>div>div>h2 {
    color: #656262;
    font-size: 12px;
}
#message .rsWrapper .list .box>div>div>p {
    font-size: 11px;
    color: #a3a1a7;
    font-weight: 500;
}
#message .rsWrapper .list .box .more p {
    font-size: 18px;
    color: #686275
}
#message .rsWrapper .list .box .more .absoluteBox {
    position: absolute;
    top: 20px;
    right: 0;
    border: 1px solid #c718e2;
    background: #ffffff;
    padding: 4px;
    width: 100px;
    border-radius: 4px;
    z-index: 10;
}
#message .rsWrapper .list .box .more .absoluteBox>div:hover {
    background: #f7f7f8;
}
#message .rsWrapper .list .box .more .absoluteBox>div {
    font-size: 13px;
    border-radius: 4px;
    height: 32px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

/* 가운데 상세 메세지 영역 */
#message .rsWrapper .center {
    flex: 1;
    justify-content: flex-start;
    height: 100%;
    padding: 0 25px;
}
#message .rsWrapper .center .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 76px;
}
#message .rsWrapper .btn-back {
    display: none;
}
#message .rsWrapper .center .title img {
    width: 10px;
    margin: 0 20px 0 0;
}
#message .rsWrapper .center .title h1 {
    font-size: 24px;
    font-weight: 700;
}
#message .rsWrapper .center .message {
    height: calc(100% - 76px - 50px - 24px);
    justify-content: flex-start;
}
#message .rsWrapper .center .message .sender,
#message .rsWrapper .center .message .reciver {
    margin: 0 0 12px;
}
#message .rsWrapper .center .message .reciver>div>div {
    position: relative;
    width: auto;
    margin: 0 8px 0 0;
}
#message .rsWrapper .center .message .sender>div,
#message .rsWrapper .center .message .reciver>div {
    align-items: flex-end;
    margin: 0 0 8px;
}
#message .rsWrapper .center .message .reciver>div>div>img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
#message .rsWrapper .center .message .sender>div,
#message .rsWrapper .center .message .reciver>div>div:last-child {
    max-width: 280px;
    width: auto;
    font-size: 14px;
    color: #000000;
    font-weight: 600;
    padding: 8px 16px;
    background: #f7f7f8;
    border-radius: 24px 24px 24px 0;
    line-height: 1.3;
}
#message .rsWrapper .center .message .sender>div {
    border-radius: 24px 24px 0 24px;
    font-weight: 300;
}
#message .rsWrapper .center .message .sender>p,
#message .rsWrapper .center .message .reciver>p {
    font-size: 11px;
    color: #a3a1a7;
    font-weight: 600;
}
#message .rsWrapper .center .message .sender>div {
    background: #403748;
    color: #ffffff;
}
#message .rsWrapper .center .message .sender>div>img {
    width: 58px;
    border-radius: 4px;
}
#message .rsWrapper .center .inputBox {
    margin: 24px 0 0;
    height: 50px;
    background: #f7f7f8;
    border-radius: 24px;
    padding: 8px 12px;
}
#message .rsWrapper .center .inputBox #pinkBtn.upload {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
}
#message .rsWrapper .center .inputBox #pinkBtn.upload img {
    width: 18px;
}
#message .rsWrapper .center .inputBox #pinkBtn.send {
    width: 61px;
    height: 100%;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 700;
    border: none;
}
#message .rsWrapper .center .inputBox input {
    width: calc(100% - 32px - 61px);
    padding: 0 10px;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none;
    font-size: 14px;
    color: #000000;
}
#message .rsWrapper .center .notice {
    display: flex;
    flex-direction: column;
    
}
#message .rsWrapper .center .notice .date {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #a3a1a7;
}
#message .rsWrapper .center .notice .box {
    display: flex;
    gap: 8px;
    width: 100%;
}
#message .rsWrapper .center .notice .box .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #13d5df 0%, #f138fc 100%);
}
#message .rsWrapper .center .notice .box .icon img {
    width: 24px;
    height: 24px;
}
#message .rsWrapper .center .notice .box .content {
    flex: 1;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
}

/* 메세지 프로필 영역 box & info */
#message .rsWrapper .profile {
    width: 320px;
    height: 100%;
    background: linear-gradient(to bottom, #d6ecf6, #e9e4f6);
    padding: 15px;
    border-radius: 0 8px 8px 0;
    position: relative;
    justify-content: flex-start;
    display: flex;
}
#message .rsWrapper .profile .title {
    display: none;
}
#message .rsWrapper .profile .box {
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10;
}
#message .rsWrapper .profile .box .profileBox {
    gap: 8px;
}
#message .rsWrapper .profile .box .profileBox > img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: solid 1px rgba(0, 0, 0, 0.05);
    margin: 0 0 8px;
}
#message .rsWrapper .profile .box .profileBox > h1 {
    font-size: 14px;
    color: #0e0e1f;
}
#message .rsWrapper .profile .box .profileBox > h2 {
    font-size: 11px;
    color: #6d6c75;
}
#message .rsWrapper .profile .box .profileBox .grade {
    width: 45px;
    height: 22px;
    border-radius: 20px;
    border: 1px solid transparent;
    background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-size: 11px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
}
#message .rsWrapper .profile .box .profileBox >div:has(.grade) img {
    width: 13px;
    height: 13px;
    margin-left: 4px;
}
#message .rsWrapper .profile .box .followBox {
    position: relative;
    padding: 20px 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
/* 팔로워확인모달 */
#message .followBox .modal-overlay {
    position: absolute;
    top: 100%;
    right: 50%;
    transform:  translateX(50%);
    z-index: 999;
}
#message .followBox .modal-content {
    width: 400px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: #000;
}
#message .followBox .modal-content .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
#message .followBox .modal-content .title h2 {
    font-size: 24px;
    font-weight: 600;
}
#message .followBox .modal-content .data {
    max-height: 320px;
    overflow-y: auto;
}
#message .rsWrapper .profile .box .followBox > div {
    flex: 1;
}
#message .rsWrapper .profile .box .followBox > div h1 {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    color: #0e0e1f;
}
#message .rsWrapper .profile .box .followBox > div p {
    font-size: 12px;
    color: #0e0e1f;
}
#message .rsWrapper .profile .box .infoBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding-top: 12px; 
}
#message .rsWrapper .profile .box .infoBox .snsArea button {
    width: 16px;
    height: 16px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 10;
    width: 90px;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px #c718e2;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox img {
    width: 18px;
}
#message .rsWrapper .profile .box .infoBox .rankingArea .absoluteBox li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2px;
    color: #000;
}
#message .rsWrapper .profile .box .infoBox .rateArea {
    display: flex;
    align-items: center;
    gap: 8px;
}
#message .rsWrapper .profile .box .infoBox .rateArea h2 {
    font-size: 16px;
    font-weight: 600;
    color: #0e0e1f;
}
/* ant 별점 컨트롤 */
#message .rsWrapper .profile .box .infoBox .rateArea ul {
    display: flex;
}
#message .rsWrapper .profile .box .infoBox .rateArea ul li {
    margin: 0;
}
#message .rsWrapper .profile .box .infoBox .rateArea ul li svg {
    width: 14px;
}
#message .rsWrapper .profile .box .infoBox .rateArea p {
    font-size: 14px;
    color: #87858b;
}
#message .rsWrapper .profile .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding: 0 8px;
}
#message .rsWrapper .profile .info h3 {
    font-size: 14px;
    font-weight: 500;
    color: #0e0e1f;
}
#message .rsWrapper .profile .info h4 {
    font-size: 13px;
    color: #6d6c75;
}

@media (max-width : 700px) {
    /* 메세지 레이아웃 */
    #myMessage > div {
        border-bottom: none;
    }
    #myMessage > div > .title {
        justify-content: flex-start;
        padding: 30px 0 20px;
    }
    #myMessage > div > .title .overflowBox {
        justify-content: flex-start;
    }
    #message {
        padding: 0;
    }
    #message .rsWrapper {
        padding: 0;
    }

    /* 메세지 리스트 영역 */
    #message .rsWrapper .list {
        max-width: none;
    }

    /* 가운데 상세 메세지 영역 */
    #message .rsWrapper .center {
        display: none;
        position: fixed;
        top: 0; 
        left: 0;
        z-index: 1001;
        width: 100vw !important;
        padding: 0 15px;
        background-color: #fff;
    }
    #message .rsWrapper .btn-back {
        display: block;
        width: 10px;
        height: 18px;
        margin-right: 12px;
        background: url(/src/assets/images/chat/black_arrow_left.png) no-repeat center;
        background-size: contain;
    }
    #message .rsWrapper .center .svgBox {
        display: none;
    }
    #message .rsWrapper .center .inputBox {
        margin: 0;
    }
    
    /* 대화 클릭시 노출되는 프로필 */
    #message .rsWrapper .profile {
        display: none;
        position: fixed;
        z-index: 1001;
        top: 0; 
        left: 0;
        width: 100vw;
        height: 100vh;
    }
    #message .rsWrapper .profile.active {
        display: flex;
    }    
    #message .rsWrapper .profile .title {
        display: flex;
        align-items: center;
        height: 44px;
        color:#0e0e1f;
    }
    #message .rsWrapper .profile .title h1 {
        font-size: 24px;
        font-weight: 700;
    }
    /* 팔로워 확인 모달 */
    #message .followBox .modal-overlay {
        position: fixed;
        top: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.1);
    }
    #message .followBox .modal-content {
        width: 90%;
    }
    #message .followBox .modal-content .data {
        max-height: 90%;
    }
    #message .rsWrapper .list {
        width: 100%;
        border: none;
        background: #ffffff;
        border-top: 1px solid #f3f3f3;
    }
}
