#signup {
  width: 580px;
  padding: 20px 70px;
}
#signup .top {
  margin: 0 0 20px;
  background: #ffffff;
  padding: 0;
}
#signup .top div {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#signup .top div:hover {
  background: #cccccc;
}
#signup .logo {
  width: 310px;
  padding: 15px 0 40px;
}
#signup .logo img {
  width: 100%;
}
#signup .email .input {
  width: calc(100% - 120px - 10px) !important;
}
#signup .email #pinkBtn {
  width: 120px;
  height: 55px;
  border-radius: 5px;
}
#signup .input,
#signup .email .input {
  width: 100%;
  height: 55px;
  /* background: #eeeeee; */
  border-radius: 5px;
  box-shadow: none;
  padding: 16px 20px;
  font-size: 16px;
}
#signup .input::placeholder,
#signup .email .input::placeholder {
  color: #a3a1a7;
}
#signup .input:hover,
#signup .email .input:hover {
  border: 1px solid #ec15ff;
}
#signup .input:focus,
#signup .email .input:focus {
  background: #ffffff;
  border: 1px solid #ec15ff;
}
#signup .check {
  margin: 0 0 16px;
}
#signup .checkBox {
  border-top: 1px solid #dfdce3;
  padding: 20px 0 0;
}
#signup .checkBox div {
  margin: 0 0 15px;
}
#signup .checkBox div p {
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 1;
}
#signup .checkBox div p:hover {
  color: #ec15ff;
  border-bottom: 1px solid #ec15ff;
}
#signup .allCheck {
  width: 100%;
  height: 30px;
  margin-top: 28px;
  margin-bottom: 8px;
}
#signup #purpleBtn {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  font-size: 18px;
  margin: 0 0 26px;
}
#signup .signup {
  margin: 0 0 24px;
}
#signup .signup p {
  color: #676767;
  font-size: 13px;
  padding: 0 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#signup .signup span:hover {
  color: #ec15ff;
}
#signup .signup p:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
}
#signup .line {
  position: relative;
  margin: 0 0 20px;
}
#signup .line p {
  font-size: 16px;
  z-index: 2;
  padding: 0 10px;
  background: #ffffff;
  color: #6d6c75;
}
#signup .line .absoluteBox {
  position: absolute;
  top: 50%;
  height: 1px;
  left: 0;
  margin: -0.5px 0 0;
  background: #dfdce4;
}
#signup .btn #kakao,
#signup .btn #naver,
#signup .btn #facebook,
#signup .btn #google {
  width: 100%;
  height: 58px;
  margin: 0 0 16px;
  border-radius: 4px;
}
#signup #kakao img,
#signup #naver img,
#signup #facebook img,
#signup #google img {
  width: 20px;
  margin: 0 20px 0 0;
}
#signup #kakao p,
#signup #naver p,
#signup #facebook p,
#signup #google p {
  font-size: 18px;
}
#signup #kakao.squre,
#signup #naver.squre,
#signup #google.squre {
  width: 80px;
  height: 80px;
  margin: 0 0 16px;
  border-radius: 24px;
  flex-direction: column;
}
#signup #kakao.squre img,
#signup #naver.squre img,
#signup #google.squre img {
  width: 20px;
  margin: 0 0 10px;
}
#signup #kakao.squre p,
#signup #naver.squre p,
#signup #google.squre p {
  font-size: 13px;
}

#success .top {
  margin: 0 0 20px;
}
#success .top h1 {
  font-size: 24px;
  font-weight: bold;
  color: #0e0e1f;
}
#success .top p {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#success .top p:hover {
  color: #ec15ff;
}
#success .content {
  padding: 50px 0;
}
#success .content p {
  font-size: 20px;
}
#success #purpleBtn {
  width: 100%;
  height: 58px;
  font-size: 18px;
  border-radius: 4px;
}

#error .top {
  margin: 0 0 20px;
}
#error .top h1 {
  font-size: 24px;
  font-weight: bold;
  color: #0e0e1f;
}
#error .top p {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#error .top p:hover {
  color: #ec15ff;
}
#error .content {
  padding: 53px 0;
}
#error .content p {
  font-size: 20px;
}
#error .content span {
  font-size: 20px;
  color: #a3a1a7;
}
#error #purpleBtn {
  width: 100%;
  height: 58px;
  font-size: 18px;
  border-radius: 4px;
}

@media (max-width: 700px) {
  #signup {
    width: 100%;
    padding: 20px;
  }
  #signup .input,
  #signup .email .input,
  #signup .input::placeholder,
  #signup .email .input::placeholder {
    font-size: 14px;
  }
}