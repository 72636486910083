#newSlider {
  display: block;
  width: 100%;
  height: auto;
  padding: 0 42px;
}
#newSlider .musicBox {
  border-radius: 100%;
  position: relative;
  padding: 0 18px;
}
#newSlider .musicBox img {
  width: 100%;
  height: auto;
  border-radius: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}
#newSlider .musicBox:hover div:first-child {
  opacity: 1;
}
#newSlider .slick-current .musicBox:hover div:first-child {
  opacity: 0;
}
#newSlider .musicBox div:first-child {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 18px;
  width: calc(100% - 36px);
  border-radius: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(19, 213, 223, 0.6) 0%, rgba(241, 56, 252, 0.6) 107%);
  transition: all 0.2s ease-in-out;
}
#newSlider .musicBox div:first-child img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 0 8px;
}
#newSlider .musicBox div:first-child h1 {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  margin: 8px;
}
#newSlider .musicBox div:first-child p {
  font-size: 12px;
  color: #ffffff;
}
#newSlider .musicBox #active {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 10px;
  width: calc(100% - 20px);
  height: 100%;
}
#newSlider .musicBox #active:hover {
  z-index: 9999;
}
#newSlider .musicBox #active img {
  width: 72px;
  height: 72px;
  margin: 17px 0;
}
#newSlider .musicBox #active div {
  width: auto;
}
#newSlider .musicBox #active div h1 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  margin: 0 0 12px;
}
#newSlider .musicBox #active div p {
  font-size: 12px;
  color: #ffffff;
}
#newSlider .slick-current {
  transform: scale(1.3);
  z-index: 100;
  position: relative;
  padding: 0 10px;
  transition: all 0.2s ease-in-out;
}
#newSlider .slick-current .musicBox #active {
  opacity: 1;
}

#top10 #newSlider .slick-current {
  transform: scale(1.75);
}

#newSlider .slick-slide {
  transition: all 0.2s ease-in-out;
}
#newSlider .slick-list {
  height: 320px;
  padding: 40px 0 0 !important;
}

#top10 #newSlider .slick-list {
  height: 260px;
  padding: 60px 0 0 !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.1);
}

#newSlider .musicBox #active .mobileImg {
  display: none;
}
#newSlider .slick-slider {
  /* padding: 0 10px; */
}

#aiWrapper #newSlider .slick-slider {
  padding: 0;
}

#newSlider .arrowLeft,
#newSlider .arrowRight {
  position: absolute;
  width: 36px;
  margin: 0 4px 0 0;
  height: 36px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}
#newSlider .arrowLeft:hover,
#newSlider .arrowRight:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
  color: #ffffff;
}

#aiWrapper #newSlider .arrowLeft {
  left: -42px;
}

#newSlider .arrowLeft {
  top: 50%;
  left: -82px;
  margin: -18px 0 0;
}

#aiWrapper #newSlider .arrowRight {
  right: -42px;
}

#newSlider .arrowRight {
  top: 50%;
  right: -82px;
  margin: -18px 0 0;
}

#newWrapper #newSlider .musicBox #active img {
  width: 72px;
  height: 72px;
  /* margin: 17px 0; */
  margin: 48px 0 0;
}
#newWrapper #newSlider .musicBox #active div {
  width: 100%;
  padding: 10px 40px;
}
#newWrapper #newSlider .musicBox #active div h1 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 12px;
  text-overflow: ellipsis;
}
#newWrapper #newSlider .musicBox #active div p {
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
}

@media (max-width: 1350px) {
  #newSlider .musicBox #active img {
    width: 80px;
    height: 80px;
  }
  #newSlider .musicBox #active div h1 {
    font-size: 12px;
    margin: 0 0 5px;
  }
}
@media (max-width: 1280px) {
  #newSlider .musicBox #active img {
    width: 55px;
    height: 55px;
  }
  #newSlider .musicBox #active div h1,
  #newSlider .musicBox #active div p {
    font-size: 10px;
  }
  #newSlider .slick-list {
    height: 220px;
  }
}
@media (max-width: 1100px) {
  #newSlider .musicBox {
    padding: 0 20px;
  }
  #newSlider .musicBox div:first-child {
    left: 20px;
    width: calc(100% - 40px);
  }
  #newSlider .slick-list {
    height: 300px;
  }
}
@media (max-width: 900px) {
  #newSlider .slick-list {
    height: 240px;
  }
}
@media (max-width: 700px) {
  #newSlider {
    padding: 0;
  }
  #newSlider .musicBox {
    padding: 0 10px;
  }
  #newSlider .slick-current {
    transform: scale(1.6);
  }
  #newSlider .slick-current .musicBox {
    padding: 0;
  }
  #newSlider .musicBox #active {
    width: 100%;
    height: 100%;
    left: 0;
    padding: 0 10px 10px;
    justify-content: space-between;
  }
  #newSlider .musicBox #active .mobileImg {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: flex;
  }
  #newSlider .musicBox #active img {
    width: 35px;
    height: 35px;
    margin: 5px 0;
  }
  #newSlider .musicBox #active div {
    align-items: center;
  }
  #newSlider .musicBox #active div h1 {
    margin: 0;
    text-align: center;
    font-size: 8px;
    margin: 0 0 3px;
  }
  #newSlider .musicBox #active div p {
    font-size: 6px;
  }
  #newSlider .slick-list {
    height: auto;
    padding: 80px 0 !important;
  }
  #newSlider .musicBox div:first-child {
    width: 100%;
    left: 0;
    padding: 0 10px;
    background: none;
  }
  #newSlider .musicBox div:first-child h1 {
    text-align: center;
    margin: 0;
  }
  #newSlider .musicBox:hover div:first-child {
    opacity: 1;
  }
  #aiWrapper #newSlider .arrowLeft,
  #newSlider .arrowLeft {
    left: -10px;
    color: #ffffff;
  }
  #aiWrapper #newSlider .arrowRight,
  #newSlider .arrowRight {
    right: -10px;
    color: #ffffff;
  }
}
