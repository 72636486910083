.param_title{
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
}

.promptSaveGuide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.promptSaveGuide h1{
  background: linear-gradient(to right, #13d5df, #f138fc);
  font-weight: 700;
  color: transparent;
  text-align: center;
  -webkit-background-clip: text;
  margin: 0 0 10px;
  line-height: 1.2;
}