#admin .selected {
    background: #ffffdd;
}
#admin .dragging {
    background: palevioletred;
}

#admin .dropHover {
    background: #86748f !important;
}

#admin div .searchBox {
    padding: 16px;
    border-bottom: 2px solid #000000;
    margin: 0 0 16px;
}

#admin div .searchBox .leftBox,
#admin div .searchBox .rightBox {
    width: auto;
}

#admin div .searchBox .rightBox .select,
#admin div .searchBox .leftBox .select {
    width: 120px;
    height: 40px;
    margin: 0 8px 0 0;
}

#admin div .searchBox .rightBox .select .ant-select-selector,
#admin div .searchBox .leftBox .select .ant-select-selector {
    width: 120px;
    height: 40px;
    background: #f4f5f7;
    border: 1px solid #dfdce4;
}

#admin div .searchBox .leftBox .search {
    width: 400px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #efecf1;
    background: #f4f5f7;
    padding: 8px 12px;
}

#admin div .searchBox .leftBox .search img {
    width: 20px;
    margin: 0 8px 0 0;
}

#admin div .searchBox .leftBox .search .input {
    box-shadow: none;
    background: transparent;
    border: none;
}

#admin div .searchBox .rightBox #logoutBtn {
    width: auto;
    height: 40px;
    padding: 4px 12px;
    border-radius: 4px;
    margin: 0 8px 0 0;
}

#admin div .searchBox .rightBox #logoutBtn div img {
    width: 18px;
}

#admin div .searchBox .rightBox #logoutBtn .text {
    font-size: 14px;
    margin: 0 0 0 8px;
}

#admin div .searchBox .rightBox .listBtn {
    width: 84px;
    border: 1px solid #f3f3f3;
    border-radius: 3px;
    background: #f3f3f3;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#admin div .searchBox .rightBox .listBtn:hover {
    background: #ffffff;
}

#admin div .searchBox .rightBox .listBtn .btn#active {
    background: #ffffff;
}

#admin div .searchBox .rightBox .listBtn .btn {
    width: 40px;
    height: 40px;
}



#admin div .searchBox .rightBox .listBtn .btn:first-child {
    border-right: 1px solid #f3f3f3;
}

#admin div .searchBox .rightBox .listBtn .btn p {
    font-size: 18px;
}

#admin div .searchBox .rightBox .listBtn .btn img {
    width: 18px;
}

#admin div div .list {
    min-height: 50vh;
    justify-content: start;
}
#admin div div .list>div {
    padding: 12px 8px;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#admin div div .list>div:hover {
    /*background: #f4f5f7;*/
}

#admin div div .list .checkbox {
    width: 20px;
    margin: 0 38px 0 0;
}

#admin div div .list .thumbnail {
    width: 64px;
    height: 36px;
    border-radius: 8px;
    background: #f2f2f2;
    overflow: hidden;
    margin: 0 16px 0 0;
}

#admin div div .list .thumbnail img {
    width: 100%;
    height: 100%;
}

#admin div div .list .thumbnail img.icon {
    width: 24px;
    height: auto;
}

#admin div div .list .title {
    width: calc(100% - 20px - 38px - 64px - 16px - 300px - 73px - 20px - 40px - 190px - 48px);
    margin: auto;
    color: initial;
    font-weight: initial;
    font-size: initial;
}


#admin div div .list .title h1 {
    font-size: 14px;
    margin: 0 0 7px;
}

#admin div div .list .title div img {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin: 0 8px 0 0;
}

#admin div div .list .title div h2 {
    font-size: 12px;
    color: #323146;
    margin: 0 20px 0 0;
}

#admin div div .list .title div p {
    font-size: 12px;
    color: #6d6c75;
}

#admin div div .list .channel {
    width: 300px;
}

#admin div div .list .channel p {
    font-size: 13px;
    color: #3e3d53;
}

#admin div div .list .mark {
    width: 73px;
    margin: 0 10px;
    border-radius: 50px;
    font-size: 13px;
    background: #fe393c;
    color: #ffffff;
    padding: 4px 0;
}

#admin div div .list .star {
    width: 40px;
    margin: 0 18px 0 0;
    position: relative;
    cursor: pointer;
}

#admin div div .list .star:hover img.activeImg {
    opacity: 1;
}

#admin div div .list .star img {
    width: 14px;
}

#admin div div .list .star img.activeImg {
    transition: all .2s ease-in-out;
    position: absolute;
    left: 13px;
    width: 14px;
    opacity: 0;
}

#admin div div .list .date {
    width: 190px;
    padding: 0 8px;
    font-size: 14px;
    color: #87858b;
}


#admin div div .more {
    width: 48px;
    padding: 0 12px;
    position: relative;
    font-size: 14px;
    color: #87858b;
}

#admin .contextMenu {
    position: absolute;
    right: 30px;
    width: 100px;
    padding: 5px 0;
    border: 1px solid #c718e2;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 10;
}

#admin .contextMenu .menu {
    height: 40px;
    background: #ffffff;
    cursor: pointer;
}


#admin .contextMenu .menu:hover {
    background: #fdf2ff;
}

#admin .contextMenu .menu>p {
    font-size: 13px;
    color: #000000;
}


#admin div div .list2 {
    flex-wrap: wrap;

}

#admin div div .list2>div {
    width: calc(100% / 6 - 20px);
    margin: 0 23px 64px 0;
    border-radius: 10px 100px 10px 10px;
    position: relative;
    background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#admin div div .list2>div:hover {
    padding: 5px;
}

#admin div div .list2>div:hover .thumbnail {
    height: 118px;
}

#admin div div .list2 div:nth-child(6n) {
    margin: 0 0 64px;
}

#admin div div .list2 div .thumbnail {
    position: relative;
    background: linear-gradient(225deg, #ffeed2, #ffd8c0);
    height: 128px;
    border-radius: 10px 100px 0 0;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

#admin div div .list2 div .thumbnail>h1 {
    font-size: 16px;
    color: #3e3d53;
    font-weight: 600;
    display: flex;
}

#admin div div .list2 div#img .thumbnail>img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
}


#admin div div .list2 div#img .thumbnail .absoluteBox {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

#admin div div .list2 div .thumbnail .absoluteBox div .checkbox {
    width: 20px;
    margin: 0 12px 0 0;
}

#admin div div .list2 div#img .thumbnail .absoluteBox div img {
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    border-radius: 100%;
    opacity: 1;

}

#admin div div .list2 div#img .thumbnail .absoluteBox div h2 {
    font-size: 13px;
    color: #ffffff;
    opacity: 1;

}

#admin div div .list2 div#img .thumbnail .absoluteBox h1 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    opacity: 1;
}

#admin div div .list2 div .thumbnail .absoluteBox div img,
#admin div div .list2 div .thumbnail .absoluteBox div h2,
#admin div div .list2 div .thumbnail .absoluteBox h1 {
    opacity: 0;
}

#admin div div .list2 div#img .thumbnail>h1,
#admin div div .list2 div .thumbnail>img {
    display: none;
}


#admin div div .list2 .folder#img {
    background: none;
}
#admin div div .list2 .folder.trash#img .thumbnail {
    background: linear-gradient(225deg, #e9e9e9, #c6c6c6);

}

#admin div div .list2 .folder#img .thumbnail {
    border-radius: 8px 70px 0 0;
}

#admin div div .list2 .folder#img .thumbnail .absoluteBox {
    background: none;
}

#admin div div .list2 .folder#img .thumbnail .absoluteBox div img,
#admin div div .list2 .folder#img .thumbnail .absoluteBox div h2,
#admin div div .list2 .folder#img .thumbnail .absoluteBox h1 {
    display: none;
}

#admin div div .list2 .folder#img .thumbnail>h1,
#admin div div .list2 .folder#img .thumbnail>img {
    display: flex;
}




#admin div div .list2 div#img {
    border-radius: 8px;
}

#admin div div .list2 div#img .thumbnail {
    border-radius: 8px 8px 0 0;
}

#admin div div .list2 div .bottom {
    padding: 8px;
    background: #f1f1f1;
    border-radius: 0 0 8px 8px;
}

#admin div div .list2 div .bottom p {
    font-size: 13px;
    color: #0e0e1f;
}

#admin div div .list2 div .bottom>div {
    width: auto;
}

#admin div div .list2 div .bottom>div img {
    width: 15px;
    margin: 0 8px 0 0;
}

#admin div div .list2 div .bottom>div .more {
    width: auto;
    position: relative;
}

#admin div div .list2 div .bottom>div .more p {
    font-size: 16px;
}



#admin div .mobile,
#admin div div .list .title>p {
    display: none;
}

@media (max-width : 1100px) {
    #admin div div .list .title>p {
        font-size: 10px;
        color: #fe393c;
        margin: 0 0 5px;
        display: flex;
    }

    #admin div div .list .mark {
        display: none;
    }

    #admin div div .list .title {
        width: calc(100% - 20px - 38px - 64px - 16px - 300px - 40px - 190px - 48px)
    }

    #admin div div .list .title h1 {
        margin: 0 0 5px;
    }

    #admin div .searchBox .leftBox .search {
        width: 300px;
    }

    #admin div div .list2>div {
        width: calc(100% / 4 - 20px);
        margin: 0 23px 64px 0;
    }

    #admin div div .list2>div:nth-child(6n) {
        margin: 0 23px 64px 0;
    }

    #admin div div .list2>div:nth-child(4n) {
        margin: 0 0 64px;
    }
}

@media (max-width :1000px) {
    #admin div div .list .thumbnail {
        width: 40px;
        height: 40px;
    }

    #admin div div .list .title {
        width: calc(100% - 20px - 38px - 40px - 16px - 300px - 40px - 100px - 48px)
    }

    #admin div div .list .date {
        width: 100px;
    }
}

@media (max-width : 900px) {



    #admin div .searchBox .leftBox>img {
        width: 18px;
        margin: 0 10px 0 0;
    }

    #admin div .searchBox {
        padding: 0 0 16px;
    }

    #admin div .searchBox .rightBox>img {
        width: 18px;
        margin: 0 5px;
    }

    #admin div div .list .checkbox {
        margin: 0 8px 0 0;
    }

    #admin div div .list .channel {
        display: none;
    }

    #admin div div .list .star {
        width: 14px;
        margin: 0 8px 0 0;
    }

    #admin div div .list .date {
        font-size: 11px;
        width: 65px;
        padding: 0;
    }

    #admin div div .more {
        width: 30px;
    }

    #admin div div .list .title {
        width: calc(100% - 20px - 8px - 40px - 8px - 14px - 8px - 65px - 30px);
    }

    #admin div div .list .title div img {
        display: none;
    }

    #admin div div .list .title div h2 {
        font-size: 10px;
        margin: 0 8px 0 0;
    }

    #admin div div .list .title h1 {
        font-size: 13px;
    }

    #admin div div .list .title div p {
        font-size: 10px;
    }

    #admin div div .list .star img.activeImg {
        left: 0;
    }

    #admin>div .searchBox {
        flex-wrap: wrap;
    }

    #admin div .mobile {
        margin: 10px 0 0;
        display: flex;
        gap:10px;
    }

    #admin div .mobile .select {
        width: 78px;
        height: 32px;
        margin: 0px;
    }

    #admin div .mobile .select .ant-select-selector {
        width: 78px;
        height: 32px;
        background: #f4f5f7;
        border: 1px solid #dfdce4;
    }

    #admin div .mobile .search {
        height: 32px;
        border-radius: 4px;
        border: 1px solid #efecf1;
        background: #f4f5f7;
        padding: 8px;
    }

    #admin div .mobile .search img {
        width: 20px;
        margin: 0 8px 0 0;
    }

    #admin div .mobile .search .input {
        box-shadow: none;
        background: transparent;
        border: none;
        font-size: 12px;
    }

    #admin div div .list2>div {
        width: calc(100% / 2 - 6.5px);
        margin: 0 13px 10px 0;
    }

    #admin div div .list2>div:nth-child(6n),
    #admin div div .list2>div:nth-child(4n) {
        margin: 0 13px 10px 0;
    }

    #admin div div .list2>div:nth-child(2n) {
        margin: 0 0 10px
    }



    #admin div div .list2 div#img .thumbnail .absoluteBox div img {
        display: none;
    }
}