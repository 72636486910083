#myProfile .rsWrapper .titleBox {
  margin: 20px 0 30px;
  position: relative;
}

#myProfile .rsWrapper .titleBox .title {
  /* width: 160px; */
  width: auto;
  padding: 0 10px;
  background: #ffffff;
  z-index: 2;
}

#myProfile .rsWrapper .titleBox .title img {
  width: 24px;
  margin: 0 15px 0 0;
}

#myProfile .rsWrapper .titleBox .title h1 {
  font-size: 24px;
  font-weight: 600;
  color: #3e3d53;
}

#myProfile .rsWrapper .titleBox .line {
  position: absolute;
  top: 50%;
  left: 0;
  /* border: 1px dashed #c2b8cb; */
  margin: -0.5px 0 0;
  width: 100%;
  height: 1px;
}

#myProfile .userInfo,
#myProfile .myInfo,
#myProfile .sellerInfo,
#myProfile .payInfo {
  align-items: flex-start;
  border-bottom: 1px solid #dbdbe0;
  padding: 30px 0;
}

#myProfile .payInfo {
  border-bottom: none;
}

#myProfile .ai,
#myProfile .aiInfo {
  border-bottom: 1px solid #dbdbe0;
  padding: 30px 0;
  align-items: flex-start;
}

#myProfile .aiInfo {
  align-items: flex-start;
}

#myProfile .title {
  width: 180px;
  flex-wrap: wrap;
  margin-right: 24px;
}

#myProfile .title img {
  width: 20px;
  margin: 0 14px 0 0;
}

#myProfile .title h1 {
  font-size: 16px;
  font-weight: 500;
  color: #3e3d53;
}

#myProfile .title div {
  margin: 12px 0 0;
}

#myProfile .title div #darkGrey {
  /* width: 132px; */
  width: auto;
  padding: 11px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

#myProfile .content {
  width: calc(100% - 180px);
  flex-wrap: wrap;
  gap: 30px;
}
#myProfile .aiInfo .content {
  gap: 0px;
}
#myProfile .content .tabBox {
  gap: 12px;
  margin: 0;
}
#myProfile .content .tabBox div {
  margin: 0;
}

#myProfile .userInfo .content .box,
#myProfile .myInfo .content .box,
#myProfile .myInfo .content .box .infoBox .box2,
#myProfile .myInfo .content .box .infoBoxUpdate .box2,
#myProfile .myInfo .content > .info,
#myProfile .sellerInfo .content .info,
#myProfile .payInfo .content .info {
  /* margin: 0 0 26px; */
  align-items: flex-start;
}

#myProfile .myInfo .content .box .infoBox .box2:first-child {
  margin: 0 0 45px;
}

#myProfile .userInfo .content .box .info,
#myProfile .myInfo .content .box .infoBox .box2 .info,
#myProfile .myInfo .content .box .infoBoxUpdate .box2 .info {
  width: calc(50% - 20px);
}

#myProfile .userInfo .content .box .info h1,
#myProfile .myInfo .content .box .thumb h1,
#myProfile .myInfo .content .box .thumbUpdate h1,
#myProfile .myInfo .content .box .infoBox .box2 .info h1,
#myProfile .myInfo .content .info h1,
#myProfile .sellerInfo .content .info h1,
#myProfile .payInfo .content .info h1,
#myProfile .ai .content .box p {
  font-size: 12px;
  color: #464548;
}

#myProfile .userInfo .content .box .info > div > p {
  margin: 0 0 9px;
}

#myProfile .userInfo .content .box .info > div > p,
#myProfile .ai .content .box p span,
#myProfile .myInfo .content .info p,
#myProfile .myInfo .content .info h1 span,
#myProfile .userInfo .content .box .info h1 span {
  font-size: 12px;
  color: #0d88d4;
}

#myProfile .content .box .info > p,
#myProfile .content .box .infoBox .box2 .info p,
#myProfile .content .info p {
  /* font-size: 13px; */
  color: #000000;
  font-weight: 500;
}

#myProfile .userInfo .content .box .info input,
#myProfile .myInfo .content .box .infoBoxUpdate .box2 .info input,
#myProfile .sellerInfo .content .info input,
#myProfile .payInfo .content .info input,
#myProfile .myInfo .content .info input,
#myProfile .myInfo .content .info textarea {
  width: 100%;
  height: 40px;
  background: #f7f7f8;
  border: 1px solid #e2dfe4;
  border-radius: 4px;
  outline: none;
  padding: 0 10px;
  box-sizing: border-box;
  resize: none;
  font-size: 14px;
}

#myProfile .myInfo .content .info textarea {
  padding: 10px;
  height: 100px;
}

#myProfile .myInfo .content .info:nth-child(3) input {
  margin: 0 0 30px;
}

#myProfile .userInfo .content .box .info input::placeholder,
#myProfile .myInfo .content .box .infoBox .box2 .info input::placeholder,
#myProfile .sellerInfo .content .info input::placeholder,
#myProfile .payInfo .content .info input::placeholder,
#myProfile .myInfo .content .info input::placeholder,
#myProfile .myInfo .content .info textarea::placeholder {
  color: #a3a1a7;
}

#myProfile .userInfo .content .box .info input:disabled,
#myProfile .myInfo .content .box .infoBox .box2 .info input:disabled,
#myProfile .sellerInfo .content .info input:disabled,
#myProfile .myInfo .content .info input:disabled,
#myProfile .payInfo .content .info input:disabled,
#myProfile .myInfo .content .info textarea:disabled {
  background: #e9ecf1;
  border: 1px solid #e2dfe4;
  color: b-inputD;
}

#myProfile .userInfo .content .box .info input:focus,
#myProfile .myInfo .content .box .infoBox .box2 .info input:focus,
#myProfile .sellerInfo .content .info input:focus,
#myProfile .myInfo .content .info input:focus,
#myProfile .payInfo .content .info input:focus,
#myProfile .myInfo .content .info textarea:focus {
  border: 1px solid #b041f3;
  background: #ffffff;
}

#myProfile .userInfo .content .box .info #darkGrey {
  width: 105px;
  height: 33px;
  border-radius: 4px;
  font-size: 14px;
}

#myProfile .userInfo .content .box .info > .rowBox {
  position: relative;
  gap: 12px;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle {
  margin: 0 0 31px;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle h1 {
  font-size: 16px;
  font-weight: 700;
  color: #0e0e1f;
  margin: 0;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle p {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .absolueTitle p:hover {
  color: #e21dfd;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox .box p {
  font-size: 12px;
  color: #464548;
  margin: 0 0 9px;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox div #darkGrey {
  margin: 0 16px 0 0;
}

#myProfile .userInfo .content .box .info > .rowBox .absoluteBox div #darkGrey,
#myProfile .userInfo .content .box .info > .rowBox .absoluteBox div #purpleBtn {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

#myProfile .userInfo .content .box .info > .rowBox > input {
  width: calc(100% - 104px - 12px);
}

#myProfile .userInfo .content .box .info > .rowBox #darkGrey {
  width: 104px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

#myProfile .userInfo .content .box .info > .rowBox .ant-select {
  /* width: calc(50% - 6px); */
  height: 40px;
}

#myProfile .myInfo .content .box .thumb {
  width: 140px;
}

#myProfile .myInfo .content .box .thumb img {
  width: 100%;
  height: 140px;
  border-radius: 16px;
}

#myProfile .myInfo .content .box .thumbUpdate {
  width: 317px;
}

#myProfile .myInfo .content .box .thumbUpdate img {
  width: 140px;
  height: 140px;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox {
  width: 140px;
  height: 140px;
  border-radius: 4px;
  border: 1px dashed #e21dfd;
  background: #fcf7fd;
  cursor: pointer;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox .plus {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #f0dbf4;
  color: #c49bcc;
  font-size: 22px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  margin: 0 0 4px;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox:hover .plus {
  background: #c49bcc;
  color: #f0dbf4;
}

#myProfile .myInfo .content .box .thumbUpdate .plusBox p {
  text-align: center;
  font-size: 12px;
  color: #c49bcc;
  line-height: 1.2;
}

#myProfile .myInfo .content .box .infoBoxUpdate {
  width: calc(100% - 317px);
  height: 100%;
  justify-content: space-between;
  padding: 0 0 0 48px;
  align-items: flex-start;
}

#myProfile .myInfo .content .box .infoBox {
  width: calc(100% - 140px);
  height: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: flex-start;
}

#myProfile .myInfo .content .info img {
  width: 100%;
}

#myProfile .myInfo .content .info .plusBannerBox {
  border: 1px dashed #e21dfd;
  background: #fcf7fd;
  height: 219px;
  border-radius: 4px;
  cursor: pointer;
}

#myProfile .myInfo .content .info .plusBannerBox .plus {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #f0dbf4;
  color: #c49bcc;
  font-size: 22px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  margin: 0 0 4px;
}

#myProfile .myInfo .content .info .plusBannerBox:hover .plus {
  background: #c49bcc;
  color: #f0dbf4;
}

#myProfile .myInfo .content .info .plusBannerBox p {
  text-align: center;
  font-size: 12px;
  color: #c49bcc;
  line-height: 1.2;
}

#myProfile .myInfo .content .info h2 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0 0 12px;
}

#myProfile .myInfo .content .info h3 {
  font-size: 13px;
  color: #000000;
}

#myProfile .ai .content .ant-select-selector {
  background: #f7f7f8;
  border: 1px solid #dfdce4;
}

#myProfile .ai .content > .tabBox {
  width: auto;
  gap: 12px;
  /* margin: 0 12px; */
}

#myProfile .ai .content .tabBox .aiTab {
  width: auto;
  height: auto;
  padding: 5px 12px;
  border: 1px solid #cccccc;
  border-radius: 30px;
  margin: 0;
  gap: 12px;
}

#myProfile .ai .content .tabBox .aiTab h1 {
  /* font-size: 13px; */
  color: #0e0e1f;
  margin: 0;
  gap: 8px;
}

#myProfile .ai .content .tabBox .aiTab p {
  font-size: 13px;
  color: #0e0e1f;
}

#myProfile .aiInfo .content .ant-checkbox,
#myProfile .aiInfo .content .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

#myProfile .aiInfo .content .ant-checkbox + span img {
  width: 21px;
  margin: 0 0 -6px;
}

#myProfile .aiInfo .content > p {
  font-size: 12px;
  color: #464548;
  margin: 0 0 10px;
}

#myProfile .aiInfo .content p span {
  color: #0d88d4;
}

#myProfile .aiInfo .content .tabBox .tab {
  width: auto;
  padding: 8px 12px;
  border: 1px solid #cccccc;
  font-size: 13px;
  color: #0e0e1f;
  font-weight: 600;
  border-radius: 20px;
  margin: 0;
  line-height: 1;
  height: 34px;
}

#myProfile .btnBox #logoutBtn,
#myProfile .btnBox #darkGrey,
#myProfile .btnBox #purpleBtn {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 14px 8px 0;
}
#myProfile .btnBox #logoutBtn {
  border-radius: 4px;
  border: 1px solid var(--t-groupTitleD, #6d6c75);
}
#myProfile .secession {
  margin: 34px 0 0;
}

#myProfile .secession .title #logoutBtn {
  width: 125px;
  height: 34px;
  border-radius: 24px;
}

#myProfile .secession .title #logoutBtn img {
  width: 18px;
  margin: 0 10px 0 0;
}

/* ///////////// */
/* ///////////// */
/* SELLER MODAL */
/* ///////////// */
/* ///////////// */
.sellerModal .title {
  margin: 0 0 37px;
}

.sellerModal .title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #0e0e1f;
}

.sellerModal .title p {
  font-size: 24px;
}

.sellerModal .check {
  margin: 0 0 17px;
}

.sellerModal .check p {
  font-size: 13px;
  color: #000000;
  margin: 0 0 -3px 20px;
  border-bottom: 1px solid #000000;
  line-height: 1;
}

.sellerModal .scroll {
  max-height: 600px;
  overflow-y: auto;
  justify-content: flex-start;
}

.sellerModal .scroll .boxWrapper1,
.sellerModal .scroll .boxWrapper2,
.sellerModal .scroll .boxWrapper3,
.sellerModal .scroll .boxWrapper4 {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 0 24px;
}

.sellerModal .scroll .boxWrapper1 .box p,
.sellerModal .scroll .boxWrapper2 .box p,
.sellerModal .scroll .boxWrapper3 .box p,
.sellerModal .scroll .boxWrapper4 .box p {
  font-size: 12px;
  color: #0e0e1f;
  margin: 0 0 9px;
}

.sellerModal .scroll .boxWrapper1 .box:first-child {
  width: 200px;
}

.sellerModal .scroll .boxWrapper1 .box:last-child {
  width: calc(100% - 200px - 40px);
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab {
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  border-radius: 4px;
  padding: 4px;
  height: 40px;
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab div {
  width: calc(50% - 2px);
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab div:hover {
  background: #ffffff;
  color: #464548;
}

.sellerModal .scroll .boxWrapper1 .box:first-child .tab div#active {
  background: #ffffff;
  color: #464548;
}

.sellerModal .scroll .boxWrapper1 .box:last-child #darkGrey {
  width: 88px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

.sellerModal .scroll .boxWrapper1 .box:last-child input {
  width: calc(100% - 88px - 12px);
}

.sellerModal .scroll .boxWrapper1 .box input,
.sellerModal .scroll .boxWrapper2 .box input,
.sellerModal .scroll .boxWrapper3 .box input,
.sellerModal .scroll .boxWrapper4 .box input {
  height: 40px;
  outline: none;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 13px;
}

.sellerModal .scroll .boxWrapper1 .box input::placeholder,
.sellerModal .scroll .boxWrapper2 .box input::placeholder,
.sellerModal .scroll .boxWrapper3 .box input::placeholder,
.sellerModal .scroll .boxWrapper4 .box input::placeholder {
  color: #a3a1a7;
}

.sellerModal .scroll .boxWrapper1 .box input:disabled,
.sellerModal .scroll .boxWrapper2 .box input:disabled,
.sellerModal .scroll .boxWrapper3 .box input:disabled,
.sellerModal .scroll .boxWrapper4 .box input:disabled {
  border: 1px solid #e2dfe4;
  background: #e9ecf1;
  color: #524f56;
}

.sellerModal .scroll .boxWrapper2 > div:first-child {
  padding: 0 0 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  margin: 0 0 16px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box {
  width: 200px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box input {
  width: 100%;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child {
  width: 224px;
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child input {
  width: calc(100% - 88px - 12px);
}

.sellerModal .scroll .boxWrapper2 div:first-child .box:last-child #darkGrey {
  width: 88px;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child {
  width: 200px;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child {
  width: calc(100% - 200px - 40px);
}

.sellerModal .scroll .boxWrapper2 > div:not(:last-child) {
  margin: 0 0 17px;
}

.sellerModal .scroll .boxWrapper2 div .box input {
  width: 100%;
}

.sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
.sellerModal .scroll .boxWrapper2 div:nth-child(4) .box {
  width: calc(100% / 2 - 20px);
}

.sellerModal .scroll .boxWrapper3 > div:first-child,
.sellerModal .scroll .boxWrapper4 > div:first-child {
  margin: 0 0 17px;
}

.sellerModal .scroll .boxWrapper3 div:first-child .box,
.sellerModal .scroll .boxWrapper4 div:first-child .box {
  width: 200px;
}

.sellerModal .scroll .boxWrapper3 div .box input,
.sellerModal .scroll .boxWrapper4 div .box input {
  width: 100%;
}

.sellerModal .scroll .boxWrapper4 div:first-child .ant-select {
  width: 200px;
  height: 40px;
}

.sellerModal .scroll .boxWrapper4 div:last-child input {
  width: 212px;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content > p {
  font-size: 13px;
  margin: 0 16px;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .inputRound {
  width: 40px;
  border-radius: 100%;
  margin: 0 16px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .round {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #000000;
  margin: 0 16px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content .round:last-child {
  margin: 0 44px 0 0;
}

.sellerModal .scroll .boxWrapper4 div:last-child .box .content #darkGrey {
  width: 88px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

.sellerModal .scroll .btnBox #purpleBtn,
.sellerModal .scroll .btnBox #logoutBtn {
  width: 100px;
  height: 40px;
  margin: 0 5px;
  font-size: 14px;
  border-radius: 4px;
}

/* //////// */
/* //////// */
/* AIMODAL */
/* //////// */
/* //////// */

.aiModal .title {
  margin: 0 0 37px;
}

.aiModal .title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #0e0e1f;
}

.aiModal .title p {
  font-size: 24px;
}

.aiModal .top {
  padding: 12px;
  border-radius: 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
  align-items: flex-end;
  margin: 0 0 28px;
}

.aiModal .top .box {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c2b8cb;
  height: 188px;
  width: calc(100% - 120px - 24px);
  background: #ffffff;
}

.aiModal .top .box .thumb {
  width: 123px;
}

.aiModal .top .box .thumb p {
  font-size: 12px;
  font-weight: 700;
  color: #464548;
  margin: 0 0 13px;
}

.aiModal .top .box .thumb .plusBox {
  width: 100%;
  height: 123px;
  border-radius: 4px;
  background: #f3f3f3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.aiModal .top .box .thumb .plusBox .plus {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.1);
  font-size: 20px;
  color: #ffffff;
}

.aiModal .top .box .thumb .plusBox:hover {
  background: #f0dbf4;
}

.aiModal .top .box > div {
  width: calc(100% - 123px - 20px);
}

.aiModal .top .box > div .infoBox:not(:last-child) {
  width: 100%;
  margin: 0 0 12px;
}

.aiModal .top .box > div .infoBox p {
  font-size: 12px;
  font-weight: 700;
  color: #464548;
}

.aiModal .top .box > div .infoBox .ant-select {
  width: 300px;
  height: 40px;
}

.aiModal .top .box > div .infoBox input {
  height: 40px;
  width: 300px;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  outline: none;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 13px;
}

.aiModal .top .box > div .infoBox input::placeholder {
  font-size: 13px;
  color: #a3a1a7;
}

.aiModal .top #purpleBtn {
  width: 120px;
  height: 40px;
  font-size: 14px;
  border-radius: 24px;
}

.aiModal .filter {
  margin: 0 0 16px;
}

.aiModal .filter .ant-select {
  border-radius: 4px;
  width: 120px;
  height: 40px;
}

.aiModal .filter > div {
  width: auto;
}

.aiModal .filter > div input {
  width: 140px;
  height: 40px;
  border: 1px solid #e2dfe4;
  background: #f7f7f8;
  border-radius: 4px;
  padding: 8px 12px;
  outline: none;
  font-size: 13px;
}

.aiModal .filter > div p {
  font-size: 13px;
  margin: 0 12px;
}

.aiModal .filter > div #purpleBtn {
  width: 40px;
  height: 40px;
  margin: 0 0 0 12px;
  font-size: 20px;
  border-radius: 4px;
}

.aiModal .contentTop {
  border-top: 2px solid #000000;
  background: #f3f2fa;
  height: 48px;
}

.aiModal .contentTop div {
  font-size: 14px;
  color: #323146;
  font-weight: 500;
}

.aiModal .scroll {
  max-height: 350px;
  justify-content: flex-start;
  overflow: auto;
}

.aiModal .contentTop div:nth-child(1),
.aiModal .scroll .content div:nth-child(1) {
  width: 180px;
}

.aiModal .contentTop div:nth-child(2),
.aiModal .contentTop div:nth-child(4),
.aiModal .scroll .content div:nth-child(2),
.aiModal .scroll .content div:nth-child(4) {
  width: 120px;
}

.aiModal .scroll .content div:nth-child(4) .tab {
  padding: 4px 8px;
  font-size: 13px;
  color: #ffffff;
  width: auto;
  border-radius: 50px;
}

.aiModal .scroll .content div:nth-child(4) .tab.success {
  background: #525162;
}

.aiModal .scroll .content div:nth-child(4) .tab.accept {
  background: #0fc9f3;
}

.aiModal .scroll .content div:nth-child(4) .tab.ing {
  background: #f8c930;
}

.aiModal .scroll .content div:nth-child(4) .tab.fail {
  background: #fe393c;
}

.aiModal .contentTop div:nth-child(3),
.aiModal .scroll .content div:nth-child(3) {
  width: 140px;
}

.aiModal .scroll .content div:nth-child(3) img {
  width: 80%;
}

.aiModal .contentTop div:nth-child(6),
.aiModal .scroll .content div:nth-child(6) {
  width: 80px;
}

.aiModal .scroll .content div:nth-child(6) img {
  width: 20px;
}

.aiModal .contentTop div:nth-child(5),
.aiModal .scroll .content div:nth-child(5) {
  width: calc(100% - 180px - 120px - 120px - 140px - 80px);
}

.aiModal .scroll .content {
  border-bottom: 1px solid #e2dfe4;
  padding: 8px 0;
}

.aiModal .scroll .content div:nth-child(5) {
  font-size: 16px;
  color: #464548;
}

.aiModal .scroll .content div {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

@media (max-width: 800px) {
  #myProfile .userInfo,
  #myProfile .myInfo,
  #myProfile .payInfo {
    flex-direction: column;
    margin: 0 0 30px;
    padding: 0;
  }

  #myProfile .sellerInfo,
  #myProfile .ai,
  #myProfile .aiInfo {
    padding: 0 0 30px;
    width: 100%;
    flex-direction: column;
    margin: 0 0 30px;
  }

  #myProfile .userInfo .title,
  #myProfile .myInfo .title,
  #myProfile .ai .title,
  #myProfile .aiInfo .title,
  #myProfile .sellerInfo .title,
  #myProfile .payInfo .title,
  #myProfile .secession title {
    width: 100%;
    margin: 0 0 30px;
  }

  #myProfile .userInfo .content,
  #myProfile .myInfo .content,
  #myProfile .ai .content,
  #myProfile .aiInfo .content,
  #myProfile .sellerInfo .content,
  #myProfile .payInfo .content {
    width: 100%;
    flex-wrap: wrap;
  }

  #myProfile .userInfo .content .box,
  #myProfile .myInfo .content .box,
  #myProfile .myInfo .content .box .infoBox .box2,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2,
  #myProfile .sellerInfo .content .info,
  #myProfile .payInfo .content .info {
    flex-direction: column;
    margin: 0;
  }

  #myProfile .userInfo .content .box:nth-child(3) {
    flex-direction: row;
  }

  #myProfile .userInfo .content .box #logoutBtn {
    width: 80px;
    height: 30px;
    border-radius: 4px;
    font-size: 12px;
  }

  #myProfile .userInfo .content .box .info,
  #myProfile .myInfo .content .box .infoBox .box2 .info,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2:not(:last-child) .info,
  #myProfile .myInfo .content .box .infoBoxUpdate {
    width: 100%;
    margin: 0 0 30px;
    padding: 0;
  }

  #myProfile .myInfo .content .box .thumb {
    width: 100%;
    margin: 0 0 30px;
  }

  #myProfile .myInfo .content .box .thumb img {
    width: 140px;
    height: 140px;
    border-radius: 16px;
  }

  #myProfile .myInfo .content .info .plusBannerBox {
    height: 110px;
  }

  #myProfile .myInfo .content .info img {
    height: 138px;
  }

  #myProfile .myInfo .content .box .infoBox {
    padding: 0;
    width: 100%;
  }

  #myProfile .myInfo .content .box .infoBox .box2:first-child {
    margin: 0;
  }

  #myProfile .myInfo .content .box .infoBox .box2:last-child,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2:last-child {
    flex-direction: row;
  }

  #myProfile .userInfo .content .box .info input,
  #myProfile .myInfo .content .box .infoBoxUpdate .box2 .info input,
  #myProfile .sellerInfo .content .info input,
  #myProfile .payInfo .content .info input,
  #myProfile .myInfo .content .info input,
  #myProfile .myInfo .content .info textarea {
    font-size: 13px;
  }

  #myProfile .userInfo .content .box .info > .rowBox {
    flex-wrap: wrap;
  }

  #myProfile .userInfo .content .box .info:first-child > .rowBox .ant-select {
    width: 86px;
  }

  #myProfile .userInfo .content .box .info:first-child > .rowBox > .mobileInput {
    width: calc(100% - 86px - 10px);
  }

  #myProfile .userInfo .content .box .info > .rowBox #darkGrey.mobileBtn {
    margin: 7px 0 0;
    width: 100%;
  }

  #myProfile .userInfo .content .box:nth-child(3)#update {
    flex-direction: column;
  }

  #myProfile .ai .content .box p span,
  #myProfile .myInfo .content .info p,
  #myProfile .myInfo .content .info h1 span,
  #myProfile .userInfo .content .box .info h1 span {
    font-size: 10px;
  }

  #myProfile .myInfo .content .box .thumbUpdate {
    width: auto;
    margin: 0 0 30px;
  }

  #myProfile .myInfo .content .box .thumbUpdate img {
    margin: 0 10px 0 0;
  }

  #myProfile .myInfo .content .box .infoBoxUpdate .box2 .info {
    width: calc(50% - 5px);
  }

  #myProfile .myInfo .content .info:nth-child(3) input {
    margin: 0 0 5px;
  }

  #myProfile .myInfo .content .info textarea {
    height: 40px;
  }

  #myProfile .ai .content > .tabBox {
    width: 100%;
    flex-wrap: wrap;
    margin: 10px 0 0;
  }

  #myProfile .aiInfo #darkGrey {
    width: 100%;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
    margin: 30px 0 0;
  }

  #myProfile .secession {
    margin: 0 0 30px;
  }

  #myProfile .btnBox {
    justify-content: space-between;
  }

  #myProfile .btnBox #logoutBtn,
  #myProfile .btnBox #darkGrey,
  #myProfile .btnBox #purpleBtn {
    margin: 0;
    width: calc(100% / 3 - 10px);
  }

  #myProfile .btnBox#update {
    justify-content: space-between;
  }

  #myProfile .btnBox#update #logoutBtn,
  #myProfile .btnBox#update #purpleBtn {
    width: calc(50% - 8px);
  }

  .mobilePwModal .title {
    margin: 0 0 30px;
  }

  .mobilePwModal .title h1 {
    color: #0e0e1f;
    font-size: 20px;
    font-weight: 700;
  }

  .mobilePwModal .title p {
    font-size: 24px;
  }

  .mobilePwModal .box p {
    color: #464548;
    font-size: 12px;
    margin: 0 0 5px;
  }

  .mobilePwModal .box input {
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    height: 38px;
    width: 100%;
    margin: 0 0 20px;
    padding: 0 10px;
    border-radius: 4px;
  }

  .mobilePwModal .box input::placeholder {
    color: #a3a1a7;
  }

  .mobilePwModal div #darkGrey,
  .mobilePwModal div #purpleBtn {
    width: calc(50% - 10px);
    height: 40px;
    border-radius: 4px;
  }

  .sellerModal .scroll {
    max-height: none;
  }

  .sellerModal .check {
    justify-content: space-between;
  }

  .sellerModal .scroll .boxWrapper2 > div,
  .sellerModal .scroll .boxWrapper2 > div:first-child,
  .sellerModal .scroll .boxWrapper1,
  .sellerModal .scroll .boxWrapper2,
  .sellerModal .scroll .boxWrapper3,
  .sellerModal .scroll .boxWrapper4 {
    flex-direction: column;
    align-items: flex-start;
  }

  .sellerModal .scroll .boxWrapper3 > div:first-child,
  .sellerModal .scroll .boxWrapper4 > div:first-child {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .sellerModal .scroll .boxWrapper4 > div:first-child input,
  .sellerModal .scroll .boxWrapper4 div:first-child .ant-select,
  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
  .sellerModal .scroll .boxWrapper2 div:nth-child(4) .box,
  .sellerModal .scroll .boxWrapper1 .box:last-child,
  .sellerModal .scroll .boxWrapper2 div:first-child .box:last-child {
    width: 100%;
  }

  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:last-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(2) .box:first-child,
  .sellerModal .scroll .boxWrapper2 div:nth-child(3) .box,
  .sellerModal .scroll .boxWrapper2 div:nth-child(4) .box,
  .sellerModal .scroll .boxWrapper1 .box {
    margin: 0 0 11px;
  }

  .sellerModal .scroll .boxWrapper2 > div:not(:last-child),
  .sellerModal .scroll .boxWrapper1 .box:last-child {
    margin: 0 0 10px;
  }

  .sellerModal .scroll .boxWrapper3 div:first-child .box,
  .sellerModal .scroll .boxWrapper4 div:first-child .box,
  .sellerModal .scroll .boxWrapper2 div:first-child .box {
    width: 100%;
    margin: 0 0 10px;
  }

  .sellerModal .scroll .boxWrapper2 div:first-child .box:last-child input {
    width: calc(100% - 88px - 12px);
  }

  .sellerModal .scroll .boxWrapper4 > div:last-child > div > div {
    flex-wrap: wrap;
  }

  .sellerModal .scroll .boxWrapper4 div:last-child .box .content .inputRound {
    margin: 0 5px 0 0;
  }

  .sellerModal .scroll .boxWrapper4 div:last-child .box .content .round {
    width: 10px;
    height: 10px;
    margin: 0 5px 0 0;
  }

  .sellerModal .scroll .boxWrapper4 > div:last-child > div > div input {
    width: 40%;
  }

  .sellerModal .scroll .boxWrapper4 div:last-child .box .content #darkGrey {
    width: 100%;
    margin: 20px 0 0;
  }

  .sellerModal .scroll .btnBox {
    justify-content: space-between;
  }

  .sellerModal .scroll .btnBox #purpleBtn,
  .sellerModal .scroll .btnBox #logoutBtn {
    width: calc(50% - 8px);
  }
}

#plan .rsWrapper > .top > h1 {
  font-size: 16px;
  font-weight: 700;
  color: #3e3d53;
  margin: 0 0 10px;
}

#plan .rsWrapper .top .box {
  border: 1px solid #dbdbe0;
  padding: 20px 0;
  border-radius: 8px;
  align-items: flex-start;
  height: 116px;
  margin: 0 0 65px;
}

#plan .rsWrapper .top .box > div {
  padding: 0 20px;
}

#plan .rsWrapper .top .box > div h2 {
  font-size: 12px;
  font-weight: 700;
  color: #464548;
  margin: 0 0 20px;
}

#plan .rsWrapper .top .box > div h2 span {
  color: #fe393c;
}

#plan .rsWrapper .top .box > div:not(:last-child) {
  border-right: 1px solid #e0dbe4;
}

#plan .rsWrapper .top .box > div:nth-child(1),
#plan .rsWrapper .top .box > div:nth-child(2) {
  width: calc(100% / 2);
  height: 100%;
}

#plan .rsWrapper .top .box > div:nth-child(3),
#plan .rsWrapper .top .box > div:nth-child(4) {
  width: calc((100% / 3) / 2);
  height: 100%;
}

#plan .rsWrapper .top .box > div:first-child > div > h1 {
  font-size: 28px;
  font-weight: 600;
  color: #000000;
}

#plan .rsWrapper .top .box > div:first-child > div img {
  width: 30px;
  margin: 0 0 0 70px;
}

#plan .rsWrapper > .box > div:first-child p,
#plan .rsWrapper .top .box > div:nth-child(4) > div > div > p,
#plan .rsWrapper .top .box > div > p {
  font-size: 12px;
  color: #0d88d4;
}

#plan .rsWrapper .top .box > div:nth-child(2) > div:first-child {
  margin: 0 0 20px;
}

#plan .rsWrapper .top .box > div:nth-child(2) div .coupon {
  width: auto;
  border-radius: 50px;
  padding: 4px 8px;
  border: 1px solid #fe393c;
  color: #f34144;
  font-size: 11px;
  margin: 0 0 0 4px;
}

#plan .rsWrapper .top .box > div:nth-child(2) > div h2 {
  margin: 0;
}

#plan .rsWrapper .top .box > div:nth-child(2) > div h1,
#plan .rsWrapper .top .box > div:nth-child(3) > div h1,
#plan .rsWrapper .top .box > div:nth-child(4) > div > div > h1 {
  font-size: 20px;
  color: #000000;
}

#plan .rsWrapper .top .box > div:nth-child(4) > div > div {
  width: auto;
}

#plan .rsWrapper .top .box > div:nth-child(4) > div #pinkBtn {
  width: 104px;
  height: 40px;
  border-radius: 24px;
  font-size: 14px;
  padding: 0 12px;
  line-height: 1;
}

#plan .rsWrapper .top .box > div:nth-child(4) > div #pinkBtn svg {
  margin: 0 5px 0 0;
}

#plan .rsWrapper .plan {
  margin: 0 0 16px;
}

#plan .rsWrapper .plan .ant-switch-inner-checked {
  height: 36px;
  line-height: 36px;
}

#plan .rsWrapper .plan .ant-switch-inner-unchecked {
  height: 10px;
  line-height: 10px;
}

#plan .rsWrapper .plan .ant-switch.ant-switch-checked {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
}

#plan .rsWrapper .plan .ant-switch {
  background: #0d88d4;
  margin: 0 16px 0 0;
}

#plan .rsWrapper .plan .ant-switch .ant-switch-handle {
  width: 26px;
  height: 26px;
  inset-inline-start: 4px;
  top: 4px;
}

#plan .rsWrapper .plan .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 30px) !important;
}

#plan .rsWrapper .plan .ant-switch .ant-switch-handle::before {
  border-radius: 100%;
}

#plan .rsWrapper .plan .switch {
  width: 80px;
  height: 36px;
  border-radius: 50px;
  padding: 4px 6px;
  margin: 0 16px 0 0;
}

#plan .rsWrapper .plan .switch .round {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: #ffffff;
}

#plan .rsWrapper .plan .switch p {
  font-size: 14px;
  color: #ffffff;
}

#plan .rsWrapper .plan h1 {
  font-size: 24px;
  font-weight: 700;
  color: #323146;
}

#plan .rsWrapper > p {
  font-size: 13px;
  font-weight: 700;
  color: #323146;
  margin: 0 0 50px;
}

#plan .rsWrapper > p > span {
  color: #c62cff;
}

#plan .rsWrapper .contentBox .box {
  width: 388px;
  padding: 24px;
  margin: 0 56px;
  border-radius: 40px;
  height: 562px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid #b041f3;
  }
}

#plan .rsWrapper .contentBox .box#active {
  border: 1px solid #b041f3;
}

#plan .rsWrapper .contentBox .box > div h1.planFlag {
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 20px;
  background-image: linear-gradient(to right, #b041f3 0%, #ec15ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#plan .rsWrapper .contentBox .box > div h1 {
  font-size: 36px;
  color: #3e3d53;
  font-weight: 600;
  margin: 0 0 20px;
  letter-spacing: -1px;
}

#plan .rsWrapper .contentBox .box > div h1 span,
#plan .rsWrapper .contentBox .box > div > div.planFlag > p {
  font-size: 16px;
  color: #524f56;
}

#plan .rsWrapper .contentBox .box > div .type {
  padding: 0 0 20px;
  border-bottom: 1px dashed #524f56;
}

#plan .rsWrapper .contentBox .box > div .type p {
  font-size: 20px;
  color: #3e3d53;
  font-weight: 700;
}

#plan .rsWrapper .contentBox .box > div .type p span {
  font-weight: 500;
}

#plan .rsWrapper .contentBox .box > div .type > div {
  width: 50px;
  height: 30px;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 0 12px;
  border-radius: 20px;
}

#plan .rsWrapper .contentBox .box > div .type > div.beginner {
  background: #6d6c75;
}

#plan .rsWrapper .contentBox .box > div .type > div.basic {
  background: #f8ab30;
}

#plan .rsWrapper .contentBox .box > div .type > div.pro {
  background: linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  position: relative;
}

#plan .rsWrapper .contentBox .box > div .type > div.pro::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 20px;
  height: 32px;
  width: 52px;
  background: linear-gradient(to right, #13d5df 0%, #f138fc 100%);
  z-index: -1;
}

#plan .rsWrapper .contentBox .box > div > p {
  font-size: 12px;
  color: #464548;
  margin: 20px 0 17px;
}

#plan .rsWrapper .contentBox .box > div .checkBox {
  margin: 0 0 16px;
}

#plan .rsWrapper .contentBox .box > div .checkBox .check {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #2687e9;
  font-size: 10px;
  color: #ffffff;
  margin: 0 10px 0 0;
}

#plan .rsWrapper .contentBox .box > div .checkBox p {
  font-size: 14px;
}

#plan .rsWrapper .contentBox .box #greyBtn6,
#plan .rsWrapper .contentBox .box #pinkBtn3 {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
}

#plan .rsWrapper .contentBox .box .btn {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.03);
  color: #4a4b50;
}

/* MODAL */
.dataModal .title {
  margin: 0 0 20px;
}

.dataModal .title h1 {
  font-size: 24px;
  color: #0e0e1f;
  font-weight: 700;
}

.dataModal .title p {
  font-size: 24px;
}

.dataModal .boxWrapper {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px 0;
  margin: 0 0 24px;
}

.dataModal .boxWrapper .box {
  width: 210px;
  height: 226px;
  padding: 20px;
}

.dataModal .boxWrapper .box div h1 {
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 8px;
}

.dataModal .boxWrapper .box div h1 span {
  font-size: 16px;
  font-weight: 400;
}

.dataModal .boxWrapper .box div h2 {
  font-size: 24px;
  color: #3e3d53;
  font-weight: 700;
}

.dataModal .boxWrapper .box#active {
  border: 1px solid #b041f3;
  border-radius: 40px;
  padding: 24px;
}

.dataModal .boxWrapper .box #greyBtn3,
.dataModal .boxWrapper .box #purpleBtn {
  width: 100%;
  height: 50px;
  border-radius: 24px;
}

.dataModal .boxWrapper .box .btn {
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 16px;
  font-weight: 700;
  color: #4a4b50;
  border-radius: 24px;
}

.btnBox #darkGreyBorder2,
.btnBox #purpleBtn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
}

#plan .rsWrapper .btnBox {
  display: none;
}

@media (max-width: 1500px) {
  #plan .rsWrapper .top .box > div:nth-child(4) > div #pinkBtn {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  #plan .rsWrapper .top .box {
    flex-wrap: wrap;
    height: auto;
    padding: 0 20px;
  }

  #plan .rsWrapper .top .box > div:nth-child(1),
  #plan .rsWrapper .top .box > div:nth-child(2) {
    width: 100%;
    padding: 20px 0;
  }

  #plan .rsWrapper .top .box > div:nth-child(3),
  #plan .rsWrapper .top .box > div:nth-child(4) {
    width: 50%;
    margin: 20px 0;
    padding: 0;
  }

  #plan .rsWrapper .top .box > div:nth-child(4) {
    padding: 0 0 0 10px;
  }

  #plan .rsWrapper .top .box > div:nth-child(3) {
    border-right: 1px solid #e0dbe4 !important;
    border-bottom: none !important;
  }

  #plan .rsWrapper .top .box > div:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #e0dbe4;
  }

  #plan .rsWrapper .top .box > div:nth-child(4) > div > div {
    width: auto;
  }

  #plan .rsWrapper .top .box > div:nth-child(4) > div #pinkBtn {
    font-size: 12px;
    padding: 0 8px;
    width: auto;
    height: 30px;
  }

  #plan .rsWrapper .contentBox {
    flex-wrap: wrap;
  }

  #plan .rsWrapper .contentBox > .box {
    border: 1px solid #cccccc;
    margin: 0;
    width: 100%;
    margin: 0 0 30px;
    height: auto;
  }

  #plan .rsWrapper .title {
    margin: 0 0 10px;
  }

  #plan .rsWrapper .title h2 {
    font-size: 16px;
    color: #3e3d53;
    font-weight: 700;
  }

  #plan .rsWrapper .title p {
    color: #686275;
    width: 10px;
  }

  #plan .rsWrapper .box {
    border: 1px solid #dbdbe0;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 0 56px;
  }

  #plan .rsWrapper .box h2 {
    font-size: 12px;
    color: #464548;
    font-weight: 700;
    margin: 0 0 5px;
  }

  #plan .rsWrapper .box h2 span {
    color: #f34144;
    font-size: 11px;
  }

  #plan .rsWrapper .box div h1 {
    font-size: 20px;
    color: #000000;
    margin: 0 0 3px;
    font-weight: 600;
  }

  #plan .rsWrapper .box > div {
    padding: 20px 0;
  }

  #plan .rsWrapper > .box > div:first-child {
    border-bottom: solid 1px #e0dbe4;
  }

  #plan .rsWrapper .box > div:last-child > div:first-child {
    border-right: 1px solid #e0dbe4;
  }

  #plan .rsWrapper > .box > div:last-child > div:last-child {
    padding: 0 0 0 10px;
  }

  .dataModal .boxWrapper {
    padding: 0;
    border: none;
    flex-wrap: wrap;
  }

  .dataModal .boxWrapper .box {
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 30px;
    height: auto;
    margin: 0 0 20px;
  }

  .dataModal .boxWrapper .box > div:first-child {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 10px;
  }

  #plan .rsWrapper .contentBox .box > div h1.planFlag,
  .dataModal .boxWrapper .box > div h1 {
    margin: 0;
  }

  .btnBox {
    justify-content: space-between;
  }

  .btnBox #darkGreyBorder2,
  .btnBox #purpleBtn {
    width: calc(50% - 8px);
  }

  #plan .rsWrapper .top .box > div:first-child > div img {
    margin: 0 0 0 12px;
  }

  #plan .rsWrapper .top .box > div:nth-child(2) > div:first-child,
  #plan .rsWrapper .top .box > div h2 {
    margin: 0 0 5px;
  }

  #plan .rsWrapper .contentBox .box > div h1 {
    margin: 0;
  }

  #plan .rsWrapper .btnBox {
    display: flex;
  }

  #plan .rsWrapper .top .box > div:nth-child(2) > div h1,
  #plan .rsWrapper .top .box > div:nth-child(3) > div h1,
  #plan .rsWrapper .top .box > div:nth-child(4) > div > div > h1 {
    font-weight: 600;
  }
}

.plan-content > p {
  font-size: 12px;
  color: #464548;
  margin: 20px 0 17px;
}

.plan-content .checkBox {
  margin: 0 0 16px;
}

.plan-content .checkBox .check {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #2687e9;
  font-size: 10px;
  color: #ffffff;
  margin: 0 10px 0 0;
}

.plan-content .checkBox p {
  font-size: 14px;
}

#pinkBtn3 > span {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
}

#pinkBtn3 > span:hover {
  background: linear-gradient(to right, #b041f3, #ec15ff);
}

#greyBtn6 {
  width: 100%;
  height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  background: rgba(0, 0, 0, 0.03);
  color: #a29fab;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#greyBtn6:hover {
  background: #a29fab;
  color: #000000;
}
