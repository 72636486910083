.dictionary-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.dictionary-modal-content {
    position: fixed;
    background-color: white;
    padding: 20px;
    width: 100%;
    height: 100%;
}

.dictionary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
}

.dictionary-header h2 {
    font-size: 24px;
}

.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.dictionary-search {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 0;
    border-bottom: 2px solid #000;
}

.search-select {
    width: 120px;
}

.search-input {
    flex: 1;
}

.dictionary-content {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
}

.table-container,
.detail-container {
    position: absolute;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.table-wrapper {
    display: none;
}

.table-wrapper.visible {
    display: block;
}

.noSearch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 160%;
    z-index: 10;
}

.table-container.slide-out {
    transform: translateX(-100%);
}

.table-container.slide-in {
    transform: translateX(0);
}

.detail-container {
    transform: translateX(100%);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.detail-container.slide-in {
    transform: translateX(0);
}

.detail-container.slide-out {
    transform: translateX(100%);
}

.detail-section {
    margin-bottom: 20px;
    padding: 20px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards;
}

.detail-header {
    display: flex;
    align-items: center;
    padding: 40px 0;
}

.back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    transition: background-color 0.3s;
    border-radius: 4px;
    scale: 0.8;
}

.content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.detailRoot {
    flex: 1;
    text-align: center;
}

.detailRoot h2{
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 700;
}

.detailRoot p {
    font-size: 24px;
    font-weight: 600;
}

.back-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.detail-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.detail-table-header {
    display: flex;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e0e0e0;
}

.detail-table-row {
    display: flex;
}

.detail-cell {
    flex: 1;
    padding: 12px;
    text-align: center;
    border: 1px solid #e0e0e0;
    transition: background-color 0.3s;
}

.detail-cell.header {
    font-weight: 600;
    background-color: #f9f9f9;
}

.detail-description {
    margin-top: 24px;
    line-height: 1.6;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards 0.3s;
}

.detail-image-container {
    margin-top: 24px;
    text-align: center;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards 0.5s;
}

.detail-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.detail-image:hover {
    transform: scale(1.02);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.noDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 20px;
    font-size: 16px;
}

.dictionary-popup > #aui-grid-wrap-0 > div{
    width: 800px;
    height: 800px;
}