#myPage {
    padding: 0 0 50px;
}

#myPage>div>.title {
    padding: 40px 0 48px;
    overflow: auto;
}

#myPage>div>.title .overflowBox {
    min-width: 800px;
}

#myPage>div>.title .overflowBox>div {
    margin: 0 30px;
    width: auto;
    position: relative;
}

#myPage>div>.title .overflowBox div h1 {
    font-size: 32px;
    font-weight: bold;
    color: #cccccc;
    cursor: pointer;
    transition: all .2s ease-in-out;
}


#myPage>div>.title .overflowBox div h1:hover,
#myPage>div>.title .overflowBox div#active h1 {
    color: #0e0e1f;
}

#myPage>div>.title .overflowBox div .round {
    display: none;
}

#myPage>div>.title .overflowBox div#active .round {
    display: flex;
    position: absolute;
    top: -6px;
    left: -10px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fe5139;
}

#seller .tab {
    padding: 0 0 16px;
    border-bottom: solid 2px #000;
    margin: 0 0 58px;
}

#seller .tab #pinkBtn2 {
    width: 150px;
    height: 41px;
    border-radius: 24px;
    font-size: 14px;
    margin: 0 2px;
}

#seller .tab #pinkBtn2.active {
    background: linear-gradient(to right, #ca79fc 0%, #ff5eb6 100%);
    color: #ffffff;
    border: 1px solid transparent;
}

#seller .rsWrapper .title {
    margin: 0 0 60px;

    p {
        font-size: 20px;
        font-weight: 600;
        color: #000000;

        span {
            border-bottom: 2px solid #000000;
        }
    }
}

#seller .rsWrapper :where(.css-dev-only-do-not-override-apn68).ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

#seller .rsWrapper :where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper span.ant-radio+* {
    padding: 0 10px;
}

#seller .rsWrapper .titleBox {
    margin: 0 0 30px;
}

#seller .rsWrapper .titleBox .title {
    width: 220px;
}

#seller .rsWrapper .titleBox .title svg,
#seller .rsWrapper .titleBox .title img {
    width: 24px;
    margin: 0 15px 0 0;
}

#seller .rsWrapper .titleBox .title h1 {
    font-size: 24px;
    font-weight: 600;
    color: #3e3d53;

}

#seller .rsWrapper .titleBox .line {
    width: calc((100% - 140px) / 2);
    /* border: 1px dashed #e6e6e6; */
    height: 1px;
}

#seller .rsWrapper .aiInfo,
#seller .rsWrapper .accountBox,
#seller .rsWrapper .aiBox,
#seller .rsWrapper .tagBox,
#seller .rsWrapper .infoBox,
#seller .rsWrapper .sellerBox,
#seller .rsWrapper .ai,
#seller .rsWrapper .type {
    align-items: flex-start;
    padding: 30px 0;
}

#seller .rsWrapper .aiInfo .title,
#seller .rsWrapper .accountBox .title,
#seller .rsWrapper .aiBox .title,
#seller .rsWrapper .tagBox .title,
#seller .rsWrapper .infoBox .title,
#seller .rsWrapper .sellerBox .title,
#seller .rsWrapper .ai .title,
#seller .rsWrapper .type .title {
    width: 180px;
    flex-wrap: wrap;
}

#seller .rsWrapper .aiInfo .content>div>img,
#seller .rsWrapper .infoBox .content .box .info>div>img,
#seller .rsWrapper .sellerBox .content .box .info>div>img {
    width: 12px;
    margin: 0 0 9px 14px;
}

#seller .rsWrapper .type .title svg,
#seller .rsWrapper .aiInfo .title img,
#seller .rsWrapper .accountBox .title svg,
#seller .rsWrapper .aiBox .title svg,
#seller .rsWrapper .tagBox .title img,
#seller .rsWrapper .infoBox .title img,
#seller .rsWrapper .sellerBox .title svg,
#seller .rsWrapper .ai .title div img,
#seller .rsWrapper .type .title img {
    width: 20px;
    margin: 0 14px 0 0;
}

#seller .rsWrapper .ai .title div img:last-child {
    width: 12px;
}

#seller .rsWrapper .aiInfo .title h1,
#seller .rsWrapper .accountBox .title h1,
#seller .rsWrapper .aiBox .title h1,
#seller .rsWrapper .tagBox .title h1,
#seller .rsWrapper .infoBox .title h1,
#seller .rsWrapper .sellerBox .title div h1,
#seller .rsWrapper .ai .title div h1,
#seller .rsWrapper .type .title h1 {
    font-size: 16px;
    font-weight: 600;
    color: #3e3d53;
}

#seller .rsWrapper .ai .title div h1 {
    margin: 0 10px 0 0;
}

#seller .rsWrapper .aiInfo .title #darkGrey,
#seller .rsWrapper .ai .title #darkGrey {
    width: 132px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    margin: 12.5px 0 0;
}


#seller .rsWrapper .aiInfo .content,
#seller .rsWrapper .accountBox .content,
#seller .rsWrapper .aiBox .content,
#seller .rsWrapper .tagBox .content,
#seller .rsWrapper .infoBox .content,
#seller .rsWrapper .sellerBox .content,
#seller .rsWrapper .ai .content,
#seller .rsWrapper .type .content {
    width: calc(100% - 180px);
    flex-wrap: wrap;
}

#seller .rsWrapper .aiInfo .content .box,
#seller .rsWrapper .accountBox .content .box,
#seller .rsWrapper .aiBox .content .box,
#seller .rsWrapper .tagBox .content .box,
#seller .rsWrapper .infoBox .content .box,
#seller .rsWrapper .sellerBox .content .box,
#seller .rsWrapper .ai .content .box,
#seller .rsWrapper .type .content .box {
    margin: 0 0 24px;
    align-items: flex-start;
}

#seller .rsWrapper .aiInfo .content .box .info,
#seller .rsWrapper .accountBox .content .box .info,
#seller .rsWrapper .aiBox .content .box .info,
#seller .rsWrapper .tagBox .content .box .info,
#seller .rsWrapper .infoBox .content .box .info,
#seller .rsWrapper .sellerBox .content .box .info,
#seller .rsWrapper .ai .content .box .info,
#seller .rsWrapper .type .content .box .info {
    margin: 0 0 25px;
}

#seller .rsWrapper .type .content .box .info>div {
    height: 40px;
}

#seller .rsWrapper .aiInfo .content .box .info:last-child,
#seller .rsWrapper .accountBox .content .box .info:last-child,
#seller .rsWrapper .aiBox .content .box .info:last-child,
#seller .rsWrapper .tagBox .content .box .info:last-child,
#seller .rsWrapper .infoBox .content .box .info:last-child,
#seller .rsWrapper .sellerBox .content .box .info:last-child,
#seller .rsWrapper .ai .content .box .info:last-child,
#seller .rsWrapper .type .content .box .info:last-child {
    margin: 0;
}


#seller .rsWrapper .aiInfo .content>div>h1,
#seller .rsWrapper .aiInfo .content .box .info h1,
#seller .rsWrapper .accountBox .content .box .info h1,
#seller .rsWrapper .aiBox .content .box .info h1,
#seller .rsWrapper .tagBox .content .box .info h1,
#seller .rsWrapper .infoBox .content .box .info h1,
#seller .rsWrapper .sellerBox .content .box .info h1,
#seller .rsWrapper .ai .content .box .info h1,
#seller .rsWrapper .type .content .box .info h1 {
    font-size: 12px;
    color: #464548;
    margin: 0 0 9px;
}


#seller .rsWrapper .aiBox .content .box textarea,
#seller .rsWrapper .aiBox .content .box .info input,
#seller .rsWrapper .tagBox .content .box .info input,
#seller .rsWrapper .infoBox .content .box .info input,
#seller .rsWrapper .infoBox .content .box .info textarea,
#seller .rsWrapper .sellerBox .content .box .info textarea,
#seller .rsWrapper .sellerBox .content .box .info input,
#seller .rsWrapper .sellerBox .content .box .info>div input,
#seller .rsWrapper .ai .content .box .info>div input,
#seller .rsWrapper .type .content .box .info>div .input {
    width: 200px;
    height: 40px;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 13px;
    outline: none;
}

#seller .rsWrapper .aiBox .content .box textarea::placeholder,
#seller .rsWrapper .aiBox .content .box .info input::placeholder,
#seller .rsWrapper .tagBox .content .box .info::placeholder,
#seller .rsWrapper .infoBox .content .box .info input::placeholder,
#seller .rsWrapper .infoBox .content .box .info textarea::placeholder,
#seller .rsWrapper .sellerBox .content .box .info textarea::placeholder,
#seller .rsWrapper .sellerBox .content .box .info input::placeholder,
#seller .rsWrapper .sellerBox .content .box .info>div input::placeholder,
#seller .rsWrapper .ai .content .box .info>div input::placeholder,
#seller .rsWrapper .type .content .box .info>div .input::placeholder {
    font-size: 13px;
    color: #a3a1a7;
}

#seller .rsWrapper .aiBox .content .box textarea:focus,
#seller .rsWrapper .aiBox .content .box .info input:focus,
#seller .rsWrapper .tagBox .content .box .info:focus,
#seller .rsWrapper .infoBox .content .box .info input:focus,
#seller .rsWrapper .infoBox .content .box .info textarea:focus,
#seller .rsWrapper .sellerBox .content .box .info textarea:focus,
#seller .rsWrapper .sellerBox .content .box .info input:focus,
#seller .rsWrapper .sellerBox .content .box .info>div input:focus,
#seller .rsWrapper .ai .content .box .info>div input:focus,
#seller .rsWrapper .type .content .box .info>div .input:focus {
    background: #ffffff;
    border: 1px solid #b041f3;
}

#seller .rsWrapper .sellerBox .content .box .info>.bottom {
    width: calc(100% / 3 - 27px)
}

#seller .rsWrapper .sellerBox .content .box .info>div input,
#seller .rsWrapper .sellerBox .content .box .info>div .ant-select {
    width: calc(50% - 6px);
    height: 40px;
}

#seller .rsWrapper .ai .content .box .info>div {
    width: calc(100% / 3 - 27px);
}

#seller .rsWrapper .infoBox .content .box .info input,
#seller .rsWrapper .infoBox .content .box .info textarea,
#seller .rsWrapper .sellerBox .content .box .info textarea,
#seller .rsWrapper .sellerBox .content .box .info input,
#seller .rsWrapper .ai .content .box .info>div input {
    width: 100%;
}

#seller .rsWrapper .ai .content .box .info>div .ant-select {
    width: 100%;
    height: 40px;
}

#seller .rsWrapper .sellerBox .content .box .info>div .ant-select-selector,
#seller .rsWrapper .ai .content .box .info>div .ant-select-selector {
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
}

#seller .rsWrapper .sellerBox .content .box .info>.greenBox {
    width: 100%;
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #dbdbe0;
    background: #eff8f9;
}

#seller .rsWrapper .sellerBox .content .box .info>.greenBox #darkGrey {
    width: auto;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 16px 0 0;
}

#seller .rsWrapper .sellerBox .content .box .info>.greenBox>div>img {
    width: 21px;
    margin: 0 0 0 16px;
}


#seller .rsWrapper .sellerBox .content .box .info>.greenBox>h1 {
    font-size: 12px;
    color: #464548;
    margin: 25px 0;
}

#seller .rsWrapper .sellerBox .content .box .info>.greenBox>p {
    font-size: 13px;
    color: #000000;
    font-weight: 500;
}

#seller .rsWrapper .infoBox .content .box .info:last-child textarea,
#seller .rsWrapper .sellerBox .content .box .info textarea {
    height: 72px;
    resize: none;
}

#seller .rsWrapper .infoBox .content .box .info textarea {
    height: 204px;
    resize: none;
    white-space: pre-wrap;
}

#seller .rsWrapper .tagBox .content .box .info>p {
    font-size: 12px;
    color: #0d88d4;
    margin: 0 0 9px;
}

#seller .rsWrapper .tagBox .content .box .info div .tag {
    width: auto;
    padding: 8px 10px;
    border: 1px solid #cccccc;
    border-radius: 50px;
    margin: 0 12px 0 0;
}

#seller .rsWrapper .tagBox .content .box .info div .tag>p {
    font-size: 13px;
    color: #0e0e1f;
    font-weight: 600;
    margin: 0 15px 0 0;
}

#seller .rsWrapper .tagBox .content .box .info div .tag>img {
    width: 8px;
}


#seller .rsWrapper .tagBox .content .box .info input {
    margin: 0 12px 0 0;
}

#seller .rsWrapper .accountBox .content .box .info .plusBox,
#seller .rsWrapper .aiBox .content .box .info .plusBox {
    width: 180px;
    height: 180px;
    border-radius: 4px;
    padding: 8px 16px;
    border: 1px dashed #e21dfd;
    background: #fcf7fd;
    cursor: pointer;
}

#seller .rsWrapper .accountBox .content .box .info .plusBox {
    width: 300px;
    margin: 0 40px 0 0;
}

#seller .rsWrapper .accountBox .content .box .info .plusBox .plus,
#seller .rsWrapper .aiBox .content .box .info .plus {
    background: #f0dbf4;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    color: #c49bcc;
    margin: 0 0 8px;
}

#seller .rsWrapper .accountBox .content .box .info .plusBox>p,
#seller .rsWrapper .aiBox .content .box .info .plusBox>p {
    color: #c49bcc;
    text-align: center;
    line-height: 1.2;
    font-size: 12px;
}

#seller .rsWrapper .accountBox .content .box .info .plusBox p {
    font-size: 16px;
}

#seller .rsWrapper .accountBox .content .box .info .plusBox:hover .plus,
#seller .rsWrapper .aiBox .content .box .info .plusBox:hover .plus {
    background: #c49bcc;
    color: #f0dbf4;
}

#seller .rsWrapper .aiBox .content .box textarea {
    height: 173px;
    width: 100%;
    resize: none;
}

#seller .rsWrapper .accountBox .content .box .info .account {
    width: 300px;
    height: 180px;
    padding: 20px 16px;
    border-radius: 16px;
    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px rgba(226, 29, 253, 0.2);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to bottom, #848ff5, #b369ba);
}

#seller .rsWrapper .accountBox .content .box .info .account h1 {
    font-size: 16px;
    color: #0e0e1f;
    margin: 0 0 10px;
}

#seller .rsWrapper .accountBox .content .box .info .account p {
    font-size: 14px;
    color: #403748;
    margin: 0 0 34px;
}

#seller .rsWrapper .accountBox .content .box .info .account #pinkBtn {
    width: 180px;
    height: 40px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 700;

}

#seller .rsWrapper>p {
    color: #f34144;
    font-size: 14px;
    margin: 0 0 80px;
    font-weight: 600;
}

#seller .rsWrapper .btnBox #purpleBtn,
#seller .rsWrapper .btnBox #darkGrey,
#seller .rsWrapper .btnBox #logoutBtn {
    width: 120px;
    height: 40px;
    margin: 0 8px;
    border-radius: 4px;
    font-size: 14px;
}


#seller .aiInfo .content .ant-checkbox,
#seller .aiInfo .content .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

#seller .aiInfo .content .ant-checkbox+span img {
    width: 21px;
    margin: 0 0 -6px;
}

#seller .aiInfo .content>p {
    font-size: 12px;
    color: #464548;
    margin: 0 0 10px;
}

#seller .aiInfo .content p span {
    color: #0d88d4;
    margin: 0 0 0 12px;
}

#seller .aiInfo .content .tabBox .tab {
    width: auto;
    padding: 8px 12px;
    border: 1px solid #cccccc;
    font-size: 13px;
    color: #0e0e1f;
    font-weight: 600;
    border-radius: 20px;
    margin: 0 12px 0 0;
}

#seller .rsWrapper .aiBox .content .box .info #darkGrey {
    width: 93px;
    height: 30px;
    border-radius: 4px;
    font-size: 12px;
}

#seller .rsWrapper .aiBox .content .box:not(.image) .info>div {
    align-items: flex-end;
}

#seller .rsWrapper .aiBox .content .box .info>div>div>h1 {
    font-weight: 700;
}

#seller .rsWrapper .aiBox .content .box .info>div>div>div:not(.plusBox) {
    align-items: flex-end;
    margin: 0 0 12px;
}

#seller .rsWrapper .aiBox .content .box .info>div>div:first-child>.plusBox {
    width: calc(100% - 40px);
}

#seller .rsWrapper .aiBox .content .box:not(.image) .info>div>div:last-child {
    width: auto;
}

#seller .rsWrapper .aiBox .content .box .info>div>div>div>img {
    width: 18px;
    margin: 0 0 0 15px
}


/* //////// */
/* //////// */
/* AIMODAL */
/* //////// */
/* //////// */

.aiModal .title {
    margin: 0 0 20px;
}

.aiModal .title h1 {
    font-size: 24px;
    font-weight: 700;
    color: #0e0e1f;
}

.aiModal .title p {
    font-size: 24px;
}

.aiModal .top {
    padding: 12px;
    border-radius: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
    align-items: flex-end;
    margin: 0 0 28px;
}

.aiModal .top .box {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #c2b8cb;
    height: 188px;
    width: calc(100% - 120px - 24px);
    background: #ffffff;
}

.aiModal .top .box .thumb {
    width: 123px;

}

.aiModal .top .box .thumb p {
    font-size: 12px;
    font-weight: 700;
    color: #464548;
    margin: 0 0 13px;
}

.aiModal .top .box .thumb .plusBox {
    width: 100%;
    height: 123px;
    border-radius: 4px;
    background: #f3f3f3;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.aiModal .top .box .thumb .plusBox .plus {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.1);
    font-size: 20px;
    color: #ffffff;
}

.aiModal .top .box .thumb .plusBox:hover {
    background: #f0dbf4;
}

.aiModal .top .box>div {
    width: calc(100% - 123px - 20px);
}

.aiModal .top .box>div .infoBox:not(:last-child) {
    width: 100%;
    margin: 0 0 12px;
}

.aiModal .top .box>div .infoBox p {
    font-size: 12px;
    font-weight: 700;
    color: #464548;
}

.aiModal .top .box>div .infoBox .ant-select {
    width: 300px;
    height: 40px;
}

.aiModal .top .box>div .infoBox input {
    height: 40px;
    width: 300px;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    outline: none;
    padding: 0 12px;
    border-radius: 4px;
    font-size: 13px;
}

.aiModal .top .box>div .infoBox input::placeholder {
    font-size: 13px;
    color: #a3a1a7;
}

.aiModal .top #pinkBtn {
    width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 24px;
}

.aiModal .filter {
    margin: 0 0 16px;
}

.aiModal .filter .ant-select {
    border-radius: 4px;
    width: 120px;
    height: 40px;
}

.aiModal .filter>div {
    width: auto;
}

.aiModal .filter>div input {
    width: 140px;
    height: 40px;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
    font-size: 13px;
}

.aiModal .filter>div p {
    font-size: 13px;
    margin: 0 12px;
}

.aiModal .filter>div #pinkBtn {
    width: 40px;
    height: 40px;
    margin: 0 0 0 12px;
    font-size: 20px;
    border-radius: 4px;
}

.aiModal .contentTop {
    border-top: 2px solid #000000;
    background: #f3f2fa;
    height: 48px;
}

.aiModal .contentTop div {
    font-size: 14px;
    color: #323146;
    font-weight: 500;
}

.aiModal .scroll {
    max-height: 350px;
    justify-content: flex-start;
    overflow: auto;
}

.aiModal .contentTop div:nth-child(1),
.aiModal .scroll .content div:nth-child(1) {
    width: 180px;
}

.aiModal .contentTop div:nth-child(2),
.aiModal .contentTop div:nth-child(4),
.aiModal .scroll .content div:nth-child(2),
.aiModal .scroll .content div:nth-child(4) {
    width: 120px;
}

.aiModal .scroll .content div:nth-child(4) .tab {
    padding: 4px 8px;
    font-size: 13px;
    color: #ffffff;
    width: auto;
    border-radius: 50px;
    font-weight: 300;
    line-height: 1;
}

.aiModal .scroll .content div:nth-child(4) .tab.success {
    background: #525162;
}

.aiModal .scroll .content div:nth-child(4) .tab.accept {
    background: #0fc9f3
}

.aiModal .scroll .content div:nth-child(4) .tab.ing {
    background: #f8c930
}

.aiModal .scroll .content div:nth-child(4) .tab.fail {
    background: #fe393c
}

.aiModal .contentTop div:nth-child(3),
.aiModal .scroll .content div:nth-child(3) {
    width: 140px;
}

.aiModal .scroll .content div:nth-child(3) img {
    width: 80%;
    border-radius: 4px;
}

.aiModal .contentTop div:nth-child(6),
.aiModal .scroll .content div:nth-child(6) {
    width: 80px;
}

.aiModal .scroll .content div:nth-child(6) img {
    width: 20px;
}

.aiModal .contentTop div:nth-child(5),
.aiModal .scroll .content div:nth-child(5) {
    width: calc(100% - 180px - 120px - 120px - 140px - 80px);
}

.aiModal .scroll .content {
    border-bottom: 1px solid #e2dfe4;
    padding: 8px 0;
}

.aiModal .scroll .content div:nth-child(5) {
    font-size: 16px;
    color: #464548;
}

.aiModal .scroll .content div {
    font-size: 13px;
    color: #000000;
    font-weight: 600;
}

.aiCreateModal .title,
.notiModal .title {
    margin: 0 0 60px
}

.aiCreateModal .title h1,
.notiModal .title h1 {
    font-size: 24px;
    font-weight: 700;
    color: #0e0e1f;
    margin: 0;
}

.aiCreateModal .title p,
.notiModal .title p {
    font-size: 24px;
}

.notiModal h1 {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin: 0 0 20px;
    text-align: center;
}

.notiModal h1 span {
    color: #c718e2;
}

.notiModal>p {
    font-size: 14px;
    color: #0e0e1f;
    margin: 0 0 38px;
}

.notiModal #purpleBtn {
    width: calc(100% - 80px);
    height: 58px;
    border-radius: 5px;
    font-size: 18px;
}

.aiCreateModal>div>p {
    font-size: 12px;
    color: #0e0e1f;
    margin: 0 0 9px;
}

.aiCreateModal>div:not(:last-child) {
    margin: 0 0 20px
}

.aiCreateModal>div>input {
    width: 100%;
    border: 1px solid #e2dfe4;
    background: #f7f7f8;
    height: 40px;
    border-radius: 4px;
    font-size: 13px;
    padding: 12px;
}

.aiCreateModal>div>input::placeholder {
    color: #a3a1a7;
    font-size: 13px;
}

.aiCreateModal>div:last-child {
    margin: 80px 0 0;
}

.aiCreateModal>div>#purpleBtn,
.aiCreateModal>div>#logoutBtn {
    width: 100px;
    height: 40px;
    border-radius: 4px;
    margin: 0 5px;
    font-size: 14px;
}

@media (max-width : 1100px) {

    #seller .rsWrapper .aiInfo .title,
    #seller .rsWrapper .accountBox .title,
    #seller .rsWrapper .aiBox .title,
    #seller .rsWrapper .tagBox .title,
    #seller .rsWrapper .infoBox .title,
    #seller .rsWrapper .sellerBox .title,
    #seller .rsWrapper .ai .title,
    #seller .rsWrapper .type .title {
        width: 160px;
    }

    #seller .rsWrapper .aiInfo .content,
    #seller .rsWrapper .accountBox .content,
    #seller .rsWrapper .aiBox .content,
    #seller .rsWrapper .tagBox .content,
    #seller .rsWrapper .infoBox .content,
    #seller .rsWrapper .sellerBox .content,
    #seller .rsWrapper .ai .content,
    #seller .rsWrapper .type .content {
        width: calc(100% - 160px);
    }

    #seller .rsWrapper .type .content .box .info>div .input {
        width: 130px;
    }


}

@media (max-width : 900px) {
    #myPage>div>.title .overflowBox div h1 {
        font-size: 24px;
    }

    #myPage>div>.title {
        justify-content: flex-start;
    }

    #myPage>div>.title .overflowBox {
        justify-content: flex-start;
    }

    #myPage>div>.title .overflowBox div#active .round {
        width: 6px;
        height: 6px;
        top: -6px;
        left: -6px;
    }

    #seller .rsWrapper {
        align-items: flex-start;
    }

    #seller .rsWrapper>p {
        font-size: 11px;
        margin: 0 0 30px;
        line-height: 1.2;
    }

    #seller .rsWrapper .titleBox .title h1 {
        font-size: 20px;
    }

    #seller .rsWrapper .aiInfo,
    #seller .rsWrapper .accountBox,
    #seller .rsWrapper .aiBox,
    #seller .rsWrapper .tagBox,
    #seller .rsWrapper .infoBox,
    #seller .rsWrapper .sellerBox,
    #seller .rsWrapper .ai,
    #seller .rsWrapper .type {
        flex-wrap: wrap;
        border-bottom: 1px solid #dbdbe0;
    }

    #seller .rsWrapper .aiInfo .title,
    #seller .rsWrapper .accountBox .title,
    #seller .rsWrapper .aiBox .title,
    #seller .rsWrapper .tagBox .title,
    #seller .rsWrapper .infoBox .title,
    #seller .rsWrapper .sellerBox .title,
    #seller .rsWrapper .ai .title,
    #seller .rsWrapper .type .title {
        width: 100%;
        margin: 0 0 32px;
    }

    #seller .rsWrapper .aiInfo .content,
    #seller .rsWrapper .accountBox .content,
    #seller .rsWrapper .aiBox .content,
    #seller .rsWrapper .tagBox .content,
    #seller .rsWrapper .infoBox .content,
    #seller .rsWrapper .sellerBox .content,
    #seller .rsWrapper .ai .content,
    #seller .rsWrapper .type .content {
        width: 100%;
    }

    #seller .rsWrapper .aiInfo .content .box .info,
    #seller .rsWrapper .accountBox .content .box .info,
    #seller .rsWrapper .aiBox .content .box .info,
    #seller .rsWrapper .tagBox .content .box .info,
    #seller .rsWrapper .infoBox .content .box .info,
    #seller .rsWrapper .sellerBox .content .box .info,
    #seller .rsWrapper .ai .content .box .info,
    #seller .rsWrapper .type .content .box .info {
        flex-wrap: wrap;
    }



    #seller .rsWrapper .type .content .box .info>div {
        position: relative;
        height: auto;
    }

    #seller .rsWrapper .type .content .box .info>div .input {
        margin: 0 0 14px;
    }

    #seller .rsWrapper .sellerBox .content .box .info>.bottom,
    #seller .rsWrapper .ai .content .box .info>div {
        width: 100%;
    }

    #seller .rsWrapper .ai .content .box .info>div {
        margin: 0 0 30px;
    }

    #seller .rsWrapper .ai .content .box .info>div:last-child {
        margin: 0;
    }

    #seller .aiInfo>#darkGrey,
    #seller .rsWrapper .ai .content .box>#darkGrey {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
    }


    #seller .aiInfo .content p span {
        margin: 0;
    }

    #seller .rsWrapper .tagBox .content .box .info>div:first-child {
        align-items: flex-start;
        margin: 0 0 15px;
    }

    #seller .rsWrapper .tagBox .content .box .info>div:first-child p {
        font-size: 11px;
        color: #0d88d4;
        line-height: 1.2;
    }

    #seller .rsWrapper .tagBox .content .box .info>div {
        flex-wrap: wrap;
    }

    #seller .rsWrapper .tagBox .content .box .info input {
        width: 100%;
        margin: 0 0 15px;
    }

    #seller .rsWrapper .tagBox .content .box .info div .tag {
        margin: 0 12px 12px 0;
    }

    #seller .rsWrapper .aiBox .content .box .info>div {
        flex-wrap: wrap;
    }

    #seller .rsWrapper .aiBox .content .box:not(.image) .info>div>div:last-child,
    #seller .rsWrapper .accountBox .content .box .info .plusBox,
    #seller .rsWrapper .aiBox .content .box:not(.image) .info .plusBox,
    #seller .rsWrapper .aiBox .content .box .info>div>div:first-child>.plusBox,
    #seller .rsWrapper .aiBox .content .box .info>div>div:first-child:not(.plus) {
        width: 100%;

    }

    #seller .rsWrapper .aiBox .content .box:not(.image) .info>div>div:first-child {
        margin: 0 0 30px;
    }

    #seller .rsWrapper .aiBox .content .box .info #darkGrey {
        width: 104px;
        height: 40px;
        font-size: 12px;
    }

    #seller .rsWrapper .aiBox .content .box .info>div>div>div:not(.plusBox) {
        justify-content: space-between;
        align-items: center;
    }

    #seller .rsWrapper .aiBox .content .box .info p {
        font-size: 11px;
        color: #2687e9;
    }

    #seller .rsWrapper .accountBox .content .box .info .account,
    #seller .rsWrapper .accountBox .content .box .info .plusBox,
    #seller .rsWrapper .aiBox .content .box .info .plusBox {
        height: 110px;
    }

    #seller .rsWrapper .accountBox,
    #seller .rsWrapper .aiBox {
        border-bottom: none;
    }

    #seller .rsWrapper .accountBox .content .box .info {
        justify-content: space-between;
    }

    #seller .rsWrapper .accountBox .content .box .info .account,
    #seller .rsWrapper .accountBox .content .box .info .plusBox {
        width: calc(100% / 2 - 7.5px);
        margin: 0;
        padding: 0 17px;
        border-radius: 4px;
    }


    #seller .rsWrapper .accountBox .content .box .info .account p,
    #seller .rsWrapper .accountBox .content .box .info .account h1 {
        font-size: 12px;
    }

    #seller .rsWrapper .accountBox .content .box .info .account p {
        margin: 0 0 20px;
    }

    #seller .rsWrapper .accountBox .content .box .info .account #pinkBtn {
        width: 100%;
        height: 28px;
        font-size: 12px;
    }

    #seller .rsWrapper .aiBox .content .box.image .info {
        justify-content: flex-start;
    }

    #seller .rsWrapper .aiBox .content .box.image .info .plusBox {
        width: 100px;
        height: 100px;
        margin: 0 13px 15px 0;
    }

    #seller .rsWrapper .aiBox .content .box.image .info .plusBox:nth-child(3) {
        margin: 0 0 15px;
    }

    .aiModal .top .box>div .infoBox,
    .aiModal .top .box>div,
    .aiModal .top .box .thumb,
    .aiModal .top .box {
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }

    .aiModal .top .box,
    .aiModal .top .box .thumb {
        margin: 0 0 20px;
    }

    .aiModal .top .box>div .infoBox p {
        margin: 0 0 9px;
    }

    .aiModal .filter .ant-select,
    .aiModal .top #pinkBtn {
        width: 100%;
    }

    .aiModal .filter {
        flex-wrap: wrap;
    }

    .aiModal .filter>div {
        margin: 0 0 10px;
    }

    .aiModal .filter>div input {
        width: 130px;
    }

    .aiModal .contentTop {
        border-top: none;
        border-radius: 8px;
    }

    .aiModal .contentTop div:nth-child(5),
    .aiModal .scroll .content div:nth-child(5),
    .aiModal .contentTop div:nth-child(3),
    .aiModal .scroll .content div:nth-child(3) {
        display: none;
    }

    .aiModal .contentTop div {
        font-size: 13px;
    }

    .aiModal .contentTop div:nth-child(6),
    .aiModal .scroll .content div:nth-child(6),
    .aiModal .contentTop div:nth-child(2),
    .aiModal .contentTop div:nth-child(4),
    .aiModal .scroll .content div:nth-child(2),
    .aiModal .scroll .content div:nth-child(4),
    .aiModal .contentTop div:nth-child(1),
    .aiModal .scroll .content div:nth-child(1) {
        width: calc(100% / 4)
    }

    .aiModal .scroll .content {
        height: 65px;
    }

    #myPage>div>.title .overflowBox>div {
        margin: 0 20px 0 10px;
    }

    #seller .tab {
        justify-content: flex-start;
    }

    #seller .tab #pinkBtn2 {
        width: 80px;
        height: 32px;
    }


    #seller .rsWrapper .type .content .box .info>div:first-child :where(.css-dev-only-do-not-override-apn68).ant-radio-wrapper span.ant-radio+* {
        padding: 0 20px 0 10px;
    }

    #seller .rsWrapper .sellerBox .content .box .info>.greenBox>div>img,
    #seller .rsWrapper .ai .content .box .info:nth-child(3) {
        margin: 0;
    }

    #seller .rsWrapper .sellerBox .content .box .info>.greenBox {
        padding: 20px 10px;
    }



    #seller .rsWrapper .aiInfo .content>div>img,
    #seller .rsWrapper .infoBox .content .box .info>div>img,
    #seller .rsWrapper .sellerBox .content .box .info>div>img {
        width: 12px;
        margin: 0 0 9px 7px;
    }



    #seller .rsWrapper .aiBox .content .box textarea {
        height: 75px;
    }

    #seller .rsWrapper .accountBox .content .box .info .account {
        padding: 0 14px;
    }

    .notiModal #purpleBtn {
        width: 100%;
        height: 40px;
        font-size: 14px;
    }

    .notiModal h1 {
        font-size: 16px;
    }

    .aiCreateModal .title,
    .notiModal .title {
        margin: 0 0 30px;
    }

    .notiModal>p {
        font-size: 12px;
    }

    .aiCreateModal>div:last-child {
        margin: 40px 0 0;
    }

    .aiCreateModal>div>p {
        font-weight: 600;
    }

    .aiCreateModal>div>#purpleBtn,
    .aiCreateModal>div>#logoutBtn {
        width: 49%;
    }


    #seller .rsWrapper .sellerBox .content .box .info>.greenBox>h1 {
        margin: 20px 0 5px;
    }

    #seller .aiInfo .content .ant-checkbox+span img {
        margin: 0 0 -7px;
    }


    #seller .rsWrapper .title {
        margin: 0 0 15px;
        justify-content: flex-start;

        p {
            font-size: 13px;
        }

    }
}