#bannerSlider {
  height: 300px;
  width: 100vw;
  display: block;
}

#bannerSlider .bannerBox {
  width: 100%;
  height: 300px;
  position: relative;
  /*cursor:pointer;*/
}

#bannerSlider .bannerBox > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

#bannerSlider .bannerBox div h1 {
  font-size: 36px;
  color: #ffffff;
  font-weight: 700;
}

#bannerSlider .bannerBox img {
  width: 100%;
  height: 100%;
}

.slick-dots {
  bottom: 20px;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li.slick-active button:before {
  color: #ffffff;
  opacity: 1;
}

.slick-dots li button:before {
  color: #ffffff;
}

@media (max-width: 700px) {
  #bannerSlider {
    height: 120px;
  }

  #bannerSlider .bannerBox {
    height: 120px;
  }

  #bannerSlider .bannerBox div h1 {
    font-size: 20px;
  }
}

#mainSlider.none,
#bannerSlider.none,
#subBanner2.none,
#subBannerWrapper.none,
#subBannerWrapper .rsWrapper .subBanner.none{
  display: none;
}

