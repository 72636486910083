#footer article {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 28px 20px 16px;
  gap: 24px;
}
#footer article li {
  padding: 0 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  font-size: 11px;
}
#footer article li:last-child,
#footer article ul:not(.footMenu) li:nth-last-child(2) {
  border: 0;
}
#footer article li a {
  line-height: 1.1;
  color: #403748;
  font-weight: 500;
  /* font-size: 11px; */
}
#footer article li a:hover {
  color: #2687e9;
}
#footer article .logo {
  font-weight: 600;
}
#footer article .footMenu li {
  font-size: 14px;
  color: #0e0e1f;
}
#footer article .footMenu a {
  color: #0e0e1f;
}
#footer article .copy {
  color: #87858b;
  width: 100%;
  text-align: center;
}
.loginSection {
  min-height: 680px;
}
@media (max-width: 700px) {
  #footer {
    padding: 16px 0;
  }
  #footer article {
    flex-direction: column !important;
  }
  #footer article img {
    margin: 0 0 8px;
  }
}
